.holy-wrapper {
  .nav .open > a,
  .nav .open > a:hover,
  .nav .open > a:focus {
    background-color: transparent;
  }
  li {
    i {
      font-size: 20px;
      margin-right: 5px;
    }
    span {
      font-size: 15px;
    }
  }
  #wrapper, #wrapper-right {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  #wrapper.toggled, #wrapper-right.toggled {
    padding-left: 220px;
  }

  #sidebar-wrapper {
    z-index: 1000;
    left: 220px;
    width: 0;
    height: 100%;
    margin-left: -220px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #1a1a1a;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  #sidebar-wrapper-right {
    z-index: 1000;
    left: initial;
    width: 0;
    height: 100%;
    margin-left: -220px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #ffffff;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  #sidebar-wrapper::-webkit-scrollbar {
    display: none;
  }
  #sidebar-wrapper-right::-webkit-scrollbar {
    display: none;
  }

  #wrapper.toggled #sidebar-wrapper, #wrapper-right.toggled #sidebar-wrapper-right {
    width: 220px;
  }

  #page-content-wrapper {
    width: 100%;
    padding-top: 30px;
  }

  #wrapper.toggled #page-content-wrapper, #wrapper-right.toggled #page-content-wrapper {
    position: absolute;
    margin-right: -220px;
  }

  /*-------------------------------*/
  /*     Sidebar nav styles        */
  /*-------------------------------*/

  .sidebar-nav {
    position: absolute;
    top: 0;
    width: 220px;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .sidebar-nav li {
    position: relative;
    line-height: 20px;
    display: block;
    width: 100%;
  }

  .sidebar-nav li:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
    width: 3px;
    -webkit-transition: width .2s ease-in;
    -moz-transition: width .2s ease-in;
    -ms-transition: width .2s ease-in;
    transition: width .2s ease-in;

  }
  .sidebar-nav li:nth-child(2):before {
    background-color: #ec1b5a;
  }
  .sidebar-nav li:nth-child(3):before {
    background-color: #79aefe;
  }
  .sidebar-nav li:nth-child(4):before {
    background-color: #314190;
  }
  .sidebar-nav li:nth-child(5):before {
    background-color: #279636;
  }
  .sidebar-nav li:nth-child(6):before {
    background-color: #7d5d81;
  }
  .sidebar-nav li:nth-child(7):before {
    background-color: #00457b;
  }
  .sidebar-nav li:nth-child(8):before {
    background-color: #2d2366;
  }
  .sidebar-nav li:nth-child(9):before {
    background-color: #35acdf;
  }
  .sidebar-nav li:hover:before,
  .sidebar-nav li.open:hover:before {
    width: 100%;
    -webkit-transition: width .2s ease-in;
    -moz-transition: width .2s ease-in;
    -ms-transition: width .2s ease-in;
    transition: width .2s ease-in;

  }

  .sidebar-nav li a {
    display: block;
    color: #ddd;
    text-decoration: none;
  }
  #sidebar-wrapper-right .sidebar-nav li a {
    color: black;
  }

  .sidebar-nav li a:hover,
  .sidebar-nav li a:active,
  .sidebar-nav li a:focus,
  .sidebar-nav li.open a:hover,
  .sidebar-nav li.open a:active,
  .sidebar-nav li.open a:focus {
    color: #fff;
    text-decoration: none;
    background-color: transparent;
  }

  .sidebar-nav > .sidebar-brand {
    height: 65px;
    font-size: 20px;
    line-height: 44px;
  }
  .sidebar-nav .dropdown-menu {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    background-color: #222;
    box-shadow: none;
  }

  /*-------------------------------*/
  /*       Hamburger-Cross         */
  /*-------------------------------*/

  .hamburger {
    position: fixed;
    top: 14px;
    display: block;
    width: 23px;
    height: 23px;
    background: transparent;
    border: none;
    margin-left: 15px;
    &.is-open{
      z-index: 9999;
    }
  }
  .hamburger:hover,
  .hamburger:focus,
  .hamburger:active {
    outline: none;
  }
  .hamburger.is-closed:before {
    content: '';
    display: block;
    width: 100px;
    font-size: 14px;
    color: #fff;
    line-height: 32px;
    text-align: center;
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transition: all .35s ease-in-out;
  }
  .hamburger.is-closed:hover:before {
    opacity: 1;
    display: block;
    -webkit-transform: translate3d(-100px, 0, 0);
    -webkit-transition: all .35s ease-in-out;
  }

  .hamburger.is-closed .hamb-top,
  .hamburger.is-closed .hamb-middle,
  .hamburger.is-closed .hamb-bottom,
  .hamburger.is-open .hamb-top,
  .hamburger.is-open .hamb-middle,
  .hamburger.is-open .hamb-bottom {
    position: absolute;
    left: 0;
    height: 4px;
    width: 100%;
  }
  .hamburger.is-closed .hamb-top,
  .hamburger.is-closed .hamb-middle,
  .hamburger.is-closed .hamb-bottom {
    background-color: #000;
  }
  .hamburger.is-closed .hamb-top {
    top: 3px;
    -webkit-transition: all .35s ease-in-out;
  }
  .hamburger.is-closed .hamb-middle {
    top: 50%;
    margin-top: -2px;
  }
  .hamburger.is-closed .hamb-bottom {
    bottom: 2px;
    -webkit-transition: all .35s ease-in-out;
  }

  .hamburger.is-closed:hover .hamb-top {
    top: 0;
    -webkit-transition: all .35s ease-in-out;
  }
  .hamburger.is-closed:hover .hamb-bottom {
    bottom: 0;
    -webkit-transition: all .35s ease-in-out;
  }
  .hamburger.is-open .hamb-top,
  .hamburger.is-open .hamb-middle,
  .hamburger.is-open .hamb-bottom {
    background-color: #f8f8f8;
    margin-left: -55px;
  }
  .hamburger.is-open .hamb-top,
  .hamburger.is-open .hamb-bottom {
    top: 50%;
    margin-top: -2px;
  }
  .hamburger.is-open .hamb-top {
    -webkit-transform: rotate(45deg);
    -webkit-transition: -webkit-transform .2s cubic-bezier(.73, 1, .28, .08);
  }
  .hamburger.is-open .hamb-middle {
    display: none;
  }
  .hamburger.is-open .hamb-bottom {
    -webkit-transform: rotate(-45deg);
    -webkit-transition: -webkit-transform .2s cubic-bezier(.73, 1, .28, .08);
  }
  .hamburger.is-open:before {
    content: '';
    display: block;
    width: 100px;
    font-size: 14px;
    color: #fff;
    line-height: 32px;
    text-align: center;
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transition: all .35s ease-in-out;
  }
  .hamburger.is-open:hover:before {
    opacity: 1;
    display: block;
    -webkit-transform: translate3d(-100px, 0, 0);
    -webkit-transition: all .35s ease-in-out;
  }

  /*-------------------------------*/
  /*            Overlay            */
  /*-------------------------------*/

  .overlay, .overlay-right {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(250, 250, 250, .8);
    z-index: 1;
  }
  ul.auth-section {
    position: absolute;
    bottom: 0;
    left: 5px;
    .registration {
      background: #0B7ED4;
      color: white;
      margin-bottom: 15px;
      text-align: center;
    }
    .login {
      color: #fff;
      cursor: pointer;
      text-align: center;
    }
    .logout {
      display: none;
      color: #fff;
      cursor: pointer;
      margin-left: 10px;
    }
  }
}

button.hamburger-right.is-open {
  position: fixed;
  right: 228px;
}

.holy-wrapper #sidebar-wrapper-right .sidebar-nav li a.profile-pic-image {
  padding:0;
}
.navBar-right-swipe{
  button.hamburger-right.is-open {
    position: absolute;
    right: 10px;
    z-index: 99999999;
    top: 10px;
    background: none;
    border: none;
    color: white;
    i{
      font-size:30px;
    }
  }
}

.navBar-right-swipe{
  hr {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .nav-right-profile{
    padding: 5px;
    background: #2980b9;
    color: white;
    font-size: 12px;
    img{
      border-radius: 50%;
    }
  }
}

button.hamburger.is-closed{
  i{
    font-size: 23px;
  }
  .fa-times-circle{
    display: none;
  }
}

button.hamburger.is-open{
  position: relative;
  right: 80px;
  top: -18px;
  i{
    font-size: 25px;
    color: white;
  }
  .fa-bars{
    display: none;
  }
}
