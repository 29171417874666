.feature-health {
  .post {
    margin-bottom: 60px;
    padding:0 30px;
  }
  .post-thumb.bg-hv-overlay {
    max-height: 200px;
    overflow-y: hidden;
  }
  .bg-overlay, .hs-header-nav header.header, .bg-hv-overlay, .bg-overlay-white {
    position: relative;
    display: block;
  }
  .bg-hv-overlay:hover:before {
    opacity: 0.7;
    background-color: #5fcfff;
    transition: all .4s;
  }
  .bg-hv-overlay:before {
    opacity: 0;
    z-index: 1;
    background-color: initial;
  }
  .bg-overlay:before, .hs-header-nav header.header:before, .bg-hv-overlay:before, .bg-overlay-white:before {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;
    content: "";
    height: 100%;
    width: 100%;
  }
  .post-thumb img {
    width: 100%;
  }
  .bg-hv-overlay:hover .hs-btn-plus {
    opacity: 1;
    text-decoration: none;
  }
  .bg-hv-overlay .hs-btn-plus {
    opacity: 0;
  }
  .hs-btn-plus {
    font-size: 52px;
    position: absolute;
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    color: #fff !important;
    z-index: 10;
  }
  .article-items h3 {
    margin: 30px 0 15px;
  }
  .post-meta {
    margin-bottom: 18px;
  }
  .post-meta > a {
    margin-right: 12px;
    color: #333333;
    &:hover{
      text-decoration: underline;
    }
  }
  .post-meta i {
    color: #dfdfdf;
    margin-right: 6px;
  }
  .article-items .hs-btn {
    margin-top: 5px;
  }
  .hs-btn-bg {
    color: #fff;
    background-color: #52d3b7;
    border: 1px solid transparent;
    padding: 5px 10px;
  }
  .hs-btn-bg:hover {
    background-color: transparent;
    color: #52d3b7;
    border-color: #52d3b7;
  }
  .hs-btn i {
    margin-right: 8px;
  }
  a:link {
    text-decoration: none;
    outline: none;
    transition: all .5s ease-in-out;
  }
  .entry {
    margin-bottom: 30px;
    margin-top: 30px;
    p{
      font-size: 16px;
      line-height: 16*1.6px;
    }
  }
}