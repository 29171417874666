.tabs .active .login,.tabs .active .register {
  background: #2d3b55;
  color: white;
}

.authentication {
  flex: 1 0 auto;
  #login-submit{
    width: 100%;
  }
  .panel-login {
    border: none;
    -webkit-box-shadow: 0px 0px 49px 14px rgba(188,190,194,0.39);
    -moz-box-shadow: 0px 0px 49px 14px rgba(188,190,194,0.39);
    box-shadow: 0px 0px 49px 14px rgba(188,190,194,0.39);
  }
  .panel-login .checkbox input[type=checkbox]{
    margin-left: 0px;
  }
  .panel-login .checkbox label {
    padding-left: 25px;
    font-weight: 300;
    display: inline-block;
    position: relative;
  }
  .panel-login .checkbox {
    padding-left: 20px;
  }
  .panel-login .checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: 0px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  }
  .panel-login .checkbox label::after {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 0;
    margin-left: 0px;
    padding-left: 3px;
    padding-top: 1px;
    font-size: 11px;
    color: #555555;
  }
  .panel-login .checkbox input[type="checkbox"] {
    opacity: 0;
  }
  .panel-login .checkbox input[type="checkbox"]:focus + label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
  .panel-login .checkbox input[type="checkbox"]:checked + label::after {
    font-family: 'FontAwesome';
    content: "\f00c";
  }
  .panel-login>.panel-heading .tabs{
    padding: 0;
  }
  .panel-login h2{
    font-size: 20px;
    font-weight: 300;
    margin: 30px;
  }
  .panel-login>.panel-heading {
    color: #848c9d;
    background-color: #e8e9ec;
    border-color: #fff;
    text-align:center;
    border-bottom: 0;
    padding: 0 15px;
  }
  .panel-login>.panel-heading .login {
    padding: 20px 30px;
    border-bottom-leftt-radius: 5px;
  }
  .panel-login>.panel-heading .register {
    padding: 20px 30px;
  }
  .panel-login>.panel-heading a{
    text-decoration: none;
    color: #666;
    font-weight: 300;
    font-size: 16px;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    transition: all 0.1s linear;
  }
  .panel-login>.panel-heading a#register-form-link {
    width: 100%;
    text-align: left;
  }
  .panel-login>.panel-heading a#login-form-link {
    width: 100%;
    text-align: left;
  }

  .panel-login input[type="text"],.panel-login input[type="email"],.panel-login input[type="password"] {
    height: 37px;
    border: 0;
    font-size: 16px;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    transition: all 0.1s linear;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid #e7e7e7;
    border-radius: 0px;
    padding: 6px 0px;
    width: 100%;
  }
  .panel-login input:hover,
  .panel-login input:focus {
    outline:none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-color: #ccc;
  }
  .btn-login {
    background-color: #E8E9EC;
    outline: none;
    color: #2D3B55;
    font-size: 14px;
    height: auto;
    font-weight: normal;
    padding: 14px 0;
    text-transform: uppercase;
    border: none;
    border-radius: 0px;
    box-shadow: none;
  }
  .btn-login:hover,
  .btn-login:focus {
    color: #fff;
    background-color: #2D3B55;
  }
  .forgot-password {
    text-decoration: underline;
    color: #888;
  }
  .forgot-password:hover,
  .forgot-password:focus {
    text-decoration: underline;
    color: #666;
  }

  .btn-register {
    background-color: #E8E9EC;
    outline: none;
    color: #2D3B55;
    font-size: 14px;
    height: auto;
    font-weight: normal;
    padding: 14px 0;
    text-transform: uppercase;
    border: none;
    border-radius: 0px;
    box-shadow: none;
  }
  .btn-register:hover,
  .btn-register:focus {
    color: #fff;
    background-color: #2D3B55;
  }
  input.form-control {
    padding: 23px;
    &:focus{
      outline: none!important;
      box-shadow: none;
    }
    &.btn-reset-pass {
      padding: 12px;
    }
  }
  span.input-group-addon {
    padding: 10px 20px;
  }
  .forgot-password, .forgot-password-login {
    cursor: pointer;
  }
  @media (min-width: 768px) {
    .omb_row-sm-offset-3 div:first-child[class*="col-"] {
      margin-left: 25%;
    }
  }

  .omb_login .omb_authTitle {
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .omb_login .omb_socialButtons a {
    color: white; // In yourUse @body-bg
    opacity: 0.9;
  }
  .omb_login .omb_socialButtons a:hover {
    color: white;
    opacity: 1;
  }
  .omb_login .omb_socialButtons .omb_btn-facebook {
    background: #3b5998;
  }
  .omb_login .omb_socialButtons .omb_btn-twitter {
    background: #00aced;
  }
  .omb_login .omb_socialButtons .omb_btn-google {
    background: #c32f10;
  }

  .omb_login .omb_loginOr {
    position: relative;
    font-size: 1.5em;
    color: #aaa;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .omb_login .omb_loginOr .omb_hrOr {
    background-color: #cdcdcd;
    height: 1px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .omb_login .omb_loginOr .omb_spanOr {
    display: block;
    position: absolute;
    left: 50%;
    top: -2px;
    margin-left: -1.5em;
    background-color: white;
    width: 3em;
    text-align: center;
  }

  .omb_login .omb_loginForm .input-group.i {
    width: 2em;
  }
  .omb_login .omb_loginForm .help-block {
    color: red;
  }

  @media (min-width: 768px) {
    .omb_login .omb_forgotPwd {
      text-align: right;
      margin-top: 10px;
      float: right;
    }
  }
  @media (max-width: 767px) {
    .omb_login .omb_forgotPwd {
      text-align: right;
      margin-top: 10px;
      float: right;
    }
  }
  &.signup {
    padding: 0 20px;
    .form-control {
      margin-right: 20px;
      margin-bottom: 20px;
      display: inline-block;
      width: initial;
    }
    select {
      line-height: initial;
    }
    .form-footer a {
      display: inline-block;
      height: 47px;
      line-height: 40px;
      border: none;
      border-radius: 5px;
      color: white;
      padding: 5px 15px;
      background: #27ae60;
      margin-right: 10px;
      font-size: 16px;
      margin-bottom: 20px;
    }
    .control-label {
      padding-right: 0;
      color: #858581;
    }
  }
}

strong.verification-code-desc{
  display: block;
  margin-top: 10px;
}
.mobile-verification-code-desc{
  display: block;
  margin-top: 10px;
}

.m-right-0{
  margin-right: 0 !important;
}

.wait-thirty-second{
  position: relative;
  top: 13px;
  left: -14px;
  display: inline-block;
  color: green;
}
.wait-thirty-second-new{
  position: relative;
  top: 0;
  left: -14px;
  display: inline-block;
  color: green;
}