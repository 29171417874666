//colors
$color_pink_swan_approx: #bbbbbb;
$white: #ffffff;
$color_celeste_approx: #cdcdcd;
$color_star_dust_approx: #a0a0a0;
$color_bon_jour_approx: #e1dfdf;
$color_emperor_approx: #4d4d4d;
$mine_shaft: #323232;
$color_concrete_approx: #f4f2f2;
$color_bunker_approx: #151515;
$color_gray_approx: #807e7e;
$wild_sand: #f4f4f4;
$color_pumice_approx: #c9c9c9;
$color_masala_approx: #3b3b3b;
$color_mystic_approx: #e5e2ef;
$beige: #f5f5dc;
$color_tundora_approx: #464646;
$color_dark_blue_approx: #0b0eaf;
$color_swirl_approx: #cbc9c9;
$black: #000000;
$color_fuscous_gray_approx: #555555;
$color_silver_approx: #bdbdbd;
$color_chicago_approx: #575757;
$color_log_cabin_approx: #272822;
$color_mine_shaft_approx: #333;
$color_rangoon_green_approx: #1d1d1d;
$color_tapa_approx: #787777;
$color_concord_approx: #7d7d7d;
$color_stack_approx: #8a8d88;
$color_gravel_approx: #4b4b4b;
$color_alto_approx: #dadada;
$color_delta_approx: #a2a1a1;
$color_gray_nurse_approx: #e8e8e8;
$color_horizon_approx: #598099;
$color_dove_gray_approx: #6a6a6a;
$boulder: #7a7a7a;
$color_iron_approx: #d7d7d7;
$color_storm_dust_approx: #656363;
$color_mountain_mist_approx: #919191;
$color_silver_chalice_approx: #b0afaf;
$color_gallery_approx: #efeeee;
$color_jelly_bean_approx: #1d85a3;
$color_boulder_approx: #797978;
$color_forest_green_approx: #158d15;
$color_tropical_blue_approx: #bfdff3;
$color_mercury_approx: #e4e4e4;
$color_wild_sand_approx: #f5f4f3;
$color_cararra_approx: #ececeb;
$color_zanah_approx: #d8ead6;
$color_la_palma_approx: #3b8419;
$color_wild_blue_yonder_approx: #7c8fae;
$color_ironside_gray_approx: #696868;
$color_gunsmoke_approx: #858581;
$color_quill_gray_approx: #d4d3d3;
$color_friar_gray_approx: #807d7d;
$color_william_approx: #416363;
$color_green_house_approx: #285c23;
$color_cloud_approx: #c8c7c4;
$color_shady_lady_approx: #a7a6a6;
$color_deep_sea_green_approx: #0b5b59;
$silver: #c0c0c0;
$color_tuatara_approx: #363636;
$color_shakespeare_approx: #3d9fda;
$color_japanese_laurel_approx: #0f730c;
$color_french_gray_approx: #b8b8c3;
$color_clam_shell_approx: #dcacac;
$color_sangria_approx: #900707;
$color_nobel_approx: #b2afaf;
$gray: #808080;
$color_arapawa_approx: #0e156f;
$concrete: #f2f2f2;
$color_suva_gray_approx: #898888;
$color_heavy_metal_approx: #2d2d2d;
$black_12_5: rgba(0, 0, 0, 0.125);
$gallery: #efefef;
$color_san_juan_approx: #2e5179;
$color_indigo_approx: #5573d1;
$color_botticelli_approx: #cbdaeb;
$color_havelock_blue_approx: #4582e7;
$color_bay_of_many_approx: #263a96;
$color_oslo_gray_approx: #8e8f91;
$color_jewel_approx: #1a7932;
$color_venus_approx: #928d8d;
$color_cornflower_blue_approx: #6d91ed;
$color_wafer_approx: #d8caca;
$color_cape_cod_approx: #424242;
$color_shark_approx: #292929;
$color_bahama_blue_approx: #0f648f;
$color_blue_whale_approx: #073449;
$color_curious_blue_approx: #31a2db;
$color_cornflower_approx: rgb(158, 202, 231);
$color_blumine_approx: #14587a;
$color_mischka_approx: #d1d8df;
$color_swiss_coffee_approx: #d9d5d5;
$color_pale_sky_approx: #678;
$color_shuttle_gray_approx: #556870;
$color_river_bed_approx: #3c5258;
$color_pale_slate_approx: #c3c3c3;
$color_lochmara_approx: #0080c0;
$maroon: #800000;
$color_black_haze_approx: #f7f7f7;
$color_heather_approx: #b8bfd4;

//urls
$url_0: url("../img/city_oliva.png");
$url_1: url("../img/wild_oliva.png");
$url_2: url("../img/kindajean.png");
$url_3: url("../img/opacity.png");
$url_4: url("../img/loading-back.png");

//@extend-elements
//original selectors
//.menu-for-settings li a, .chat-box-wraper .chat-menu button:not(.active), .writing-area .mce-flow-layout, [class*="bd"]
%extend_1 {
  border: 0 solid $color_celeste_approx;
}

//original selectors
//[class*="arrow"]:before, [class*="arrow"]:after
%extend_2 {
  position: absolute;
  //border-style: solid;
}

//original selectors
//.btn-black, .btn-gray
%extend_3 {
  padding: 5px 25px;
  line-height: 0.4;
  font-size: 13px;
  height: 25px !important;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
  display: inline-block;
}

//original selectors
//.btn-black:hover, .btn-black:focus
%extend_4 {
  color: $color_concrete_approx;
  border: 1px solid $color_bunker_approx;
  background: $color_emperor_approx $url_1 repeat-x;
}

//original selectors
//.sub-navi ul li, .social-nav ul li
%extend_5 {
  list-style: none;
  display: inline-block;
  padding: 0 20px;
}

//original selectors
//.sub-navi ul li a, .social-nav ul li a
%extend_6 {
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  position: relative;
}

//original selectors
//.social-nav ul li a.active:before, .sub-navi ul li a.active:before
%extend_7 {
  content: "";
  position: absolute;
  left: 19%;
  bottom: -10px;
  border-style: solid;
  border-width: 0 9px 11px 9px;
  border-color: #cbc9c9 transparent;
  display: block;
  width: 0;
  z-index: 0;
}

//original selectors
//.social-nav ul li a.active:after, .sub-navi ul li a.active:after
%extend_8 {
  content: "";
  position: absolute;
  bottom: -11px;
  left: calc(19% + 1px);
  border-style: solid;
  border-width: 0 8px 10px 8px;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 0;
}

//original selectors
//.blog-post-area input[type=text], .post-title-here select
%extend_9 {
  width: 100%;
  height: 45px;
  padding-left: 10px;
  margin-bottom: 10px;
  border: 1px solid;
  color: $color_fuscous_gray_approx;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
}

//original selectors
//.post-type span, .post-type label
%extend_10 {
  width: 100%;
  display: inline-block;
  position: relative;
  padding-left: 25px;
  padding-top: 5px;
  font-weight: normal;
  font-size: 13px;
  color: $color_dove_gray_approx;
  cursor: pointer;
}

//original selectors
//.content-header .title, .content-header .title a
%extend_11 {
  font-size: 14px;
  margin-bottom: 2px !important;
  color: $color_storm_dust_approx;
}

//original selectors
//.comments-area .commentator, .comments-group .comments .commentator
%extend_12 {
  display: block;
  width: 35px;
  height: 35px;
  float: left;
}

//original selectors
//#modal_for_image_upload .album, .myPic .album
%extend_13 {
  margin-top: 0;
  width: auto;
  min-width: 100% !important;
  padding: 15px 0;
}

//original selectors
//.myFriends .friends-action, .myPic .pic-action
%extend_14 {
  position: relative;
  margin-top: 15px;
  min-height: 34px;
}

//original selectors
//.myFriends .friends-action button, .myPic .pic-action button
%extend_15 {
  text-align: left;
  color: $color_ironside_gray_approx;
  font-size: 14px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
}

//original selectors
//#modal_for_image_upload .album li .pic, #modal_for_image_upload .album li .picOnly, .myPic .album li .picOnly, .myPic .album li .pic
%extend_16 {
  height: 150px;
  overflow: hidden;
  position: relative;
}

//original selectors
//#modal_for_image_upload .album li .pic img, .myFriends .friends-list li .pic img, .myPic .album li .picOnly img, .myPic .album li .pic img
%extend_17 {
  cursor: pointer;
  min-width: 100%;
  max-height: 100%;
}

//original selectors
//#modal_for_image_upload .album li .album-info a, .myPic .album li .album-info a
%extend_18 {
  color: $color_gunsmoke_approx;
  font-size: 14px;
  text-decoration: none;
}

//original selectors
//#modal_for_image_upload .album li .album-info span, .myPic .album li .album-info span
%extend_19 {
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid $color_celeste_approx;
  font-size: 11px;
  padding: 2px 7px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 15px;
  color: $color_stack_approx !important;
  background-color: $white;
}

//original selectors
//.myPostType .type-header .type-selection button, .myPostType .type-header .limit-selection button, .myPostType .type-header .query-selection button
%extend_20 {
  position: relative;
  height: 40px;
  width: 100% !important;
  text-align: left;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
  color: $color_stack_approx;
}

//original selectors
//.mySettings input[type=submit], .mySettings button
%extend_21 {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
  padding: 3px 12px;
  border: 1px solid $color_quill_gray_approx;
  background-color: $concrete;
  color: $color_silver_chalice_approx;
  font-size: 13px;
  margin-left: 5px;
}

//original selectors
//.mySettings input[type=submit], .mySettings button[type=submit]
%extend_22 {
  color: $color_bon_jour_approx;
  width: 108px;
  background: $color_emperor_approx $url_0 repeat;
  border: 1px solid $mine_shaft;
  font-size: 13px;
}

//original selectors
//.mySettings input[type=submit]:hover, .mySettings button[type=submit]:hover
%extend_23 {
  color: $color_concrete_approx;
  border: 1px solid $color_bunker_approx;
  background: $color_emperor_approx $url_1 repeat-x;
}

//original selectors
//.optional-upload-area button, .image_process_area .upload-area button
%extend_24 {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
  border: 1px solid $color_pumice_approx;
  color: $color_delta_approx;
  width: 100%;
}

//original selectors
//.optional-upload-area button:focus, .optional-upload-area button
%extend_25 {
  background: $color_emperor_approx $url_0 repeat;
  color: $color_celeste_approx;
  border-color: $color_chicago_approx;
  padding: 4px 10px !important;
}

//original selectors
//.chat-info .user-name, .chat-short-info .user-name
%extend_26 {
  position: relative;
  font-size: 14px;
  color: $color_mine_shaft_approx;
  margin-bottom: 14px;
}

//original selectors
//.chat-info .seinding-time, .chat-short-info .seinding-time
%extend_27 {
  position: absolute;
  right: 10px;
  top: 10px;
  color: $color_oslo_gray_approx;
  font-size: 12px;
}

//original selectors
//.ifry .panel-heading, .ifry .panel-body, .ifry .panel-footer
%extend_28 {
  position: relative;
  border: none;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
  color: $black;
}

//original selectors
//.complain-wrap .complain-for, .tile-container .complain-for
%extend_29 {
  display: none;
  width: 100%;
  margin-top: 23px;
  border-bottom: 1px solid $color_celeste_approx;
  border-top: 1px solid $color_swiss_coffee_approx;
  padding: 2px 10px 5px;
}

//original selectors
//.photo-frame, .friends-album
%extend_30 {
  margin-top: 30px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
  border: none;
}

//original selectors
//.photo-frame .tiar, .friends-album .tiar
%extend_31 {
  width: 100%;
  font-size: 0;
  margin-bottom: 2.6px;
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
}

//original selectors
//.photo-frame .cage, .friends-album .cage
%extend_32 {
  font-size: 14px;
  background-color: #e5e2ef;
  display: flex;
  height: 87px !important;
  margin-right: 2.6px;
  flex-grow: 1;
  width: calc(100% * (1/4) - 10px - 1px);
  object-fit: cover;
  margin-bottom: 3px;
}
.dropdown.user-post-share, .dropdown.user-post-like {
  display: inline-block;
  cursor: pointer;
}
.dropdown.user-post-like > span{
  position: relative;
  left: -10px;
}
.dropdown.user-post-like > span + .dropdown-menu {
    left: -10px;
  }

.menu-for-settings {
  margin: 15px 0;
  padding: 0;
  li {
    list-style: none;
    padding: 0;
    a {
      @extend %extend_1;
      position: relative;
      color: $color_mountain_mist_approx;
      display: block;
      padding: 6px 10px;
      height: 34px;
      text-decoration: none;
      border: 1px solid $color_celeste_approx;
      &.active {
        color: $color_tuatara_approx;
      }
      &:hover {
        color: $color_tuatara_approx;
      }
      &:after {
        content: "";
        position: absolute;
        top: -1px;
        right: -3px;
        border-style: solid;
        border-width: 17px 0 17px 3px;
        display: block;
        width: 0;
        z-index: 0;
      }
    }
    + li {
      margin-top: 3px;
    }
  }
}

.chat-box-wraper {
  min-height: 450px;
  .chat-menu {
    padding: 15px;
    text-align: center;
    button {
      text-align: left;
      color: $color_stack_approx;
      font-size: 13px;
      border: 1px $color_celeste_approx;
      position: relative;
      span {
        padding-left: 15px;
      }
      &:hover {
        border-color: $color_nobel_approx;
        color: $color_dove_gray_approx;
        //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        box-shadow: inset 0 3px 5px $black_12_5;
      }
      &.active {
        background: $color_emperor_approx $url_0 repeat;
        color: $color_quill_gray_approx;
        border-color: $color_fuscous_gray_approx;
      }
      &:not(.active) {
        @extend %extend_1;
      }
    }
  }
  * {
    border: 0 solid $color_quill_gray_approx;
  }
  .chat-history {
    height: 579px;
    overflow: hidden;
    position: relative;
    border-right-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
  }
  .chat-list-area {
    height: 579px;
    overflow: hidden;
    border-right-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
  }
  .chat-header {
    position: relative;
    height: 45px;
    padding: 0;
    background-color: $gallery;
    border-bottom-width: 1px;
    border-top-width: 1px;
    > {
      .inbox {
        position: absolute;
        left: 8px;
        top: 13px;
        font-size: 12px;
        padding: 0;
        z-index: 2;
        text-decoration: none;
        &:hover {
          color: $color_san_juan_approx;
        }
      }
      .current-chat-type {
        position: absolute;
        top: 0;
        left: 10px;
        right: 50px;
        bottom: 0;
        line-height: 40px;
        padding: 0;
        color: $color_indigo_approx;
        font-size: 13px;
      }
      .dropdown {
        position: relative;
        top: 8px;
        right: 10px;
      }
    }
    .drop-btn {
      padding: 1px 8px;
      color: $color_shady_lady_approx;
      border: 1px solid $color_silver_approx;
      background-color: transparent;
      font-size: 18px;
    }
  }
}

.writing-area {
  .mce-flow-layout {
    @extend %extend_1;
    border: none !important;
    padding: 2px 0 5px !important;
    border-bottom: 1px solid $color_celeste_approx !important;
  }
  .mce-panel {
    background: none !important;
    border: none !important;
  }
  .mce-btn {
    border: none !important;
    background: none !important;
    button {
      padding: 2px 4px !important;
    }
    &.mce-active button {
      background: none !important;
    }
  }
  .mce-ico {
    font-size: 14px !important;
    color: $boulder !important;
  }
  .mce-menu {
    background-color: $white !important;
    border: 1px solid $color_iron_approx !important;
  }
  .blog-post-area #mceu_23-body {
    border: 1px solid $color_celeste_approx !important;
  }
  .status-area .mce-flow-layout {
    background: transparent !important;
    border: none !important;
    width: 30% !important;
    position: absolute;
    left: 0;
    bottom: -51px;
  }
  .mce-flow-layout-item:not(:first-child) {
    margin-left: 15px !important;
  }
}

[class*="bd"] {
  @extend %extend_1;
}

.bds2 {
  border-color: $color_celeste_approx;
}

.bds3 {
  border-color: $color_celeste_approx;
}

.bd {
  border-width: 1px;
}

[class*="bdt"] {
  border-top-width: 1px;
}

[class*="bdh"] {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.bd-r {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
}

.bd-b {
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
}

.bd-l {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-right-width: 1px;
}

[class*="bdb"] {
  border-bottom-width: 1px;
}

.bd-t {
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
}

[class*="bdl"] {
  border-left-width: 1px;
}

[class*="bdv"] {
  border-left-width: 1px;
  border-right-width: 1px;
}

.bdtl {
  border-left-width: 1px;
}

.bdbl {
  border-left-width: 1px;
}

[class*="bdr"] {
  border-right-width: 1px;
}

.bdtr {
  border-right-width: 1px;
}

.bdbr {
  border-right-width: 1px;
}

[class*="arrow"] {
  &:before {
    @extend %extend_2;
  }
  &:after {
    @extend %extend_2;
  }
}

.arrowTop {
  &:before {
    border-color: $color_star_dust_approx transparent;
    top: -11px;
    left: 5px;
    border-width: 0 8px 11px 8px;
  }
  &:after {
    border-color: $white transparent;
    top: -10px;
    left: 6px;
    border-width: 0 7px 10px 7px;
  }
}

.arrowDown {
  &:before {
    border-color: $color_star_dust_approx transparent;
    bottom: -11px;
    left: 5px;
    border-width: 11px 8px 0 8px;
  }
  &:after {
    border-color: $white transparent;
    bottom: -10px;
    left: 6px;
    border-width: 10px 7px 0 7px;
  }
}

.arrowLeft {
  &:before {
    border-color: transparent $color_star_dust_approx;
    top: 5px;
    left: -11px;
    border-width: 8px 11px 8px 0;
  }
  &:after {
    border-color: transparent $white;
    top: 6px;
    left: -10px;
    border-width: 7px 10px 7px 0;
  }
}

.arrowRight {
  &:before {
    border-color: transparent $color_star_dust_approx;
    top: 5px;
    right: -11px;
    border-width: 8px 0 8px 11px;
  }
  &:after {
    border-color: transparent $white;
    top: 6px;
    right: -10px;
    border-width: 7px 0 7px 10px;
  }
}

.btn-black {
  @extend %extend_3;
  color: $color_bon_jour_approx;
  background: $color_emperor_approx $url_0 repeat;
  border: 1px solid $mine_shaft;
  &:hover {
    @extend %extend_4;
  }
  &:focus {
    @extend %extend_4;
  }
}

.btn-gray {
  @extend %extend_3;
  color: $color_gray_approx;
  background: $wild_sand $url_2 repeat !important;
  border: 1px solid $color_pumice_approx;
  &:hover {
    color: $color_masala_approx;
    border: 1px solid $color_star_dust_approx;
  }
  &:focus {
    color: $color_masala_approx;
    border: 1px solid $color_star_dust_approx;
  }
}

.picFrame {
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    max-width: 120%;
    top: 50%;
    left: 50%;
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translate(-50%, -50%);
  }
}

.image-holder {
  display: block;
  width: 145px;
  height: 145px;
}

.content-box {
  margin-bottom: 5px;
  padding: 0 15px;
  background-color: $white;
  margin-top: 64px;
  &.mt25 {
    margin-top: 25px;
  }
}

.top-gap {
  position: fixed;
  top: 50px;
  width: 100%;
  height: 15px;
  background-color: $color_mystic_approx;
  z-index: 50;
}

.social-nav {
  height: 40px;
  padding: 9px 15px;
  background: #0B7ED4;
  ul {
    padding: 0;
    li {
      @extend %extend_5;
      a {
        @extend %extend_6;
        &:hover {
          color: $color_dark_blue_approx;
        }
        &.active {
          color: $color_dark_blue_approx;
          &:before {
            @extend %extend_7;
          }
          &:after {
            @extend %extend_8;
            border-color: $beige transparent;
          }
        }
      }
      &:first-child {
        padding-left: 0 !important;
      }
    }
  }
}

.sub-navi {
  width: 100%;
  height: 40px;
  padding: 10px 15px;
  background-color: $beige;
  text-align: right;
  ul {
    padding: 0;
    li {
      @extend %extend_5;
      padding: 0 20px;
      a {
        @extend %extend_6;
        &:hover {
          color: $color_dark_blue_approx;
        }
        &.active {
          color: $color_dark_blue_approx;
          &:before {
            @extend %extend_7;
          }
          &:after {
            @extend %extend_8;
          }
        }
      }
    }
    & > li:first-child {
      padding-left: 0 !important;
    }
  }
  &.type-group {
    text-align: left;
  }
  .basic-recent-post {
    ul {
      min-width: 100%;
      text-align: center;
      li {
        padding: 0;
        display: block;
      }
    }
  }
}

.banner {
  padding: 0 !important;
  max-height: 320px !important;
  position: relative;
  overflow: hidden;
  .drag-notice {
    position: absolute;
    top: 50%;
    left: 50%;
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translate(-50%, -50%);
    padding: 7px 25px;
    background-color: $black;
    color: $white;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 5px;
    opacity: .4;
  }
  .friends-req-area {
    position: absolute;
    bottom: 22px;
    right: 25px;
    > * {
      float: right;
      margin-left: 10px;
    }
  }
  .after-friends-req {
    padding: 10px 15px;
    position: relative;
    background-color: #0081d5;
    color: #ffffff;
    border-radius: 4px;
  }
  .friends-req {
    .btn {
      position: relative;
      padding: 8px 15px;
      color: #0081d5;
      font-weight: bold;
    }
    button:hover {
      color: $color_log_cabin_approx;
      opacity: 1;
    }
  }
  .banner-action {
    position: absolute;
    right: 20px;
    bottom: 23px;
    a {
      padding: 6px 18px;
      background-color: $white;
      font-size: 14px;
      color: $color_bunker_approx;
      text-decoration: none;
      opacity: 0.5;
      cursor: pointer;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 5px;
      margin-left: 5px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .banner-pic {
    max-width: 100% !important;
  }
  .profile-pic {
    max-width: 100% !important;
    + .action {
      font-size: 12px;
      padding: 2px 13px;
      right: 10px !important;
      bottom: 25px !important;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 15px !important;
      > button {
        font-size: 12px;
      }
      .dropdown-menu {
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 5px;
        left: -26px;
        top: 25px;
      }
      &:hover {
        display: inline-block;
      }
    }
    &:hover + .action {
      display: inline-block;
    }
  }
  .action {
    right: 20px;
    background-color: $color_mine_shaft_approx;
    font-size: 14px;
    color: $white;
    cursor: pointer;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 5px;
    bottom: 85px;
    position: absolute;
    opacity: 0.5;
    padding: 4px 15px;
    text-decoration: none;
    z-index: 1;
    display: none;
    button {
      background-color: transparent;
      color: $white;
      font-size: 13px;
      padding: 0;
    }
    .dropdown-menu {
      opacity: 1 !important;
      margin-top: -4px;
      left: -42px;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 5px 0 5px 5px;
      > li > a:hover {
        color: $color_rangoon_green_approx;
      }
    }
  }
  #banner-holder {
    &:hover + .action {
      display: inline-block;
    }
    + .action:hover {
      display: inline-block;
      opacity: 1;
    }
  }
  .user-intro {
    position: absolute;
    bottom: 23px;
    left: 40px;
    img {
      display: block;
      width: 120px;
      height: 120px;
      background-color: $white;
      padding: 1px;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 5%;
      margin: 10px auto;
      min-width: initial;
    }
    .title {
      padding: 3px 15px;
      margin: 0 auto;
      color: $color_tapa_approx;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 4px;
      cursor: pointer;
      background-color: $color_concord_approx;
      font-size: 15px;
      text-decoration: none;
      &:hover {
        color: $color_log_cabin_approx;
      }
    }
  }
  img + .action:hover {
    opacity: 1;
  }
}

.after-banner-select {
  display: none;
}

.after-friends-req {
  .dropdown {
    position: absolute;
    top: 0;
    right: 0;
  }
  .btn {
    padding: 3px 10px;
    background-color: transparent;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
    color: $color_silver_approx;
    border: none;
  }
  .dropdown-menu {
    left: auto;
    right: -1px;
    margin-top: -1px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 4px 0 4px 4px;
  }
}

.right-column {
  position: relative;
  .status-area {
    display: none;
    &:before {
      left: 60px;
    }
    &:after {
      left: 61px;
    }
  }
}

.fixed-box {
  position: fixed;
  width: 500px !important;
}

.social-header {
  float: left;
  width: 100%;
  .query-selection {
    button {
      background: red;
    }
  }
  &:after {
    content: ' ';
    position: absolute;
    width: 2px !important;
    background-color: $white;
    top: 0;
    right: 0;
    height: 100% !important;
  }
  .title {
    position: relative;
    top: -2px;
    padding-right: 7px;
    color: $black;
    font-size: 16px;
    .count {
      border: 1px solid $color_celeste_approx;
      font-size: 11px;
      padding: 2px 7px;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 15px;
      color: $color_stack_approx !important;
      position: relative;
      left: -5px;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: $color_gravel_approx !important;
      }
    }
    a {
      text-decoration: none;
      color: $color_alto_approx;
      padding-right: 7px;
      &:hover {
        color: $black;
      }
      &.active {
        color: $black;
      }
      &:not(:first-child) {
        padding-left: 7px;
      }
    }
  }
}

input {
  outline: none;
}

textarea {
  outline: none;
}

.status-area {
  width: 100%;
  position: relative;
  float: left;
  textarea {
    width: 100%;
    height: 70px;
    padding: 3px 6px;
    border: none;
    resize: none;
    float: left;
  }
  .action-box {
    width: 100%;
    background-color: $color_concrete_approx;
    padding: 5px;
    float: left;
  }
}

.action-box {
  text-align: right;
  float: right;
  margin-top: 15px;
  i {
    color: $color_delta_approx;
    font-size: 17px;
    margin-right: 5px;
    margin-top: 5px !important;
    line-height: 25px;
    cursor: pointer;
    &:hover {
      color: $color_tapa_approx;
    }
  }
  select.form-control {
    height: 25px !important;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
    display: inline-block;
    width: 150px !important;
    padding: 3px 5px !important;
    color: $color_stack_approx;
    option {
      padding: 3px !important;
      font-size: 10px !important;
      &.public:before {
        content: "\f00d";
      }
    }
  }
}

.blog-post-area {
  width: 100%;
  padding-top: 20px;
  position: relative;
  padding-bottom: 20px;
  float: left;
  input[type=text] {
    @extend %extend_9;
  }
  textarea {
    width: 100%;
    height: 70px;
    padding: 3px 6px;
    resize: none;
    margin: 10px 0 15px;
  }
}

.comments-bubble {
  .status-area {
    &:before {
      display: none !important;
    }
    &:after {
      display: none !important;
    }
  }
  .comments-by {
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 10px !important;
  }
}

.sequential-box {
  position: relative;
  width: 60%;
  display: inline-block;
  label {
    background-color: $white;
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    border: 1px solid $color_delta_approx;
    cursor: pointer;
    i {
      font-size: 20px;
      color: $color_delta_approx;
      position: relative;
      top: 3px;
      left: 7px;
    }
  }
  input {
    &:checked + label i {
      left: 4px;
      &:before {
        content: "\f00c";
      }
    }
    + label i:before {
      content: "\f00d";
    }
  }
  input[type=text] {
    padding-left: 35px;
  }
  input[type=checkbox] {
    display: none;
  }
}

.from-old-post {
  display: none;
  font-weight: normal;
  margin-left: 15px;
  cursor: pointer;
  input {
    display: none;
    + i:before {
      color: $color_horizon_approx;
      font-size: 16px;
      content: "\f096";
    }
    &:checked + i:before {
      content: "\f14a";
    }
  }
}

.post-type {
  width: 100%;
  padding: 0;
  float: left;
  margin-top: 10px;
  .group {
    margin-bottom: 5px;
    position: relative;
    > {
      #plus-minus {
        position: absolute;
        top: 0;
        right: 0;
        display: none;
      }
      .radioBox {
        display: none;
      }
    }
    > i {
      cursor: pointer;
      &:hover:before {
        opacity: 1;
      }
    }
    .child-group {
      border-top: 1px dashed $color_pink_swan_approx;
      padding-left: 15px;
    }
    span {
      line-height: 15px;
    }
    label {
      line-height: 15px;
      i {
        position: absolute;
        font-size: 15px;
        top: 5px;
        left: 5px;
      }
    }
    input {
      + label i:before {
        content: "\f096";
      }
      &:checked + label i:before {
        content: "\f046";
      }
    }
    #plus-minus:before {
      font-size: 16px;
      color: $color_star_dust_approx;
      float: right;
      padding: 3px 4px;
      border-left: 1px solid $color_star_dust_approx;
      background-color: $color_alto_approx;
      opacity: .4;
      cursor: pointer;
    }
  }
  .radioBox {
    color: $color_dove_gray_approx;
    i {
      color: $color_dove_gray_approx;
    }
    input:checked + i:before {
      color: $color_dove_gray_approx;
    }
  }
  span {
    @extend %extend_10;
  }
  label {
    @extend %extend_10;
  }
  > div:not(:last-child) > .group {
    margin-right: 5px;
  }
}

.tile {
  margin: 15px 0;
  float: left;
  &.cut-margin-top {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  &.mt50 {
    margin-top: 50px !important;
  }
  [class^="tile-"] {
    width: 100%;
    float: left;
  }
}

.cut-padding-left {
  padding-left: 0 !important;
}

.tile-body {
  padding: 0;
}

.tile-container {
  padding: 0;
  > div {
    padding: 0;
  }
  .complain-for {
    @extend %extend_29;
  }
  &:not(:first-child) .content-header {
    padding-top: 10px;
  }
}

.content-header {
  .title {
    @extend %extend_11;
    a {
      @extend %extend_11;
    }
    + .complain-for {
      margin-top: 50px;
    }
  }
  a.author-icon {
    float: left;
    margin: 0 7px 0 0;
    img {
      display: block;
      float: left;
      width: 42px;
      height: 42px;
    }
  }
}

small {
  font-size: 12px;
  color: $color_storm_dust_approx;
}

.content-body {
  font-size: 13px;
  padding-top: 7px !important;
  padding-bottom: 15px !important;
  padding-left: 49px !important;
  img {
    max-width: 100% !important;
    max-height: 100% !important;
  }
  .seeAll:hover {
    text-decoration: none;
    color: $color_forest_green_approx;
  }
}

.friends-request {
  .tr {
    .td {
      &:first-child {
        width: 60px !important;
        height: 60px !important;
        img {
          width: 100% !important;
          height: 100% !important;
        }
      }
      &:nth-child(2) {
        width: 273px !important;
        height: 60px !important;
        font-size: 11px;
        color: $color_mountain_mist_approx;
        padding: 0 5px;
      }
      &:last-child {
        width: 70px !important;
        height: 60px !important;
        text-align: right;
        padding-top: 15px;
      }
      .newFriend {
        color: $color_fuscous_gray_approx;
        display: block;
        font-size: 13px;
      }
      .oldFriend {
        color: $color_storm_dust_approx;
        font-size: 11px;
      }
    }
    &:not(:last-child) {
      padding-bottom: 5px;
    }
  }
  .request-by {
    position: relative;
    bottom: 0;
    img {
      position: relative;
      display: inline-block;
      width: 20px !important;
      height: 20px !important;
    }
  }
  .btn-group {
    .btn {
      padding: 3px 8px !important;
      font-size: 12px !important;
    }
    button {
      &:first-child {
        color: $color_mountain_mist_approx;
        &:hover {
          color: $color_fuscous_gray_approx;
        }
      }
      &:last-child {
        background: $wild_sand $url_0 repeat;
        color: $color_silver_chalice_approx;
        border: 1px solid $color_fuscous_gray_approx;
        &:hover {
          color: $color_gallery_approx;
        }
      }
    }
  }
}

.seeAll {
  position: absolute;
  bottom: 5px;
  right: 0;
  font-size: 13px;
}

.content-footer {
  float: left;
  padding-left: 49px !important;
  a {
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: $color_forest_green_approx;
    }
  }
}

.forMyPostPage {
  .content-body {
    padding-left: 0 !important;
  }
  .content-footer {
    padding-left: 0 !important;
  }
  .comments-box {
    padding-left: 0 !important;
  }
  .left-column {
    padding-top: 0;
  }
  .right-column {
    padding-top: 0;
  }
  .comments-group .comments.ans {
    display: block;
  }
  .content-header .post-hit-count {
    margin-top: 20px;
  }
}

.separator {
  width: 100%;
  height: 1px;
  margin: 5px 0;
  display: block;
  float: left;
  border-bottom: 1px dashed $color_pumice_approx;
}

.post-action-list {
  float: left;
  a {
    font-size: 13px;
    color: $color_jelly_bean_approx;
    margin-right: 10px;
  }
  .hidden-xs {
    display: inline !important;
    padding-left: 5px;
  }
}

.post-hit-count {
  float: right;
  margin-top: -1px;
  > a {
    margin-left: 10px;
    font-size: 14px;
    color: $color_boulder_approx;
  }
  a {
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: $color_forest_green_approx;
    }
  }
  .dropdown {
    display: inline-block;
    > a {
      border: 1px solid $color_pumice_approx;
      padding: 0 4px;
      text-decoration: none;
      color: $color_boulder_approx;
      &:hover {
        border: 1px solid $color_chicago_approx;
        color: $white;
        background-color: $color_chicago_approx;
      }
    }
  }
  .dropdown-menu {
    margin-right: -1px;
    margin-top: -1px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0 0 0 5px;
    background-color: $color_cararra_approx;
    a {
      padding-right: 15px;
      color: $color_dove_gray_approx;
      &:hover {
        background-color: $color_alto_approx;
        color: $color_jelly_bean_approx;
      }
      i {
        margin-right: 5px;
      }
    }
  }
}

.comments-box {
  float: left;
  margin-top: 10px;
  padding-left: 49px !important;
  margin-bottom: 20px;
  .post-hit-count .dropdown > a {
    color: $color_dove_gray_approx;
    background-color: $color_wild_sand_approx;
  }
}

.comments-group {
  padding-top: 6px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  .comments {
    margin-bottom: 5px !important;
    &.ans {
      padding-left: 44px !important;
      display: none;
    }
    .commentator {
      @extend %extend_12;
    }
  }
  .comments-bubble {
    padding-left: 44px !important;
    color: $color_dove_gray_approx;
    font-size: 13px;
  }
  .comments-here {
    padding-left: 44px !important;
    textarea {
      border-color: $color_mercury_approx;
    }
  }
  &:hover {
    border-top-color: $color_tropical_blue_approx;
    border-bottom-color: $color_tropical_blue_approx;
  }
}

.comments-area {
  padding: 0;
  margin-bottom: 8px;
  .comments-bubble {
    padding-left: 44px !important;
    height: 35px;
    width: 100%;
    textarea {
      width: 100%;
      height: 35px;
      padding: 6px 8px;
      border: 1px solid $color_silver_approx;
      resize: none;
    }
  }
  .commentator {
    @extend %extend_12;
  }
}

.comments-body {
  position: relative;
  p {
    margin-bottom: 5px !important;
  }
  img {
    max-width: 250px;
    max-height: 200px;
  }
}

.full-comments-view {
  font-size: 12px;
  display: block;
  color: $color_wild_blue_yonder_approx;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

#modal_for_image_upload {
  .album {
    @extend %extend_13;
    display: inline-block;
    margin-left: 10px;
    padding: 8px 0 15px;
    li {
      list-style: none;
      margin-right: 11px;
      margin-bottom: 15px;
      position: relative;
      border: 1px solid $color_alto_approx;
      float: left;
      &:nth-child(7n) {
        margin-right: 0;
      }
      .pic {
        @extend %extend_16;
        width: 125px;
        height: 125px;
        img {
          @extend %extend_17;
        }
      }
      .picOnly {
        @extend %extend_16;
        width: 125px;
        height: 125px;
        img {
          cursor: pointer;
          max-height: 100%;
          min-width: 100%;
          min-height: 100%;
        }
      }
      .album-info {
        position: relative;
        padding: 0 5px 5px;
        max-width: 125px;
        height: 25px;
        overflow: hidden;
        a {
          @extend %extend_18;
          &:hover {
            color: $color_log_cabin_approx;
          }
        }
        span {
          @extend %extend_19;
        }
      }
    }
  }
  .modal-dialog {
    width: 1080px;
  }
  .modal-body {
    height: 600px;
    overflow: hidden;
  }
  .album-name {
    margin-top: 10px;
    margin-left: 10px;
    color: $color_mountain_mist_approx;
    i {
      margin-right: 10px;
      font-size: 20px;
    }
  }
  .from_computer {
    display: none;
  }
  .image_process_area {
    width: 100%;
    height: 600px;
    overflow: auto;
    padding: 15px 0 15px 15px;
  }
}

.myPic {
  .album {
    @extend %extend_13;
    li {
      list-style: none;
      .loading-parent {
        position: relative;
        border: 1px solid $color_alto_approx;
        margin-bottom: 20px;
      }
      &:nth-child(6n) {
        margin-right: 0;
      }
      > div {
      }
      .picOnly {
        @extend %extend_16;
        img {
          @extend %extend_17;
        }
      }
      .pic {
        @extend %extend_16;
        img {
          @extend %extend_17;
        }
      }
      .album-info {
        position: relative;
        padding: 0 5px 5px;
        height: 25px;
        overflow: hidden;
        margin-top: 5px;
        a {
          @extend %extend_18;
          &:hover {
            color: $color_log_cabin_approx;
          }
        }
        span {
          @extend %extend_19;
        }
      }
    }
  }
  .pic-action {
    @extend %extend_14;
    padding: 0 15px;
    .action-subset {
      position: relative;
      width: 100%;
      min-height: 35px;
      > button {
        position: absolute;
        right: 0;
        padding: 5px 12px 5px 10px;
        i {
          font-size: 20px;
          margin: 0;
        }
      }
    }
    .action-toDo {
      display: none;
      float: right;
      margin-right: 15px;
      button[disabled] {
        background-image: none;
        background-color: $color_cararra_approx !important;
        color: $color_swirl_approx;
        border-color: $color_bon_jour_approx;
        cursor: move;
        opacity: 1;
      }
    }
    button {
      @extend %extend_15;
      &:hover {
        color: $color_tapa_approx;
      }
      i {
        font-size: 16px;
        margin-right: 10px;
      }
      &.margR10 {
        margin-right: 15px;
      }
      &:not(:last-child) {
        margin-right: 2px;
      }
    }
  }
}

.myFriends {
  .friends-list {
    min-width: 100% !important;
    padding: 15px 0;
    > li {
      list-style: none;
      margin-bottom: 15px;
      height: 100px;
    }
    li {
      &:nth-child(2n) {
        margin-right: 0;
      }
      .pic {
        width: 100px;
        height: 100px;
        float: left;
        img {
          @extend %extend_17;
        }
      }
      .friends-info {
        position: relative;
        width: 100%;
        height: 100px;
        padding: 5px 10px 5px 110px;
        a {
          color: $color_storm_dust_approx;
          font-size: 16px;
          text-decoration: none;
          &:hover {
            color: $color_log_cabin_approx;
          }
        }
      }
    }
  }
  .friends-box {
    width: 100%;
    float: left;
    position: relative;
    border: 1px solid $color_alto_approx;
  }
  .friends-action {
    @extend %extend_14;
    button {
      @extend %extend_15;
      width: 120px;
      position: relative;
      &:hover {
        color: $color_tapa_approx;
      }
      &.margR10 {
        margin-right: 15px;
      }
      &:not(:last-child) {
        margin-right: 2px;
      }
    }
    i {
      font-size: 16px;
      margin-right: 10px;
    }
  }
}

.friends-settings {
  position: absolute;
  bottom: 10px;
  right: 10px;
  .btn-black {
    padding: 5px 10px;
    i {
      text-align: left;
      margin-left: 30px;
    }
  }
  .dropdown-menu {
    margin-left: 0;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0 0 4px 4px;
    margin-top: 0;
    min-width: 82px;
    li {
    }
    a {
      font-size: 13px !important;
    }
  }
}

.wrap-pic-area {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 160px;
  &:hover {
    background: $url_3 repeat;
  }
  label {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    i {
      font-size: 25px;
      color: $color_iron_approx;
    }
  }
  input[type=checkbox] {
    display: none;
  }
  input {
    + label i:before {
      content: "\f096";
    }
    &:checked + label i:before {
      content: "\f14a";
    }
  }
}

.album-writing-area {
  display: none;
  position: relative;
  margin-top: 10px;
  padding: 3px 10px 2px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
  text-align: left;
  color: $color_delta_approx;
  font-size: 14px;
  border: 1px solid $color_quill_gray_approx;
  float: left;
  margin-right: 50%;
  margin-bottom: 10px;
  label {
    padding-right: 5px;
    font-weight: normal;
    font-size: 15px;
  }
  input {
    border: 1px solid $color_quill_gray_approx;
    width: 300px;
    height: 30px;
    padding: 3px 10px;
    color: $color_dove_gray_approx;
  }
  .btn-group {
    margin-left: 5px;
    button {
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 0;
      padding: 4px 10px;
      position: relative;
      top: -1px;
      left: -5px;
    }
    button[type=submit] {
      background: $color_emperor_approx $url_0 repeat;
      color: $color_celeste_approx;
      border: 1px solid $color_stack_approx;
      &:hover {
        color: $white;
      }
    }
  }
}

.myPostType {
  padding: 0 !important;
  .type-header {
    .btn-group {
      display: block;
      .dropdown-menu {
        width: 100%;
        margin-top: 0;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 0;
        border-top: none;
        > li > a {
          color: $color_friar_gray_approx;
          font-size: 13px;
          &:hover {
            background: $color_emperor_approx $url_0 repeat;
            color: $color_gallery_approx;
          }
          &:focus {
            background: $color_emperor_approx $url_0 repeat;
            color: $color_gallery_approx;
          }
        }
      }
      > button > i {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 18px;
      }
    }
    .type-selection {
      width: 100%;
      button {
        @extend %extend_20;
      }
    }
    .limit-selection {
      width: 100%;
      button {
        @extend %extend_20;
      }
    }
    .query-selection {
      width: 100%;
      button {
        @extend %extend_20;
      }
    }
  }
  .type-list {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      cursor: pointer;
      border: 1px solid $color_quill_gray_approx;
      margin-bottom: 5px;
      &:first-child {
        margin-top: 5px;
      }
      &:hover {
        background-color: $color_mystic_approx;
      }
      .title {
        padding: 0 10px;
        font-size: 14px;
        margin-bottom: 2px !important;
        color: $color_storm_dust_approx;
        padding-top: 5px !important;
      }
      .publish-info {
        padding: 0 10px;
        margin-bottom: 5px;
      }
      .comments {
        overflow: hidden;
        text-overflow: ellipsis;
        font-style: italic;
        font-size: 12px;
        color: $color_william_approx;
        margin-top: 5px;
        padding: 0 10px 5px;
      }
      .clr-area {
        padding: 2px 10px 5px;
        min-height: 20px;
        border-top: 1px solid $color_cloud_approx;
      }
      .thread-view {
        display: none;
        .comments {
          padding-bottom: 10px;
          margin-bottom: 10px;
          &:not(:last-child) {
            border-bottom: 1px dashed $color_silver_approx;
          }
        }
      }
    }
    .author-icon img {
      width: 45px;
      float: left;
      margin: 3px 10px 0 3px;
    }
  }
  .type-pagi {
    height: 30px;
    border: 1px solid $color_celeste_approx;
    > a {
      float: left;
      width: 10%;
      color: $color_storm_dust_approx;
      text-decoration: none;
      font-size: 20px;
      cursor: pointer;
      text-align: center;
      &:hover {
        color: $black;
      }
    }
    .body {
      float: left;
      width: 80%;
      text-align: center;
      color: $color_stack_approx;
      padding: 4px 10px;
      border-right: 1px solid $color_cloud_approx;
      border-left: 1px solid $color_cloud_approx;
      input.currentPage {
        width: 50px;
        text-align: right;
        border: 1px solid $silver;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 25px;
        padding: 0 8px;
        color: $color_stack_approx;
      }
    }
  }
}

.thread-view-action {
  text-align: right;
  margin-bottom: 5px;
  a {
    font-size: 11px;
    color: $color_green_house_approx;
    margin-right: 10px;
  }
}

.list-by-sequence {
  display: none;
}

.list-by-favorite {
  display: none;
}

.list-by-store {
  display: none;
}

.list-by-read {
  display: none;
}

.list-by-like {
  display: none;
}

.list-by-comments {
  display: none;
}

.list-by-myComments {
  display: none;
}

.sample-post-1 {
  display: none;
}

.sample-post-2 {
  display: none;
}

.panel-group {
  margin: 15px 0;
  .panel {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0 !important;
    border: 1px solid $color_celeste_approx;
    + .panel {
      margin-top: 3px;
    }
  }
  .panel-heading {
    border-bottom: 1px solid $color_bon_jour_approx;
    padding: 0;
  }
}

.panel-title {
  position: relative;
  font-size: 14px;
  > a {
    display: block;
    padding: 8px 10px;
    text-decoration: none;
    color: $color_mountain_mist_approx;
    &:hover {
      color: $color_tuatara_approx;
    }
    &.changeThis {
      display: inline-block;
      position: absolute;
      right: 0;
      cursor: pointer;
      font-size: 20px;
      top: -2px;
      &:hover {
        color: $color_shakespeare_approx;
      }
    }
  }
}

.mySettings {
  min-height: 350px;
  .form-control {
    width: 60%;
    display: inline-block;
    padding: 5px 10px !important;
    border: 1px solid $color_alto_approx;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
    &:focus {
      border: 1px solid $color_french_gray_approx;
    }
  }
  .ok-mobile-code {
    margin-top: 40px;
    display: none;
    color: $color_japanese_laurel_approx;
  }
  .check-mobile-code {
    margin-top: 40px;
    display: none;
  }
  .error-occurred {
    .form-control {
      border: 1px solid $color_clam_shell_approx;
      + .error {
        display: inline-block;
        margin-left: 5px;
      }
    }
    .error {
      color: $color_sangria_approx;
      font-size: 12px;
      margin-top: 2px;
      display: block;
    }
  }
  .error {
    display: none;
  }
  label {
    font-weight: normal;
  }
  .short-note {
    font-size: 12px;
    color: $color_arapawa_approx;
    margin-bottom: 5px !important;
  }
  input[type=submit] {
    @extend %extend_21;
    @extend %extend_22;
    &:hover {
      @extend %extend_23;
    }
  }
  button {
    @extend %extend_21;
    &:hover {
      border: 1px solid $color_nobel_approx;
      background-color: $color_mercury_approx;
      color: $color_suva_gray_approx;
    }
  }
  button[type=submit] {
    @extend %extend_22;
    &:hover {
      @extend %extend_23;
    }
  }
  .form-inline {
    > div {
      padding-left: 0 !important;
    }
    input[type=submit] {
      height: 33px !important;
      margin-left: 0 !important;
    }
    .form-group * {
      width: 100%;
    }
  }
  form .form-group:last-child {
    margin: 20px 10%;
    border-top: 1px dashed $color_pumice_approx;
    padding-top: 10px;
  }
  #country_code option {
    min-height: 100px !important;
  }
}

.privacy {
  position: absolute;
  top: 0;
  right: 15px;
  .dropdown-menu {
    top: 88%;
    left: -40px;
    min-width: 80px !important;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0 0 4px 4px;
    > li > a {
      padding: 3px 10px 3px 30px;
      font-size: 13px;
      color: $color_storm_dust_approx;
      > i {
        position: absolute;
        left: 10px;
        margin-top: 2px;
        &.icon-lock {
          left: 13px;
        }
      }
    }
  }
  button {
    padding: 3px 6px !important;
    border: 1px solid $color_celeste_approx !important;
    &:hover {
      border: 1px solid $color_nobel_approx !important;
      i {
        color: $color_storm_dust_approx;
      }
    }
    i {
      font-size: 16px;
      color: $gray;
    }
  }
}

.loading-parent {
  position: relative;
}

.loading-back {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $url_4 repeat;
  i {
    color: $color_bon_jour_approx;
    position: absolute;
    font-size: 40px;
    &:empty {
      top: 45%;
      left: 47%;
      //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
      transform: translate(-50%, -50%);
    }
  }
}

.image_process_area {
  .img_view_area {
    position: relative;
    .upload-area {
      position: absolute;
      top: 100px;
      left: 40%;
      text-align: center;
    }
    img {
      position: absolute;
      top: 10%;
      left: 10%;
      padding: 3px;
      max-width: 100%;
      max-height: 100%;
      &:empty {
        top: 50%;
        left: 50%;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translate(-50%, -50%);
      }
    }
  }
  .upload-area {
    i {
      font-size: 200px;
      color: $color_celeste_approx;
      clear: both;
      display: block;
    }
    button {
      @extend %extend_24;
      &:hover {
        border: 1px solid $color_silver_chalice_approx;
        color: $color_tapa_approx;
      }
    }
  }
  .img-box {
    background-color: $concrete;
    border: 1px solid $color_quill_gray_approx;
    display: inline-block;
    margin-right: 9px;
    margin-bottom: 12px;
    .img-here {
      position: relative;
      width: 240px;
      height: 160px;
      overflow: hidden;
    }
  }
}

.optional-upload-area {
  position: absolute;
  left: 50%;
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: translate(-50%, 0);
  display: inline-block;
  border: none;
  button {
    @extend %extend_24;
    @extend %extend_25;
    &:focus {
      @extend %extend_25;
    }
    i {
      position: relative;
      margin-right: 10px;
      font-size: 20px;
      top: 1px;
    }
    &:hover {
      color: $white;
      border-color: $color_rangoon_green_approx;
    }
  }
}

.hidden-file-field {
  display: none !important;
}

.section_privacy {
  text-align: right;
  border-bottom: 1px dashed $color_pumice_approx;
  margin-bottom: 15px;
  padding-bottom: 5px;
  color: $color_shady_lady_approx;
  input {
    cursor: pointer;
    &:checked + label {
      color: $color_heavy_metal_approx;
    }
  }
  label {
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}

.display-hide {
  display: none !important;
}

.display-show {
  display: inline-block;
}

.testClass {
  position: absolute !important;
  top: 217px !important;
  right: 100px !important;
  z-index: 1 !important;
  display: block !important;
}

.one-third {
  width: 19.5% !important;
}

.two-third {
  width: 29.5% !important;
}

.chat-history {
  .chat-header {
    .drop-btn {
      padding: 1px 7px;
    }
    > .current-chat-type {
      text-align: center;
      left: 50px;
      font-size: 14px;
      line-height: 45px;
    }
  }
  .chat-view-area {
    padding: 0 !important;
    height: 370px;
    overflow: auto;
  }
  .writing-area {
    position: absolute;
    bottom: 0;
    border-top-width: 1px;
    background-color: $gallery;
    padding: 10px;
    .status-area {
      border-width: 1px;
    }
  }
}

.chat-list-area {
  .chat-search-area {
    height: 45px;
    border-bottom-width: 1px;
    position: relative;
  }
  .chat-list {
    padding: 0 !important;
  }
}

.chat-view-area {
  ul {
    padding: 0;
    li {
      position: relative;
      list-style: none;
      &.published-date {
        text-align: center;
        border-bottom-width: 1px;
        height: 1px;
        span {
          position: relative;
          padding: 2px 7px;
          top: -10px;
          background-color: $white;
          color: $color_silver_chalice_approx;
        }
      }
      .sender-img {
        display: inline-block;
        position: absolute;
        width: 40px;
        height: 40px;
        top: 10px;
        left: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    &:first-child {
      padding-top: 15px;
    }
    &:last-child li.published-date {
      border-bottom-color: $color_cornflower_blue_approx;
      span {
        color: $color_cornflower_blue_approx;
      }
    }
  }
  .chat-info {
    position: relative;
    padding: 10px 0 10px 60px;
  }
}

.chat-short-info {
  .single-line {
    margin-right: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .user-name {
    @extend %extend_26;
  }
  .seinding-time {
    @extend %extend_27;
  }
  .chat-text {
    font-size: 12px;
    color: $color_venus_approx;
  }
}

.chat-info {
  .user-name {
    @extend %extend_26;
    margin-bottom: 7px;
  }
  .seinding-time {
    @extend %extend_27;
    .date {
      margin-right: 15px;
      color: $color_jewel_approx;
    }
  }
  .chat-text {
    font-size: 13px;
    padding-right: 30px;
    color: $color_boulder_approx;
    img {
      max-width: 100%;
      max-height: 250px;
    }
  }
}

.font-sizer {
  position: absolute;
  top: -15px;
  right: -15px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0 0 0 5px;
  border-bottom: 1px solid $color_wafer_approx;
  border-left: 1px solid $color_wafer_approx;
  span {
    display: block;
    float: left;
    cursor: pointer;
    background-color: $color_mystic_approx;
    height: 25px;
    width: 25px;
    color: $color_tapa_approx;
    font-size: 12px;
    text-align: center;
    &:first-child {
      font-size: 18px;
      border-right: 1px solid $color_wafer_approx;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 0 0 0 5px;
    }
    &:hover {
      background-color: $white;
      color: $black;
    }
    &:last-child {
      padding-top: 4px;
    }
  }
}

.usp-cover {
  padding: 0;
  text-align: center;
  img {
    z-index: 2 !important;
  }
}

.usp-title-info {
  float: left;
  width: 100%;
  border-bottom: 1px solid $color_cape_cod_approx;
}

.usp-name {
  float: left;
  text-align: left;
  font-size: 16px;
  padding: 5px;
}

.usp-from {
  float: right;
  text-align: right;
  font-size: 13px;
  padding: 7px;
}

.usp-short-info {
  border-bottom: 1px solid $color_gray_nurse_approx;
  float: left;
  z-index: 1 !important;
  padding: 0 0 2px;
  ul {
    padding: 5px 0;
    margin: 0;
    width: 100%;
  }
  li {
    list-style: none;
    display: table-cell;
    width: 1%;
    a {
      color: $color_gunsmoke_approx;
      text-align: center;
      text-decoration: none;
      span {
        display: block;
        &:first-child {
          font-size: 18px;
          color: $color_shark_approx;
        }
      }
    }
  }
}

.usp-status {
  float: left;
  border-top: 1px solid $color_gray_nurse_approx;
  padding: 10px 0;
  text-align: justify;
  color: $color_stack_approx;
  textarea {
    min-height: 300px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
  }
  .action-box {
    margin: 15px 0 20px;
  }
}

#usp-form-holder {
  display: none;
}

.usp-post-list {
  .tile-container {
    padding: 10px 15px !important;
    &:not(:first-child) {
      border-top: 1px solid $color_alto_approx;
    }
  }
  .content-header {
    border-bottom-width: 0;
    padding-bottom: 0 !important;
  }
}

.change-usp-status {
  float: right;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
  padding: 3px 10px;
  border: 1px solid $color_silver_approx;
  color: $color_storm_dust_approx;
  &:hover {
    background-color: $color_silver_approx;
  }
}

.ifry {
  .panel-heading {
    @extend %extend_28;
  }
  .panel-body {
    @extend %extend_28;
    padding: 5px 15px;
  }
  .panel-footer {
    @extend %extend_28;
    text-align: right;
    .btn {
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 0;
      background-color: $color_curious_blue_approx;
      color: $white;
      padding: 5px 20px;
      &:hover {
        background-color: $color_bahama_blue_approx;
        color: $color_cornflower_approx;
        //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
        transition: all .5s ease-out;
      }
    }
  }
  label {
    font-weight: normal;
    font-size: 12px;
  }
  input {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
    background-color: $color_jelly_bean_approx;
    border-color: $color_blumine_approx;
    color: $color_mischka_approx;
    outline: none;
    &:-webkit-autofill {
    }
  }
  .form-group:last-child {
    margin-bottom: 0;
  }
  .form-control:focus {
    border: 2px $color_blumine_approx;
  }
}

.ifry-closer {
  position: absolute;
  padding: 0;
  margin: 0;
  top: 6px;
  right: 12px;
  font-size: 20px;
  color: $color_bahama_blue_approx;
  &:hover {
    text-decoration: none;
    color: $color_blue_whale_approx;
  }
}

.notify-settings {
  .form-group {
    margin-bottom: 4px;
  }
  .headLine {
    margin-bottom: 12px;
    label {
      font-weight: bold;
      color: $color_tapa_approx;
    }
    .radioBox {
      margin-top: 6px;
    }
  }
  .radioBox {
    float: right;
    margin-top: -4px;
    label {
      padding-left: 10px;
    }
  }
  .checkbox-inline {
    min-width: 75px !important;
    text-align: center;
  }
}

.complain-for {
  legend {
    font-size: 14px;
    color: $color_pale_sky_approx;
    border-bottom: 1px dashed $color_pink_swan_approx;
    padding: 5px 0;
    margin-bottom: 10px;
    a {
      cursor: pointer;
      color: $color_delta_approx;
      font-size: 12px;
      padding: 0 4px;
      border: 1px solid $color_silver_approx;
      float: right;
      margin-right: 0;
      text-decoration: none;
      &:hover {
        background-color: $color_silver_approx;
        color: $white;
      }
    }
  }
  .radioBox {
    font-size: 0;
    i {
      color: $color_pale_sky_approx;
    }
    label {
      cursor: pointer;
    }
    input:checked + i:before {
      color: $color_pale_sky_approx;
    }
  }
  .radio-inline {
    margin-left: 0;
    padding-left: 25px;
    width: 35%;
    font-size: 13px;
    color: $color_shuttle_gray_approx;
    cursor: auto;
    &:nth-child(3n + 3) {
      width: auto !important;
    }
  }
}

.photo-frame {
  @extend %extend_30;
  .panel-body {
    padding: 0;
  }
  .tiar {
    @extend %extend_31;
  }
  .cage {
    @extend %extend_32;
  }
}

.friends-album {
  @extend %extend_30;
  .panel-body {
    padding: 0;
  }
  .tiar {
    @extend %extend_31;
  }
  .cage {
    @extend %extend_32;
    a {
      text-decoration: none;
      &:hover span {
        background-color: $color_cape_cod_approx;
      }
    }
    span {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 4px 2px 2px 4px;
      z-index: 10 !important;
      font-size: 11px;
      color: $white;
    }
  }
}

.order-place {
  padding: 30px 15px 15px;
  overflow-x: auto;
  margin-bottom: 15px;
  .table {
    width: 885px;
    thead > tr {
      > th {
        border: none;
      }
      > td {
        border: none;
      }
    }
    tbody > tr {
      > th {
        border: none;
      }
      > td {
        border: none;
      }
    }
    tfoot > tr {
      > th {
        border: none;
      }
      > td {
        border: none;
      }
    }
  }
  thead tr th {
    background-color: $color_river_bed_approx;
    color: $white;
    font-weight: normal;
    padding: 15px;
  }
  tbody tr {
    border-left: 1px solid $color_river_bed_approx;
    border-right: 1px solid $color_river_bed_approx;
    &:nth-child(odd) {
      background-color: $white;
    }
    &:nth-child(even) {
      background-color: $color_alto_approx;
    }
    &:last-child {
      border-bottom: 1px solid $color_river_bed_approx;
    }
    &:hover {
      background-color: $color_pale_slate_approx;
    }
    td {
      padding: 0;
      a {
        padding: 10px 15px;
        display: block;
        text-decoration: none;
        color: $color_chicago_approx;
        &:hover {
          background-color: $color_gray_approx;
          color: $white;
        }
      }
    }
  }
}

.order-detail-place {
  padding: 0;
  .panel {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
    margin-bottom: 0;
  }
  .panel-body img {
    width: 100%;
  }
  tr th {
    font-size: 0;
  }
}

.pro-info {
  position: relative;
  padding: 8px 0 !important;
  .name {
    display: block;
    color: $color_lochmara_approx;
    padding: 1px 0;
  }
  .type {
    display: block;
    color: $color_japanese_laurel_approx;
    margin-bottom: 10px;
  }
  .tocken {
    color: $maroon;
  }
  address {
    margin-bottom: 0;
  }
}

.blk {
  display: block;
  width: 100%;
  padding: 2px 0;
  span {
    width: 120px;
    text-align: right;
    display: inline-block;
  }
  &.bdt1 {
    border-top: 1px solid $color_star_dust_approx;
    padding-top: 5px;
    margin-top: 5px;
  }
}

@media(max-width: 991px) and(min-width: 768px) {
  body.directors-cut .shape-shifter .content-area .content-box {
    padding: 0 12px 12px !important;
  }
  .chat-box-wraper .chat-menu button {
    padding: 6px 8px;
    span {
      padding-left: 10px;
    }
  }
}

@media(max-width: 767px) {
  .social-nav.mobile-only {
    .dropdown-menu {
      margin-top: 9px;
      li {
        padding: 0;
        display: block;
        a {
          width: 100%;
          padding: 6px 15px;
        }
      }
    }
    ul li {
      padding: 0 15px 0 0;
      a.active:after {
        border-color: $white transparent;
      }
    }
    .dropdown .icon-angle-down {
      display: inline-block !important;
      border: 1px solid $color_pink_swan_approx;
      padding: 1px 5px;
    }
  }
  .myPic .pic-action .action-subset {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .chat-box-wraper .chat-menu {
    button {
      padding: 6px 8px;
      span {
        display: none;
      }
    }
    .btn-group + .btn-group {
      margin-top: 15px;
    }
  }
}

.post-status {
  margin-top: 35px;
  font-size: 16px;
  > div {
    margin-bottom: 15px;
    a {
      text-decoration: none;
      padding: 4px 5px;
      background-color: $color_mischka_approx;
      color: $color_fuscous_gray_approx;
      font-size: 14px;
      margin-right: 3px;
      line-height: 30px;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 5px;
      &:hover {
        background-color: $color_chicago_approx;
        color: $white;
      }
    }
  }
}

.row.wrap {
  background-color: $color_mystic_approx;
}

.left-column .blog-post-area {
  display: none;
  &:before {
    left: 60px;
  }
  &:after {
    left: 61px;
  }
}

.complain-here .action-box {
  float: none;
}

.post-title-here select {
  @extend %extend_9;
}

.radioBox [class*="checkbox"] {
  margin-top: 0;
  margin-bottom: 0;
  input:checked + i:before {
    content: "\f046";
  }
}

.title img {
  max-width: 100% !important;
}

.comments-here textarea:focus {
  outline: 1px solid $color_zanah_approx;
  border-color: $color_la_palma_approx;
}

.pic-set i:before {
  content: "\f100";
}

.pic-ReSet i:before {
  content: "\f101";
}

.selectAll i:before {
  content: "\f096";
}

.UnSelectAll i:before {
  content: "\f14a";
}

.friends-action .count {
  border: 1px solid $color_celeste_approx;
  font-size: 11px;
  padding: 2px 7px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 15px;
  color: $color_stack_approx !important;
  display: inline-block;
  background-color: $color_gallery_approx;
  right: 8px;
}

.friends-info .mutual-friend {
  display: block;
  font-size: 13px !important;
  color: $color_ironside_gray_approx !important;
  margin-top: 10px;
  img {
    width: 22px;
    margin-top: -3px;
    margin-left: 3px;
  }
  .count {
    font-size: 13px !important;
    color: $color_masala_approx !important;
  }
}

.publish-info small {
  font-size: 11px;
}

.type-list .publish-info .author-icon img {
  width: 20px;
  margin-top: 0;
}

.clr-area .post-hit-count > a {
  font-size: 11px;
  margin-left: 15px;
  color: $color_shady_lady_approx;
  position: relative;
  top: -1px;
  &.highlight {
    color: $color_deep_sea_green_approx;
  }
  &:hover {
    color: $color_dove_gray_approx;
  }
  i {
    font-size: 13px;
  }
}

.form-group > .control-label {
  padding-right: 0;
  color: $color_gunsmoke_approx;
}

.img_view_area .img-set-box {
  position: relative;
  background-color: $white;
  border-top: 1px solid $color_quill_gray_approx;
  text-align: left;
  width: 100%;
  input {
    width: 70%;
    height: 35px;
    padding: 7px;
    border: none;
  }
  .btn-group {
    position: absolute;
    top: 0;
    right: 0;
  }
  button {
    width: 35px;
    height: 35px;
    border: none;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
    border-left: 1px solid $color_quill_gray_approx;
    color: $color_tapa_approx;
  }
}

.modal-body > * {
  max-width: 100%;
}
.chat-search-post{
  margin-bottom: 10px;
  .search-box {
    position: relative;
    width: 100%;
    margin: 0;
  }
  .search-form {
    line-height: 42px;
    height: 42px;
    border: 1px solid #999;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-color: #fff;
    overflow: hidden;
  }

  .search-text {
    font-size: 14px;
    color: #ddd;
    border-width: 0;
    background: transparent;
  }

  .search-box input[type="text"] {
    width: 90%;
    padding: 11px 0 12px 1em;
    color: #333;
    outline: none;
  }

  .search-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 42px;
    width: 80px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 42px;
    border-width: 0;
    background-color:#000000;
    -webkit-border-radius: 0px 5px 5px 0px;
    -moz-border-radius: 0px 5px 5px 0px;
    border-radius: 0px 5px 5px 0px;
    cursor: pointer;
  }
}

.chat-search-area .chat-search {
  border-width: 1px;
  width: 90%;
  height: 30px;
  background-color: $white;
  position: absolute;
  top: 50%;
  left: 50%;
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: translate(-50%, -50%);
  input {
    height: 28px;
    width: 89%;
    padding: 0 10px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
    border-right-width: 1px;
  }
  button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px 10px 4px 11px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
    color: $color_stack_approx;
    &:focus {
      outline: none;
    }
  }
}

.chat-list ul {
  padding: 0;
  li {
    height: 70px;
    border-bottom-width: 1px;
    list-style: none;
    position: relative;
    cursor: pointer;
    &.active {
      background-color: $color_botticelli_approx;
    }
    &:hover {
      background-color: $color_botticelli_approx;
    }
    .user-img {
      display: inline-block;
      position: relative;
      width: 50px;
      height: 50px;
      margin-top: 10px;
      margin-left: 10px;
      img {
        width: 100%;
        height: 100%;
      }
      .unread-count {
        position: absolute;
        bottom: 0;
        right: 0;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translate(35%, 35%);
        background-color: $color_havelock_blue_approx;
        padding: 2px 6px;
        color: $white;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 15px;
        font-size: 11px;
        border: 2px $concrete;
      }
    }
    .chat-short-info {
      position: absolute;
      top: 0;
      left: 70px;
      right: 0;
      padding: 10px 0;
      bottom: 0;
    }
  }
}

li:not(.me) .chat-info .user-name {
  color: $color_bay_of_many_approx;
}

#jump-menu .list-group-item.active:after {
  border-color: transparent $color_mystic_approx;
}

.complain-wrap .complain-for {
  @extend %extend_29;
}

.ans .complain-for label {
  font-size: 12px;
}

.pro-quantity span {
  display: block;
  text-align: center;
  background-color: $color_black_haze_approx;
  color: $color_san_juan_approx;
  border: 1px solid $color_heather_approx;
  padding: 6px;
}

.panel-custom-title{
  .panel-heading {
    background: #fafafa;
    margin: 2px;
  }
}

.image-gallery-album a {
  width: 25%;
  display: inline-block;
  float: left;
  padding: 1px 4px;
}

.content-user-images{
  input[type=file] {
    cursor: pointer;
    width: 180px;
    height: 34px;
    overflow: hidden;
    border: none;
    box-shadow: none;
    margin: 0 auto;
    &:hover, &:focus {
      background: none;
      color: black;
      outline: none;
    }
  }
  
  input[type=file]:before {
    width: 158px;
    height: 32px;
    font-size: 16px;
    line-height: 32px;
    content: 'Select your file';
    display: inline-block;
    //background: white;
    border: 1px solid #000;
    padding: 0 10px;
    text-align: center;
    font-family: Helvetica, Arial, sans-serif;
  }
  
  input[type=file]::-webkit-file-upload-button {
    visibility: hidden;
  }
}