

.writing-area {
  .faq-category-row .answer {
    .btn-group {
      position: relative;
      display: table;
      vertical-align: middle;
      /* margin: 0 auto; */
    }
  }

  .nav-tabs > li > a:hover {
    border: 1px solid transparent;
  }
  #myModalBlogPost > .modal-dialog {
    width: 90%;
    margin: 30px auto;
    .modal-header, .modal-footer {
      padding: 15px 15px !important;
    }
  }
  .panel-heading {
    .nav-tabs {
      border: none;
    }
    li.active {
      a {
        color: #0081d5;
        font-weight: bold;
        i {
          color: #0081d5;
        }
      }
    }
  }
  .user-post {
    background: #ffffff;
    border: none;
  }
  .user-post .post-type-img {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .user-post .panel {
    border: none;
    box-shadow: none;
    border-radius: 5px;
  }

  .user-post .panel-body {
    padding: 0;
  }

  .user-post a {
    color: #2f373c;
  }

  .user-post a:hover {
    opacity: .8;
  }

  .user-post .media-body > a {
    text-transform: uppercase;
  }

  .user-post .status-item {
    padding: 10px;
  }

  .user-post .status-event, .user-post .content-status, .user-post .poll-option {
    position: relative;
    padding: 0 20px 20px;
  }

  .user-post .status-post img, .user-post .status-event img, .user-post .content-status img, .user-post .poll-option img {
    width: 100%;
  }

  .user-post .panel-heading {
    border: none;
    background: transparent;
  }

  .user-post .panel-heading > img {
    width: 40px;
    margin-right: 5px;
  }

  .user-post .panel-heading.engage a > img {
    border: 2px solid grey;
    border-radius: 50%;
    box-sizing: border-box;
    margin-right: 5px;
    width: 40px;
  }

  .user-post li a {
    color: #555d60;
  }

  .user-post li a i {
    display: block;
    text-align: center;
    color: #555d60;
  }

  .user-post .profile-featured-img img {
    width: 40px;
    border-radius: 50%;
  }

  .user-post .post-area {
    padding-bottom: 0;
    padding-top: 0;
  }

  .user-post .post-area textarea {
    background: #f6f5f6;
  }

  .user-post .nav > li > a {
    position: relative;
    display: block;
    padding: 0;
    cursor: pointer;
  }

  .user-post .nav > li > a:hover {
    background: transparent;
    text-decoration: none;
  }

  .user-post .nav > li > a:visited {
    background: transparent;
    text-decoration: none;
  }

  .user-post .nav > li > a:focus {
    background: transparent;
    text-decoration: none;
  }

  .user-post .nav > li > a.active {
    background: #e2e0e0;
  }

  .user-post .status-nav {
    margin-top: 8px;
  }

  .user-post .status-nav li {
    margin-left: 15px;
    float: left;
    list-style-type: none;
  }

  .user-post .status-event a, .user-post .content-status a {
    color: #555d60;
  }

  .user-post .status-event a i, .user-post .content-status a i {
    margin-right: 3px;
  }

  .user-post .status-event a.active, .user-post .content-status a.active {
    color: #f5463c !important;
  }

  .user-post .status-event a:not(:last-child), .user-post .content-status a:not(:last-child) {
    color: #555d60;
    margin-right: 15px;
  }

  .user-post .status-item {
    border-top: 1px solid #cccccc;
    padding-top: 12px;
  }
  .user-post .modal-footer {
    border-top: none;
  }
  .user-post .status-item a {
    color: #555d60;
    margin-right: 20px;
  }
  .blog-post {
    background: white;
  }
  .status-post {
    section {
      float: right;
      width: 100%;
    }
    section div {
      margin: 5px auto 0;
      background-color: #fff;
      overflow: hidden;
      padding: 0 20px 20px;
      border-radius: 3px;
      z-index: 3;
      position: relative;
      display: flex;
    }
    section div img {
      float: left;
      width: 60px;
      height: 60px;
    }
    section div textarea {
      width: 100%;
      height: 150px;
      border: none;
      padding: 5px 10px;
      font-family: arial;
      resize: none;
      outline: none;
      border-bottom: 2px solid #0077CC;
    }
    textarea::-webkit-scrollbar {
      width: 0px;
      background-color: transparent;
    }
    textarea::-webkit-scrollbar-thumb {
      background-color: #CCC;
      border-radius: 2px
    }
    .overlay {
      background-color: rgba(0, 0, 0, .5);
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 2;
      display: none;
    }
    .post {
      background-color: #fff;
      width: 500px;
      margin: 10px auto;
      padding: 10px;
      color: #333;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, .3);
      border-radius: 3px;
    }
  }
  .status-item .submit-post-button {
    border: none;
    background-color: #0077CC;
    color: #fff;
    font-weight: bold;
    padding: 8px 30px;
    border-radius: 3px;
    font-size: 16px;
  }
  .uploaded-img {
    img {
      margin-right: 10px;
      max-height: 80px !important;
      object-fit: cover !important;
      flex: 1;
      width: calc(100% * (1 / 5) - 10px - 1px) !important;
    }
  }
}

.intro-account .fa-edit {
  display: none;
}

.editable-click.editable-disabled, a.editable-click.editable-disabled, a.editable-click.editable-disabled:hover {
  color: #23527c;
}
.report-form{
  background: whitesmoke;
  padding: 10px 11px;
  margin-bottom: 10px !important;
  .report-radio-inline {float:left; width:33.33333%; margin-bottom:1em;}
  .report-radio-inline:nth-of-type(3n+1) {padding-right:1%;}
  .report-radio-inline:nth-of-type(3n+2) {padding:0 .5%;}
  .report-radio-inline:nth-of-type(3n+3) {padding-left:1%;}
  .report-radio-inline img {width:100%; height:auto; margin:0;}
  .report-radio-inline:nth-of-type(3n+4) {clear:left;}
}

