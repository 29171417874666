.fRequest {
	border-radius: 0;
	border: none;
	margin-bottom: 5px;
	padding: 10px;
}
.friend-list-page{
	.nav>li>a {
		position: relative;
		display: block;
		padding: 10px 8px;
	}
	.nav{
		.active{
			background: #0a7dd5;
			a{
				color: white;
			}
		}
	}
}
.fRequest .panel-heading.social-header {
	padding: 10px 0;
	margin-top: -10px;
	margin-bottom: 0;
	border: none;
	background-color: transparent;
}
.fRequest .panel-body {
	padding: 32px 0 0;
}
.fRequest .individual {
	position: relative;
	padding: 0 75px 0 65px;
	margin-bottom: 7px;
}
.fRequest .individual:first-child {
	margin-top: 43px \9;
}
@-moz-document url-prefix() { 
	.fRequest .individual:first-child {
		margin-top: 43px;
	}
}

.fRequest .fAcceptance {
	position: absolute;
	top: 0;
	right: 0;
	width: 70px;
	height: 60px;
	padding-top: 15px;
	text-align: right;
}
.fAcceptance .btn {
	padding: 3px 8px !important;
	font-size: 12px !important;
}
.fAcceptance .btn:first-child {
	background: #F4F4F4 url('../img/kindajean.png') repeat;
	color: #969494;
}
.fAcceptance .btn:first-child:hover {
	color: #555454;
}
.fAcceptance button:last-child {
	background: #F4F4F4 url('../img/city_oliva.png') repeat;
	color: #B0AFAF;
	border: 1px solid #575555;
}
.fAcceptance button:last-child:hover {
	color: #EFEEEE;
}
.fRequest .fpic {
	position: absolute;
	top: 0;
	left: 0;
	width: 60px;
	height: 60px;
	overflow: hidden;
}

.fRequest .fpic img {
	width: 100%;
	height: 100%;
}
.fRequest .fpic img:empty {
	position: relative;
	top: 50%;
	left: 50%;
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}
.fRequest .newFriend {
	display: block;
	font-size: 13px;
	margin-bottom: 3px;
}
.fRequest .Education {
	display: block;
	font-size: 11px;
	color: #919191;
	margin-bottom: 3px;
}
.fRequest .newFriend,
.fRequest .Education a,
.fRequest .request-by {
	color: #535353;
}
.fRequest .request-by {
	font-size: 12px;
}
.fRequest .request-by img {
	display: inline-block;
	position: relative;
	width: 20px;
	height: 20px;
}