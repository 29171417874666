.feature-entertainment {
  a:link {
    color: #333333;
    text-decoration: none;
    outline: none;
    transition: all .5s ease-in-out;
  }
  /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
  .main-content {
    padding: 0 22px;
  }
  .site-header,
  .site-header img {
    width: 100%;
  }

  /*--------- Showcase ---------*/
  .showcase {
    margin: 2px 0 10px;
    padding: 0;
  }
  .showcase,
  .showcase header,
  .showcase footer {
    position: relative;
    width: 100%;
  }
  .showcase .branch {
    position: relative;
  }

  .showcase .branch {
    vertical-align: bottom;
  }
  .showcase.list .branch {
    font-size: 0;
    margin-bottom: 2px;
    height: 400px;
  }
  .showcase.list .branch > * {
    display: inline-block;
    vertical-align: top;
  }
  .showcase figure,
  .showcase .figure {
    background-color: #E5EBF1;
    width: 100%;
    height: 165px;
  }
  .showcase.list figure,
  .showcase.list .figure {
    width: 40%;
    /* height: 140px; */
  }

  .showcase.list.alt figure,
  .showcase.list.alt .figure,
  .showcase.list .alt figure,
  .showcase.list .alt .figure {
    float: right;
  }
  .showcase article,
  .showcase .article {
    width: 100%;
    padding: 0 15px 10px;
    font-size: 16px;
    line-height: 26px;
  }

  .showcase.list.alt article,
  .showcase.list.alt .article,
  .showcase.list .alt article,
  .showcase.list .alt .article {
    padding: 0 10px 0 10px;
  }
  .showcase .branch a {
    text-decoration: none;
  }
  .showcase .branch .title {
    /* display: block; */
    width: 100%;
    font-size: 35px;
    /* margin-top: 5px; */
    /* margin-bottom: 7px; */
    font-family: 'Noto Sans', sans-serif;
    color: black !important;
    text-align: center;
    /* display: block; */
    margin-bottom: 20px;
  }
  .showcase .branch a:hover .title {
    color: #fff;
  }
  .showcase .branch > .title,
  .showcase .branch > a .title {
    margin-top: 10px;
  }

  .showcase .branch .description {
    width: 100%;
    /* height: 70px; */
    overflow: hidden;
    margin-bottom: 10px;
  }

  .showcase.list .branch .description {
    margin-bottom: 0;
  }
  .showcase .action {
    text-align: center;
  }
  .showcase.list.alt .action,
  .showcase.list .alt .action {
    text-align: left;
  }
  .showcase .branch .action .btn {
    color: #E2E187;
  }
  /*--------- /Showcase ---------*/
  .no-pading {
    padding: 0 !important;
  }

  /*-------------- .list-type -----------*/
  .list-type .branch {
    color: white;
    padding: 0;
  }
  .list-type figure,
  .list-type .figure {
    height: 380px;
  }
  .list-type article,
  .list-type .article {
    position: absolute !important;
    background: url("../img/elm-bg.png") repeat;
    bottom: 0;
    left: 0;
    padding-bottom: 3px;
    text-align: center;
    font-size: 13px;
  }
  .list-type .branch .description {
    height: 55px;
    margin-bottom: 3px;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
  }
  .list-type .branch:hover .description {
    height: 110px;
  }
  .enter10-type .branch {
    padding: 0;
  }
  .enter10-type .branch figure,
  .enter10-type .branch .figure {
    height: 100%;
    background: #1e213c;
    position: relative;
  }
  .enter10-type .showcase.list article {
    position: relative;
    height: 100%;
    padding: 50px;
    color: #FFFFFF;
    text-align: center;
    background-color: #1E213C;
  }
  .enter10-type .showcase.list article:before {
    content: "";
    position: absolute;
    border-style: solid;
    border-color: transparent #1E213C;
    top: 39%;
    margin-top: -1px;
    left: -20px;
    border-width: 30px 30px 30px 0;
  }
  .enter10-type .showcase .branch .title,
  .enter10-type .showcase .branch .title:hover {
    color: #4F962A;
    font-size: 28px;
  }
  .enter10-type .showcase .branch .description {
    font-size: 14px;
    /* height: 172px; */
    text-align: justify;
  }
  /*------------ /.enter10-type ------------*/
  /*------------ .thumb-type ------------*/
  .thumb-type {
    padding-left: 15px;
    padding-right: 5px;
  }
  .thumb-type.alt {
    padding-left: 0;
    padding-right: 15px;
  }
  .thumb-type .showcase .branch {
    padding: 0;
    margin-bottom: 10px !important;
    border: none;
    color: #1e213c;
    height: 72px;
  }
  .thumb-type .showcase .branch:hover {
    background-color: #15172B;
    color: white;
  }
  .thumb-type .showcase.list figure,
  .thumb-type .showcase.list .figure {
    width: 33%;
    height: 72px;
  }
  .thumb-type .showcase.list article,
  .thumb-type .showcase.list .article {
    width: 67%;
    height: 65px;
    padding: 10px;
    font-size: 13px;
  }
  .thumb-type .showcase.list .branch .description {
    height: auto;
    text-align: left;
    line-height: 20px;
    font-size: 14px;
    margin-top: 5px;
  }
  .thumb-type .showcase footer {
    border: none;
  }

  /*------------ /.thumb-type ------------*/
  /*------------ .details-type -------------*/
  .details-type .branch {
    background-color: white;
    margin-bottom: 0;
  }
  .details-type .branch img {
    max-width: 100%;
    margin-bottom: 10px;
  }
  .details-type .branch .title {
    font-size: 28px;
    color: #1e213c !important;
    padding: 3px 15px;
  }
  /*------------ /.details-type -------------*/

  .social-area {
    width: 100%;
    background-color: #333333;
    padding: 0 10px;
  }
  .social-area.white {
    background-color: #1E213C;
    margin-bottom: 15px;
    margin-top: 2px;
  }
  .like-area {
    padding: 10px 0;
    min-height: 52px;
    position: relative;
    text-align: center;
  }
  .like-area > div:nth-child(1) {
    left: 78px;
  }
  .like-area > div:nth-child(2) {
    left: 285px;
  }
  .like-area > div:nth-child(3) {
    right: 126px;
  }
  .like-area > div:nth-child(4) {
    right: 22px;
  }

  .font-sizer {
    top: 0;
    right: 0;
    border: 1px solid #343C88;
    border-radius: 0;
  }
  .font-sizer span {
    background-color: #545CA6;
    color: #FFFFFF;
    height: 27px;
    width: 30px;

  }
  .font-sizer span:hover {
    background-color: #202767 !important;
    color: #FFFFFF;
  }
  .font-sizer span:first-child {
    padding-top: 2px;
    border-radius: 0;
    border-right: 1px solid #2B3483;
  }
  .font-sizer span:last-child {
    padding-top: 6px;
    background-color: rgb(47, 54, 121);
  }

  /*===============================*/
  .block-type .showcase {
    margin-top: 0;
  }
  .block-type .showcase .branch > .title,
  .block-type .showcase .branch > a .title {
    margin-top: 0;
  }
  .uMayLike.showcase .branch {
    border: 1px solid #CFCDCD;
    padding: 8px;
  }
  .feature-category.topic {
    height: 60px;
    line-height: 60px;
    text-align: center;
    border: 1px solid transparent;
  }
  .feature-category.topic a {
    border: 1px solid transparent;
    webkit-transition: background-color .3s ease-in-out, color .3s ease-in-out, border-color .3s ease-in-out;
    -moz-transition: background-color .3s ease-in-out, color .3s ease-in-out, border-color .3s ease-in-out;
    -ms-transition: background-color .3s ease-in-out, color .3s ease-in-out, border-color .3s ease-in-out;
    -o-transition: background-color .3s ease-in-out, color .3s ease-in-out, border-color .3s ease-in-out;
    transition: background-color .3s ease-in-out, color .3s ease-in-out, border-color .3s ease-in-out;
  }
  .feature-category a:hover, .feature-category a.active {
    background-color: white;
    color: #f34235;
    border: 1px solid #e4e4e4;
    height: 60px;
    line-height: 60px;
  }

  .thumb-type .showcase .branch {
    border-color: #1E213C;
  }
  .pagination > li > a, .pagination > li > span {
    color: #1E213C;
    border: 1px solid #1E213C;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus,
  .pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
    background-color: #1E213C;
    border-color: #1E213C;
    color: #ffffff;
  }
  .post-title.text-center {
    padding: 25px 15px;
    font-size: 18px;
    font-weight: 300;
    color: white;
    background: #3f4e65;
    margin-top: -1px;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .post.index {
    position: relative;
    background-color: #fff;
    color: inherit;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  }

  .post-thumbnail img {
    width: 100%;
    /* height: 100%; */
  }
  img {
    vertical-align: middle;
  }
  .article-desc {
    background: white;
    padding: 1px 0;
    margin-bottom: 30px;
    color: black;
  }
  p.description {
    margin: 0 auto 20px;
    width: 80%;
    text-align: center;
  }

  .post-thumbnail {
    height: 200px;
    overflow: hidden;
  }
  .bind-all.facebook.grid-1 {
    background: white;
    /* margin: 0 auto; */
  }
  .fb-page.fb-page-width.fb_iframe_widget {
    margin: 0 auto;
    width: 330px;
    padding: 20px 0;
  }
  .header-panel {
    height: 400px;
    overflow: hidden;
  }
  .header-panel img {
    width: 100%;
  }
  .header-panel {
    height: 400px;
    overflow: hidden;
    margin-bottom: 50px;
    position: relative;
    margin-left: -15px;
  }
  .header-panel h1.title {
    position: absolute;
    top: 50%;
    left: 50px;
    color: white;
    transform: translateY(-50%);
    font-size: 60px;
    font-weight: bold;
    width: 50%;
  }

  .header-panel::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .35);
  }

  .btn.read-more-btn {
    background: #F34235;
    color: white;
    padding: 10px 20px;
    border-radius: 0;
    font-size: 16px;
  }

  .btn.read-more-btn:hover {
    background: white;
    color: #F34235;
    transition: all .4s;
  }
  .header-banner {
    background: rgba(38, 113, 65, 0.96);
    height: 400px;
  }
  .header-banner a.banner-btn {
    color: white;
    border: 1px solid;
    padding: 5px 11px;
    margin-bottom: 5px;
    display: inline-block;
  }
  .header-banner a.banner-btn:hover {
    background: white;
    color: #1E213C;
    transition: all .3s;
  }
  .header-banner .article .description-banner {
    color: white;
    text-align: left;
    font-size: 20px;
    margin-bottom: 40px;
    display: block;
  }
  .header-banner .article .description-banner:hover {
    color: gray;
    transition: all .3s;
  }
  .all-article {
    margin-top: 20px;
  }
  .all-article .article {
    width: 75%;
    margin: 0 auto;
  }
  .featured-image {
    margin-bottom: 30px;
  }
}