.feature-sports {
  .breadcrumbs {
    padding: 15px 0 200px;
    position: relative;
    height: 200px;
    .breadcrumbs-title {
      h2{
        font-size: 64px;
        font-weight: 700;
        color: #fff;
        margin-bottom: 10px;
        padding: 0;
      }
      p{
        color: #fff;
        font-size: 18px;
        line-height: 18*1.6px;
      }
    }
  }
  .blog-items {
    display: block;
    position: relative;
    margin-bottom: 30px;
    a {
      display: block;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        background-image: -webkit-gradient(linear, top right, bottom left, color-stop(0, #575970), color-stop(1, #24252F));
        background-image: -o-linear-gradient(top right, #575970, #24252F);
        background-image: -moz-linear-gradient(top right, #575970, #24252F);
        background-image: -webkit-linear-gradient(top right, #575970, #24252F);
        background-image: linear-gradient(to bottom left, #575970, #24252F);
        opacity: 0.8;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
      }
      > span {
        position: absolute;
        top: 30px;
        left: 30px;
        display: inline-block;
        font-size: 14px;
        font-weight: 700;
        color: #24252f;
        background: #fec722;
        line-height: 1;
        padding: 5px 10px;
      }
      > img {
        width: 100%;
        height: auto;
      }
    }
  }
  .blog-content-title {
    display: block;
    position: absolute;
    bottom: 0;
    padding: 30px;
    width: 100%;
    h3 {
      font-size: 21px;
      font-weight: 700;
      color: #fff;
      display: block;
      padding: 0;
      line-height: 38px;
      margin: 0 0 15px;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -o-transition: all 0.5s;
      transition: all 0.5s;
    }
    span {
      font-size: 14px;
      font-weight: 700;
      color: #fff;
    }
  }
  .title-section {
    display: block;
    border-bottom: 2px solid #fec722;
    position: relative;
    padding: 7px 0;
    margin-bottom: 30px;
    &::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 12px 12px;
      border-color: transparent transparent #fec722 transparent;
    }
    h2 {
      display: inline-block;
      margin: 0;
      padding: 0;
      font-size: 28px;
      font-weight: 300;
      color: #24252f;
    }
  }
  .arrow-style {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 0;
    background: #fec722;
    width: 40px;
    height: 56px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    &::before {
      content: "";
      position: absolute;
      left: -54px;
      bottom: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 56px 54px;
      border-color: transparent transparent #fec722 transparent;
    }
    button {
      color: #24252f;
      background: transparent;
      border: none;
      outline: none;
      i {
        font-size: 14px;
        font-weight: 700;
        padding: 21px 2px;
      }
    }
  }
  .video-items {
    padding: 0 15px;
    a {
      position: relative;
      display: block;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        box-shadow: inset 0 -160px 160px -35px #24252F;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
      }
      > img {
        width: 100%;
        height: auto;
      }
      > h3 {
        position: absolute;
        bottom: 0;
        display: block;
        color: #fff;
        margin: 0;
        padding: 0 0 15px;
        > i {
          font-size: 21px;
          width: 46px;
          height: 46px;
          border: 2px solid #fff;
          border-radius: 50%;
          padding: 12px 15px;
          margin: 5px 15px;
          display: block;
          float: left;
        }
      }
    }
  }
}