

.feature-tech{
  .header-banner-tech {
    background:url('../img/technology-bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
    &:before{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.35)
    }
    .row-banner {
      height: 400px;
      overflow: hidden;
    }
    .header-image-tech {
      width: 100%;
      height: auto;
    }
  }

  .component-view {
    .banner-text {
      color: white;
      h2 {
        margin-top: 2em;
        margin-bottom: 2rem;
        font-size: 38px;
        line-height: 44px;
        font-weight: 700;
        color: #fff;
      }
      p{
        width: 80%;
        margin-bottom: 20px;
        font-size:15px;
        line-height:15*1.6px;
      }
      a.btn {
        padding: 0 18px;
        font-size: 14px;
        line-height: 38px;
        border-color: #fbbc25;
        border-radius: 30px;
        -moz-border-radius: 30px;
        -webkit-border-radius: 30px;
        text-shadow: none;
        background: #FBBC25;
        color:black;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1px;
      }
    }
  }

  .ad-image-tech{
    border: 15px solid #fbbc25;
  }
  .ad-show{
    margin-top: 45px;
  }
  .post-padding {
    padding: 40px 60px;
  }
  .large-post-meta {
    display: block;
    font-size: 12px;
    width: 100%;
    position: relative;
    padding: 20px 0 0;
    a{
      color: #333 !important;
    }
    small {
      color: #dadada;
      font-size: 16px;
      margin: 0 5px;
    }
  }
  .avatar {
    margin-left: 0!important;
    img{
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .content .entry-title {
    letter-spacing: -0.03em;
    line-height: 38px;
    margin-bottom: 25px;
    font-size: 29px;
    font-weight: 700;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
  }
  .post-sharing {
    display: block;
    width: 100%;
    position: relative;
    margin: 20px 0 0;
  }
  .list-inline {
    padding-left: 0;
    margin-left: -5px;
    list-style: none;
  }
  .list-inline > li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
  }
  .social-small li .fa-twitter, .tw-button.btn-primary:hover, .tw-button.btn-primary:focus, .tw-button.btn-primary {
    background-color: #00B6F1 !important;
    border-color: #00B6F1 !important;
  }
  .fb-button.btn-primary {
    background-color: #3B5998 !important;
    border-color: #3B5998 !important;
  }
  h3.entry-title {
    a {
      color: #333333;
      text-decoration: none !important;
      outline: none;
      -moz-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      -webkit-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
    }
  }
  .post-media{
    margin: 40px -30px;
    img{
      width: 100%;
    }
  }
  .fb-button,.tw-button {
    color: white;
    i{
      margin-right: 5px;
    }
  }
}

