.checkout-page-sticky .box .box-footer {
  height: 110px;
  > div {
    margin-bottom: 10px;
  }
}

.row.ad-img img {
  width: 100% !important;

  margin-top: 20px;
  height: 60px;
}

.old-address.box {
  margin-top: -30px;
}

.cart-breadcrumb {
  .breadcrumb-arrow {
    height: 36px;
    padding: 0;
    line-height: 36px;
    list-style: none;
    margin: 0 auto;
    text-align: center;
  }
  .breadcrumb-arrow li:first-child a {
    border-radius: 4px 0 0 4px;
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px
  }
  .breadcrumb-arrow li, .breadcrumb-arrow li a, .breadcrumb-arrow li span {
    display: inline-block;
    vertical-align: top
  }
  .breadcrumb-arrow li:not(:first-child) {
    margin-left: -5px
  }
  .breadcrumb-arrow li + li:before {
    padding: 0;
    content: ""
  }
  .breadcrumb-arrow li span {
    padding: 0 10px
  }
  .breadcrumb-arrow li a, .breadcrumb-arrow li:not(:first-child) span {
    height: 36px;
    padding: 0 10px 0 25px;
    line-height: 36px
  }
  .breadcrumb-arrow li:first-child a {
    padding: 0 10px
  }
  .breadcrumb-arrow li a {
    position: relative;
    color: #fff;
    text-decoration: none;
    background-color: #3bafda;
    border: 1px solid #3bafda
  }
  .breadcrumb-arrow li:first-child a {
    padding-left: 10px
  }
  .breadcrumb-arrow li a:after, .breadcrumb-arrow li a:before {
    position: absolute;
    top: -1px;
    width: 0;
    height: 0;
    content: '';
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent
  }
  .breadcrumb-arrow li a:before {
    right: -10px;
    z-index: 3;
    border-left-color: #3bafda;
    border-left-style: solid;
    border-left-width: 11px
  }
  .breadcrumb-arrow li a:after {
    right: -11px;
    z-index: 2;
    border-left: 11px solid #2494be
  }
  .breadcrumb-arrow li a:focus, .breadcrumb-arrow li a:hover {
    background-color: #4fc1e9;
    border: 1px solid #4fc1e9
  }
  .breadcrumb-arrow li a:focus:before, .breadcrumb-arrow li a:hover:before {
    border-left-color: #4fc1e9
  }
  .breadcrumb-arrow li a:active {
    background-color: #2494be;
    border: 1px solid #2494be
  }
  .breadcrumb-arrow li a:active:after, .breadcrumb-arrow li a:active:before {
    border-left-color: #2494be
  }
  .breadcrumb-arrow li span {
    color: #434a54
  }
}

.tab-payment {
  .paymentWrap {
    padding: 50px;
  }

  .paymentWrap .paymentBtnGroup {
    max-width: 800px;
    margin: auto;
  }

  .paymentWrap .paymentBtnGroup .paymentMethod {
    padding: 40px;
    box-shadow: none;
    position: relative;
  }

  .paymentWrap .paymentBtnGroup .paymentMethod.active {
    outline: none !important;
  }

  .paymentWrap .paymentBtnGroup .paymentMethod.active .method {
    border-color: #4cd264;
    outline: none !important;
    box-shadow: 0px 3px 22px 0px #7b7b7b;
  }

  .paymentWrap .paymentBtnGroup .paymentMethod .method {
    position: absolute;
    right: 3px;
    top: 3px;
    bottom: 3px;
    left: 3px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border: 2px solid transparent;
    transition: all 0.5s;
  }

  .paymentWrap .paymentBtnGroup .paymentMethod .method.visa {
    background-image: url("../img/cod.jpg");
  }

  .paymentWrap .paymentBtnGroup .paymentMethod .method.master-card {
    background-image: url("../img/bkash.png");
  }

  .paymentWrap .paymentBtnGroup .paymentMethod .method.amex {
    background-image: url("../img/amex.jpg");
  }

  .paymentWrap .paymentBtnGroup .paymentMethod .method.ez-cash {
    background-image: url("../img/ez.png");
  }

  .paymentWrap .paymentBtnGroup .paymentMethod .method:hover {
    border-color: #4cd264;
    outline: none !important;
  }
}