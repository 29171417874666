.blog-post {
  padding-bottom: 20px;
  .idol-title {
    border-bottom: 1px solid #ececec;
    border-left: 3px solid #0392CE;
    margin-bottom: 20px;
    padding: 0 0 5px 15px;
    text-transform: capitalize;
    font-size: 25px;
    display: block;
    color:#C0392B;
  }
  article.post {
    padding-bottom: 30px;
  }
  .post-featured-image {
    overflow: hidden;
    position: relative;
    text-align: left;
    iframe {
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
    .entery-header {
      background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;
      bottom: 0;
      color: rgb(255, 255, 255);
      left: 0;
      margin: 0;
      overflow: hidden;
      padding: 9px 0 4px 12px;
      right: 0;
      text-overflow: ellipsis;
      transition: all 0.3s ease 0s;
      white-space: nowrap;
      position: absolute;
      z-index: 9;
      div:first-child {
        float: left;
      }
    }
  }
}

.post-featured-image .entry-meta [class*="fa-"]::before, .post-featured-image .entry-meta, .post-featured-image .entry-meta a {
  color: rgb(255, 255, 255);
}
.post-featured-image .entery-header div:first-child {
  float: left;
}
.entry-meta [class*="fa-"] {
  float: left;
  margin-bottom: 5px;
  margin-right: 10px;
}

.entry-meta > div {
  border-right: 1px solid rgb(95, 100, 104);
  padding-right: 8px;
  line-height: 1;
}
.post-featured-image .entery-header div:last-child {
  float: right;
}
.entry-meta > div:last-child {
  border-right: none;
}
.post-featured-image .entry-meta [class*="fa-"]::before, .post-featured-image .entry-meta, .post-featured-image .entry-meta a {
  color: rgb(255, 255, 255);
}

.post-featured-image div a {
  display: inline-block;
}
.post-featured-image .entery-header div:last-child {
  float: right;
  .entry-meta:last-child > div {
    border-right: none;
    padding-left: 5px;
    padding-right: 0;
  }
}
.post-featured-image .entery-header div:first-child {
  float: left;
}
.clearfix:after {
  content: " ";
  clear: both;
  display: block;
  font-size: 0;
  height: 0;
  visibility: hidden;
}
.entry-container {
  border-left: 1px solid #0392CE;
  padding: 0 0 0 20px;
  .entry-header {
    padding: 10px 0;
  }
}
.entry-container header .entry-meta {
  padding: 10px 0;
}
.entry-meta, .entry-meta a {
  color: #A7194B;
}
.clearfix {
  display: block;
}
.entry-header .entry-meta .cat-links {
  display: inline-block;
  margin-right: 7px;
}
.entry-meta .cat-links, .entry-meta .cat-links a {
  text-transform: uppercase;
  color: rgb(74, 89, 94);
  font-weight: bold;
}
.cat-links {
  position: relative;
}
.entry-header .entry-meta .cat-links a {
  background: #A7194B;
  color: rgb(255, 255, 255);
  display: inline-block;
  font-weight: 400;
  margin: 0 6px 0 0;
  padding: 3px 8px;
  transition: all 0.3s ease 0s;
}
.entry-meta .cat-links a:first-child::before {
  background: #A7194B;
  bottom: -3px;
  content: "";
  display: block;
  height: 6px;
  left: 5px;
  position: absolute;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transition: all 0.3s ease 0s;
  width: 6px;
}
.entry-header .entry-meta .cat-links {
  display: inline-block;
  margin-right: 7px;
}
.entry-meta .cat-links, .entry-meta .cat-links a {
  text-transform: uppercase;
  color: rgb(74, 89, 94);
  font-weight: bold;
}
.blog-post .entry-header .entry-title {
  padding: 15px 0 0;
  a {
    color: rgb(56, 56, 56);
    text-decoration: none;
  }
}
article {
  color: rgb(102, 102, 102);
  font-size: 14px;
  line-height: 22px;
}
.entry-container .read-more-button {
  margin-left: -20px;
}
.read-more-button, .page-content .search-form .search-submit {
  background: #0392CE;
  color: rgb(255, 255, 255);
  display: inline-block;
  font-size: 16px;
  padding: 9px 22px;
  text-align: center;
  text-transform: uppercase;
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  &:hover{
    color: #fff;
    background: #0392CE;
    opacity: .7;
  }
}
a.blog-post-image{
  max-height: 300px;
  display: block;
  overflow: hidden;
  img{
    width: 100%;
  }
}

