@font-face {
  font-family: 'Noto Sans';
  src: url('../fonts/NotoSerifBengali-Regular.ttf');
}

body{
  font-size:16px;
}
.subNav .navbar-nav li a {
  font-size: 16px;
}

.header .navbar-nav > li > a {
  font-size: 16px;
}

.social-nav ul li a, .sub-navi ul li a {
  font-size: 16px;
}