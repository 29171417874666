.notification-setting-page {
  .setting-title {
    margin: 0;
    font-size: 14px;
  }
  .switch {
    background-color: #bebebe;
    border-radius: 4px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 14px;
    height: 32px;
    margin-bottom: 12px;
    position: relative;
    width: 60px;
    -webkit-transition: background-color 0.2s ease-in-out;
    -moz-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
  }
  .switch.checked {
    background-color: #76d21d;
  }
  .switch input[type="checkbox"] {
    cursor: pointer;
    height: 10px;
    left: 12px;
    position: absolute;
    top: 8px;
    -webkit-transition: left 0.05s ease-in-out;
    -moz-transition: left 0.05s ease-in-out;
    -o-transition: left 0.05s ease-in-out;
    -ms-transition: left 0.05s ease-in-out;
    transition: left 0.05s ease-in-out;
    width: 10px;
  }
  .switch.checked input[type="checkbox"] {
    left: 38px;
  }
  .switch input:before {
    background: #fff;
    background: -moz-linear-gradient(top, #fff 0%, #f0f0f0 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #f0f0f0));
    background: -webkit-linear-gradient(top, #fff 0%, #f0f0f0 100%);
    background: -o-linear-gradient(top, #fff 0%, #f0f0f0 100%);
    background: -ms-linear-gradient(top, #fff 0%, #f0f0f0 100%);
    background: linear-gradient(to bottom, #fff 0%, #f0f0f0 100%);
    border: 1px solid #fff;
    border-radius: 2px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    content: '';
    height: 18px;
    position: absolute;
    top: -5px;
    left: -9px;
    width: 26px;
  }
  .switch input:after {
    background: #f0f0f0;
    background: -moz-linear-gradient(top, #f0f0f0 0%, #fff 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f0f0f0), color-stop(100%, #fff));
    background: -webkit-linear-gradient(top, #f0f0f0 0%, #fff 100%);
    background: -o-linear-gradient(top, #f0f0f0 0%, #fff 100%);
    background: -ms-linear-gradient(top, #f0f0f0 0%, #fff 100%);
    background: linear-gradient(to bottom, #f0f0f0 0%, #fff 100%);
    border-radius: 10px;
    content: '';
    height: 12px;
    margin: -1px 0 0 -1px;
    position: absolute;
    width: 12px;
  }
  .switch .fa-check,
  .switch .fa-times {
    line-height: 28px;
    text-shadow: 0 -2px 0 rgba(0, 0, 0, 0.2);
    margin: 0 9px;
  }
  .switch .fa-check {
    float: left;
  }
  .switch .fa-times {
    float: right;
    position: absolute;
  }
}

.notification-list {
  li.media:not(:last-child){
    border-bottom: 1px solid rgba(128, 128, 128, 0.3);
  }
  li.media {
    margin-bottom: -14px;
    cursor: pointer;
    overflow: initial;
    &:hover {
      background: rgba(0, 129, 213, 0.2);
    }
    span.dropdown-toggle {
      padding-right: 30px;
    }
  }
  .media-right {
    padding-right: 0;
  }
  li.media.unread {
    background: rgba(0, 129, 213, 0.06);
    &:hover {
      background: rgba(0, 129, 213, 0.2);
    }
  }
}