.blog-post-container {
  padding: 0;
  &:nth-child(2) {
    padding-left: 15px;
  }
}

.user-post-interact.status-item a {
  color: black;
}

.birthday-celebration {
  position: relative;
  canvas {
    width: 100%;
    height: 400px;
    background: rgb(37, 45, 61);
    -webkit-filter: blur(0.6px);
    -moz-filter: blur(0.6px);
    -o-filter: blur(0.6px);
    -ms-filter: blur(0.6px);
    filter: blur(0.6px);
  }
  h1 {
    background: #f39c12;
    padding: 20px;
    text-align: center;
    color: white;
  }
  #Profile {
    position: absolute;
    margin: auto;
    top: 85px;
    right: 0;
    left: 0;
    bottom: 0;
    width: 120px;
    height: 120px;
    border-radius: 120px;
    text-align: center;
    cursor: pointer;
  }
  #Profile img {
    border-radius: 120px;
    -webkit-filter: grayscale(0.7);
    -moz-filter: grayscale(0.7);
    -ms-filter: grayscale(0.7);
    filter: grayscale(0.7);
    opacity: 0.9;
    width: 120px;
  }
  #Profile:before {
    content: '';
    position: absolute;
    top: -6px;
    left: -6px;
    width: 130px;
    height: 130px;
    border-radius: 162px;
    border: 1px solid #BCBCBC;
  }
  #Profile:after {
    content: '';
    position: absolute;
    top: -11px;
    left: -11px;
    width: 140px;
    height: 140px;
    border-radius: 172px;
    border: 1px dashed #81BDA4;
  }
  #Profile span {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 120px;
    height: 120px;
    border-radius: 120px;
    line-height: 120px;
    font-family: 'Oswald', serif;
    font-size: 20px;
    visibility: hidden;
  }
  #Profile:hover img {
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px);
    opacity: 0.8;
  }
  #Profile:hover span {
    background: rgba(217, 217, 217, 0.52);
    visibility: visible;
    color: #494E52;
  }
}

.main-container .mid-column {
  padding-left: 15px !important;
  padding-right: 0;
}

.social-header {
  padding: 10px;
}

.intro-account {
  padding: 20px 10px;
  background: white;
  .intro-para {
    font-size: 14px;
    line-height: 14*1.6 px;
  }
  h4 .fa-edit {
    cursor: pointer;
  }
}

ul.about-myprofile {
  margin: 0;
  padding-left: 10px;
  span {
    font-weight: bold;
  }
  li {
    margin: 0 0 5px 0;
    list-style: none;
  }
}

.intro-account {
  margin-bottom: 10px;
}

.user-intro .title {
  background-color: white;
}

.banner .user-intro .title {
  background-color: white !important;
}

.ifry input:-webkit-autofill {
  -webkit-box-shadow: initial !important;
  -webkit-text-fill-color: initial !important;
}

.album {
  margin-top: 20px;
  .album-images {
    position: relative;
    margin-bottom: 10px;
    img {
      width: 100%;
      cursor: pointer;
    }
    .interaction {
      display: none;
      bottom: 5px;
      position: absolute;
      color: white;
      left: 5px;
    }
    &:hover {
      .interaction {
        display: block;
        transition: all .5s;
        text-shadow: 0 0 3px rgba(0, 0, 0, .75);
      }
    }
    .like-text {
      cursor: pointer;
    }
    .comment-text {
      cursor: pointer;
    }
  }
}

.interaction-text i {
  cursor: pointer;
}

.basic-recent-post-month {
  visibility: hidden;
}

.content-box {
  padding: 0 !important;
  background-color: transparent !important;
  &.minHeight {
    min-height: 650px;
  }
}

.main-container .mid-column {
  padding-left: 0 !important;
}

.tile {
  padding: 0 !important;
}

/*.package {
  margin-top: 50px;
}*/

.banner {
  /*min-height: 250px;*/
  img {
    max-width: 100%;
    min-width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}

/******** tile style ********/

.tile {
  border: 1px solid #D1D1D1;
  margin-bottom: 15px;
  float: left;
  [class^="tile-"] {
    padding: 10px 15px;
    width: 100%;
    float: left;
  }
  &:not(.notice) .tile-header:not(.tab-holder) {
    font-size: 16px;
    border-bottom: 1px solid #E7EDF1;
    color: #3E5F8B;
    padding: 5px 15px;
    background-color: #E7EDF1;
  }
  .tile-footer {
    border-top: 1px solid #E2E2E2;
  }
  .tile-header {
    border-bottom: 1px solid #E2E2E2;
  }
  .tile-footer {
    padding-bottom: 7px;
    padding-top: 7px;
  }
}

.tile-header {
  font-size: 18px;
  margin-bottom: 0 !important;
  color: #3C3C3C;
  a {
    font-size: 18px;
    margin-bottom: 0 !important;
    color: #3C3C3C;
  }
  .title {
    font-size: 18px;
    margin-bottom: 0 !important;
    color: #3C3C3C;
    a {
      font-size: 18px;
      margin-bottom: 0 !important;
      color: #3C3C3C;
    }
  }
  &.tab-holder {
    border-bottom-width: 0;
    padding: 0;
    background-color: #FFFFD9;
  }
}

/*---- add tab on tile-header ----*/

.competition .tile-header.tab-holder {
  background-color: #CAEDCF;
}

.sticky .tile-header.tab-holder {
  background-color: #DDF2FB;
}

.tab-holder > ul {
  margin: 10px 0 0 0;
  padding: 0;
  float: left;
  width: 96% !important;
}

.competition .tab-holder > ul {
  width: 98% !important;
}

.tab-holder > ul > li {
  border: none;
  > a {
    font-size: 16px;
    border-radius: 0;
    border: none;
    color: #888888;
    border-bottom: 1px solid #D1D1D1 !important;
  }
  &.active > a.currentContest, > a.currentContest {
    color: #128422 !important;
  }
  &:not(.active) > a:hover {
    background-color: #E4E4C7;
    border-top: 1px solid #E4E4C7;
  }
}

.competition .tab-holder > ul > li:not(.active) > a:hover {
  background-color: #AEE4B6;
  border-top: 1px solid #AEE4B6;
}

.tab-holder > {
  ul > .active > a {
    border: 1px solid #D1D1D1 !important;
    border-bottom-color: #fff !important;
    color: #4E4C4C;
  }
  span {
    margin: 10px 0 0 0;
    width: 2% !important;
    float: left;
    border-bottom: 1px solid #D1D1D1;
    position: relative;
    height: 44px;
  }
}

.competition .tab-holder > span {
  width: 1% !important;
}

.tile-body {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.common-pagi {
  float: left;
  width: 100%;
  border-top: 1px solid #DBDBDB;
  padding: 5px 10px 7px;
  .pagination {
    margin: 5px auto 0 auto;
    > li {
      > a {
        margin-top: 5px;
        margin-left: 5px !important;
        border-radius: 4px;
        box-shadow: 1px 1px 2px #808080;
        border: 1px solid #D8D8D8;
        background-color: #FFFFFF;
        color: #999999;
        text-shadow: 0 2px 3px rgba(253, 242, 242, 0.5);
      }
      &.prev a {
        margin-right: 20px !important;
      }
      &.next a {
        margin-left: 20px !important;
      }
      &.active {
        > a {
          background-color: #CACACA !important;
          border: 1px solid #ACACAC;
          box-shadow: inset 2px 2px 6px #867A7A;
          color: white;
          text-shadow: 3px 3px 3px #504E4E;
        }
        > a {
          background-color: #DBDBB4 !important;
          box-shadow: inset 2px 2px 6px #867A7A;
        }
      }
      > a:hover {
        background-color: #CACACA !important;
        border: 1px solid #ACACAC;
        box-shadow: inset 2px 2px 6px #867A7A;
        color: white;
        text-shadow: 3px 3px 3px #504E4E;
      }
    }
  }
}

/*------ add tile-container style -------*/

.tile-container {
  padding-top: 10px !important;
}

#old-post .tile-container:nth-child(even):hover, #most-wanted .tile-container:nth-child(even):hover, .tile:not(.super-sticky) .tile-container:hover {
  background-color: #E7EDF1;
}

.post-view .mid-column .tile .tile-container:hover, .tile .highest-post-comments .tile-container:hover {
  background-color: transparent;
}

.tile-container:not(:first-child) {
  border-top: 1px solid #D1D1D1;
}

.content-header, .content-body, .content-footer {
  padding: 0 !important;
}

.content-header {
  position: relative;
  padding-bottom: 10px !important;
  border-bottom: 1px dashed #B9B9B9;
}

.comments-area {
  float: left;
  padding: 0 !important;
  margin-bottom: 5px;
  margin-top: 5px;
}

.comments-box {
  float: left;
  padding: 0 !important;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 7px !important;
  padding-right: 7px !important;
  .comments-group {
    float: left;
    width: 100%;
    padding: 0 8px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    &:hover {
      background-color: #DEE3F5;
    }
  }
}

.comments-area.for-post {
  margin-top: 10px;
}

.comments-box .comments {
  width: 100%;
  float: right;
  margin-bottom: 5px;
  margin-top: 5px;
  color: #82868D;
}

.comments-area .comments-editor {
  border: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  width: 100%;
  padding: 5px 10px;
  height: 60px;
  &:focus {
    outline: none;
  }
}

.comments-here .comments-area, .comments-box .comments.ans {
  padding-left: 6% !important;
}

.comments-area .commentator, .comments-box .commentator {
  position: relative;
  float: left;
  background-color: #fff;
  margin: 0 7px 3px 0;
  border: 1px solid #CECECE;
  box-shadow: 2px 2px 4px #CCCDCE;
}

.comments-area .comments-bubble, .comments-box .comments-bubble {
  float: right;
  /*width: 87%;*/
  border: 1px solid #CECECE;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  box-shadow: 3px 3px 6px #C5C4C4;
  position: relative;
  background: #fff;
}

.comments-area .comments-bubble:before, .comments-box .comments-bubble:before {
  content: "";
  position: absolute;
  top: 6px;
  left: -19px;
  border-style: solid;
  border-width: 9px 18px 9px 0;
  border-color: transparent #CECECE;
  display: block;
  width: 0;
  z-index: 0;
}

.comments-area .comments-bubble {
  &.onFocus {
    border-color: #F1AE05;
    &:before {
      border-color: transparent #F1AE05;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 6px;
    left: -17px;
    border-style: solid;
    border-width: 9px 18px 9px 0;
    border-color: transparent #ECECEC;
    display: block;
    width: 0;
    z-index: 1;
  }
}

.comments-box .comments-bubble:after {
  content: "";
  position: absolute;
  top: 6px;
  left: -17px;
  border-style: solid;
  border-width: 9px 18px 9px 0;
  border-color: transparent #ECECEC;
  display: block;
  width: 0;
  z-index: 1;
}

.comments-area .comments-bubble:after {
  border-color: transparent #fff;
}

.comments-bubble {
  .mce-panel {
    border-width: 0 !important;
    background-image: none;
    margin: 1px;
    * {
      background-color: transparent !important;
    }
  }
  .mce-flow-layout, .mce-toolbar-grp {
    position: absolute;
    top: 100%;
    margin: 7px 0 0;
  }
  .mce-flow-layout {
    margin-left: -5px;
    margin-top: 5px;
    .mce-btn-group {
      margin: 0 0 0 5px;
    }
  }
  .mce-toolbar-grp .mce-btn-group {
    margin: 0 0 0 5px;
  }
  .mce-flow-layout .mce-btn, .mce-toolbar-grp .mce-btn {
    margin: 0 0 0 5px;
    border: 1px solid #A8A8A8;
    /*background-image: none !important;*/
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
  }
  .mce-flow-layout .mce-btn button, .mce-toolbar-grp .mce-btn button {
    padding: 3px 3px 3px 4px;
  }
  .mce-flow-layout .mce-btn button i, .mce-toolbar-grp .mce-btn button i {
    font-size: 14px;
    color: #737373;
    /*text-shadow: none;*/
  }
}

.comments-area .action-box {
  display: none;
}

.comments-bubble .action-box {
  width: 100%;
  text-align: right;
  padding: 5px 0;
  border-top: 1px solid #D6D6D6;
  background-color: #EBEBEB !important;
  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
  input, button {
    margin-right: 5px;
    font-size: 14px;
    padding: 0 10px;
  }
}

.comments-box .comments-by {
  background-color: #ECECEC;
  padding: 5px 7px;
  font-size: 13px;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid #CECECE;
  > .icon-play {
    display: none;
    font-size: 20px;
    color: #ECECEC;
    text-shadow: 1px 1px 1px #CCCDCE, 1px -1px 1px #CCCDCE;
    position: absolute;
    margin-left: -22px;
  }
}

.complain-for {
  display: none;
  width: 100%;
  background-color: #F0F1E1;
  padding: 2px 10px;
  border-bottom: 2px solid #E0DDC8;
  legend {
    font-size: 14px;
    color: #678;
    border-bottom: 1px dashed #CAC3A4;
    padding: 5px 0;
    margin-bottom: 10px;
    a {
      cursor: pointer;
      color: #C0BFBF;
      font-size: 12px;
      padding: 0 4px;
      border: 1px solid #C0BFBF;
      float: right;
      margin-right: 3px;
      text-decoration: none;
      &:hover {
        background-color: #C0BFBF;
        color: white;
      }
    }
  }
  ul {
    padding: 0;
    margin: 0 0 3px 0;
  }
  .action-box {
    text-align: right;
    background-color: transparent !important;
    border-width: 0;
    margin-bottom: 10px;
  }
  li {
    list-style: none;
    display: inline-block;
    width: 32%;
    padding-left: 20px;
  }
}

.remember-me input, .complain-for input {
  display: none;
}

.remember-me label, .complain-for label {
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}

.remember-me input + label i, .complain-for input + label i {
  position: relative;
  font-size: 18px;
  color: #598099;
  padding: 3px;
  top: 2px;
}

.remember-me input:checked + label, .complain-for input:checked + label {
  color: #598099;
}

.remember-me input + label i:before, .complain-for input + label i:before {
  content: "\f096";
}

.remember-me input:checked + label i:before, .complain-for input:checked + label i:before {
  content: "\f14a";
}

.comments-box .comments-body {
  padding: 5px 10px 0 10px;
}

#old-post .tile-container:not(:first-child), #most-wanted .tile-container:not(:first-child) {
  border-top: 1px solid #D1D1D1;
  margin-top: 0;
}

#old-post .tile-container:nth-child(even), #most-wanted .tile-container:nth-child(even) {
  background-color: #E8EBEE;
}

#old-post .content-header {
  border-bottom-width: 0;
}

#most-wanted {
  .content-header {
    border-bottom-width: 0;
  }
  > ul {
    padding: 10px 15px 0;
    > li {
      > a {
        padding-top: 3px;
        padding-bottom: 10px;
        border-radius: 0;
        border-bottom: 1px dashed #CFCECE;
        color: #6E6E6E;
        &:hover {
          color: #000;
        }
      }
      &.active > a {
        background-color: transparent;
        color: #257E94;
      }
    }
  }
}

#most-commented .post-hit-count a:first-child, #most-like .post-hit-count a:nth-child(2), #most-read .post-hit-count a:nth-child(4) {
  background-color: #346F7A;
  border-radius: 25px;
  padding: 3px 8px;
  color: #fff;
}

#most-commented .post-hit-count a:first-child:hover, #most-like .post-hit-count a:nth-child(2):hover, #most-read .post-hit-count a:nth-child(4):hover {
  background-color: #158D15;
}

.subTab {
  padding: 15px 16px 0;
  > li {
    > a {
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      border-bottom: 1px solid #268434;
      color: #5A5A5A;
      &:hover, &:focus {
        background-color: #CAEDCF;
      }
    }
    &.active > a {
      color: #fff;
      background-color: #268434 !important;
      &:hover, &:focus {
        color: #fff;
        background-color: #268434 !important;
      }
      &.result {
        color: #E7CE4C !important;
        &:hover, &:focus {
          color: #E7CE4C !important;
        }
      }
    }
  }
}

.competition ul > li > a.result {
  color: #A05C1A !important;
}

.subChildTab {
  padding: 0 15px;
  > li {
    > a {
      position: relative;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      color: #BABABA;
      background-color: #187426 !important;
    }
    &.active > a:after {
      content: "";
      position: absolute;
      border-style: solid;
      border-color: #fff transparent;
      bottom: 0;
      left: 50%;
      border-width: 0 9px 7px 9px;
    }
  }
}

.vote-now {
  position: absolute;
  bottom: 10px;
  right: 10px;
  .vote-icon {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 50px;
    height: 50px;
    background-color: #018c05;
    color: #fff;
    -webkit-border-radius: 35%;
    -moz-border-radius: 35%;
    border-radius: 50%;
    padding: 4px;
    /*box-shadow: 1px 1px 5px #08490A;*/
    i {
      font-size: 22px;
    }
    span {
      display: block;
      margin-top: 0;
      font-size: 11px;
      font-weight: bold;
    }
  }
  .vote-btn {
    display: inline-block;
    position: relative;
    top: -8px;
    left: 0;
    text-decoration: none;
    background-color: #018c05;
    color: #fff;
    padding: 2px 12px 1px 10px;
    z-index: 0;
    font-size: 14px;
    font-weight: bold;
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
    &.done {
      color: #E5D20C;
    }
    &:first-child {
      -webkit-border-radius: 4px 0 0 4px;
      -moz-border-radius: 4px 0 0 4px;
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      -webkit-border-radius: 0 4px 4px 0;
      -moz-border-radius: 0 4px 4px 0;
      border-radius: 0 4px 4px 0;
    }
    &:before {
      position: absolute;
      top: -1px;
      z-index: 1;
      content: "";
      width: 8px;
      height: 24px;
    }
  }
  a.vote-btn {
    cursor: pointer;
    &:hover {
      color: #e4d113;
    }
  }
  .vote-btn {
    &:first-child:before {
      top: 0;
      right: -5px;
      background-image: url("../img/vote-btn-before.png");
      -ms-transform: rotate(7deg);
      /* IE 9 */
      -webkit-transform: rotate(7deg);
      /* Chrome, Safari, Opera */
      transform: rotate(180deg);
    }
    &:last-child:before {
      left: -5px;
      background-image: url("../img/vote-btn-before.png");
    }
  }
}

.tile-header {
  a.author-icon, > img {
    float: left;
    margin: 0 7px 0 0;
    width: 50px;
    height: 50px;
    overflow: hidden;
    display: inline-block;
  }
}

.content-header a.author-icon {
  float: left;
  margin: 0 7px 0 0;
  width: 50px;
  height: 50px;
  overflow: hidden;
  display: inline-block;
}

.tile-header img {
  min-width: 100%;
  /*height: 50px;*/
}

.content-header {
  a.author-icon img {
    min-width: 100%;
    /*height: 50px;*/
  }
  .title {
    font-size: 16px;
    margin-bottom: 10px !important;
    color: #3C3C3C;
    a {
      font-size: 16px;
      margin-bottom: 10px !important;
      color: #3C3C3C;
    }
  }
}

.tile-header small {
  font-size: 12px;
  color: #919490;
  a {
    font-size: 12px;
    color: #919490;
  }
}

.content-header small {
  font-size: 12px;
  color: #919490;
}

abbr {
  cursor: pointer !important;
}

.tile-header small a, .content-header small a {
  color: #15668D;
}

.tile-body {
  font-size: 14px;
}

.content-body {
  font-size: 14px;
  padding: 10px 0 !important;
  color: #7A7A7A;
  img {
    max-width: 100% !important;
  }
  > img:not(:first-child) {
    display: none;
  }
}

/*-------- tiny-album style -------*/

/*--------End of tiny-album -------*/

.content-footer {
  background-color: #ECECEC;
  padding: 5px 0 !important;
  float: left;
}

.tile.super-sticky {
  background-color: #DEEAF3;
}

.super-sticky .content-body {
  font-size: 15px;
  line-height: 23px;
  max-height: 250px;
  padding-top: 0 !important;
  padding-bottom: 5px !important;
  overflow: hidden;
  border-bottom: 1px dashed #7FBDEE;
}

.tile {
  &.promo {
    height: 246px;
    &.blog {
      background: #F4F4F4 url('../img/promo-1.jpg') no-repeat !important;
    }
    &.shop {
      background: #F4F4F4 url('../img/promo-2.jpg') no-repeat !important;
    }
    &.social {
      background: #F4F4F4 url('../img/promo-3.jpg') no-repeat !important;
    }
  }
  &.noHover .tile-container:hover {
    background-color: transparent;
  }
  &.promo {
    .tile-container:hover {
      background-color: transparent;
    }
    p {
      margin-top: 100px;
      margin-left: 50px;
      width: 80%;
      font-size: 13px;
      color: #3F3E3E;
      text-shadow: 1px 1px 0 #E9E9E9;
    }
    &:not(:last-child) {
      margin-bottom: 13px;
    }
  }
}

.super-sticky .author-unite {
  float: left;
  width: 195px;
}

.thin {
  #recaptcha_widget {
    width: 100% !important;
  }
  #recaptcha_image {
    width: 91% !important;
    img {
      width: 100% !important;
    }
  }
}

.super-sticky {
  .author-unite .author-avatar {
    display: block;
    width: 180px;
    height: 180px;
    background-color: #fff;
    padding: 5px;
    border: 1px solid #BDC1C4;
    img {
      /* max-height: 120%;
      max-width: 120%;*/
      max-width: 95%;
      max-height: 95%;
    }
  }
  .content-body .title {
    font-size: 16px;
    color: #575757;
    margin-bottom: 10px;
    a {
      font-size: 16px;
      color: #575757;
      margin-bottom: 10px;
    }
  }
  .author-name {
    padding: 0;
    font-size: 16px;
    margin-bottom: 10px;
    text-align: left;
    a {
      color: #2E7483;
    }
  }
  .tile-container {
    padding-bottom: 0;
  }
}

.rslides {
  position: relative;
  list-style: none;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
  li {
    -webkit-backface-visibility: hidden;
    position: absolute;
    display: none;
    width: 100%;
    left: 0;
    top: 0;
    &:first-child {
      position: relative;
      display: block;
      float: left;
    }
  }
}

.super-pagi {
  /*background-color: #E9C5F8;*/
  padding: 0;
  /*  border-top-color: #A1C3D3;
  background-color: #C8DEF3;*/
  .pagination {
    margin-top: -1px;
    padding-top: 0;
    padding-bottom: 1px;
    > li {
      > a {
        background-color: #B9DBFA;
        box-shadow: 1px 1px 2px #5B8296;
        border: 1px solid #AACBE0;
        color: #4D8394;
        text-shadow: 0 2px 3px rgba(253, 242, 242, 0.5);
        padding: 2px 8px;
      }
      &.active > a, > a:hover {
        background-color: #B3CBD1 !important;
        box-shadow: inset 2px 2px 6px #6E8486;
      }
    }
  }
}

/*----- End of super-sticky style ------*/

/******** notice style ********/

.notice {
  border-color: #D8C680;
  .tile-body {
    position: relative;
    padding: 20px 25px !important;
    background-color: #FEFDE0;
    color: #757575;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.read-more {
  color: #B97D10;
  margin-left: 10px;
  font-style: italic;
}

.all-notice {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #B97D10;
  padding: 2px 12px;
}

.read-more:hover {
  color: #B97D10;
}

.all-notice {
  &:hover, &:focus, &:active {
    color: #ffffff;
    background-color: #DB9F48;
    border-color: #d58512;
  }
}

/******** End of notice style ********/

.post-hit-count {
  float: right;
  margin-right: 5px;
  > a {
    font-size: 14px;
    margin-right: 10px;
    color: #797978;
  }
  a {
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: #158D15;
    }
  }
  .dropdown {
    display: inline-block;
    > a {
      border: 1px solid #C7C7C7;
      /*margin-right: 0 !important;*/
      padding: 0 4px;
      text-decoration: none;
      color: #797978;
      &:hover {
        border: 1px solid #158D15;
        color: #158D15;
      }
    }
  }
  .dropdown-menu {
    margin-right: -12px;
    margin-top: 3px;
    -webkit-border-radius: 0 0 0 5px;
    -moz-border-radius: 0 0 0 5px;
    border-radius: 0 0 0 5px;
    background-color: #ECECEB;
    a {
      padding-right: 15px;
      color: #727272;
      &:hover {
        background-color: #DBDADA;
        color: #1D85A3;
      }
      i {
        margin-right: 5px;
      }
    }
  }
}

.post-action-list {
  float: left;
  padding-left: 15px;
}

.comments .post-action-list {
  border-top: 1px solid #E6E6E6;
  width: 100%;
  text-align: right;
  padding: 5px 0;
}

.post-action-list a {
  color: #1D85A3;
}

.usp-cover {
  padding: 0;
  text-align: center;
  img {
    width: 100%;
    z-index: 2 !important;
  }
}

.usp-title-info {
  float: left;
  width: 100%;
  background-color: #E2ECF3;
  border-bottom: 1px solid #99BDD6;
}

.usp-name, .usp-from {
  width: auto;
  color: #8A8A8A;
}

.usp-name {
  float: left;
  text-align: left;
  font-size: 16px;
  padding: 5px 5px 5px 15px;
}

.usp-from {
  float: right;
  text-align: right;
  font-size: 13px;
  padding: 7px 15px 7px 7px;
}

.usp-short-info {
  border-bottom: 1px solid #E9E9E9;
  float: left;
  z-index: 1 !important;
  padding: 0 0 2px;
  ul {
    padding: 0;
    margin: 0;
    width: 100%;
  }
  li {
    list-style: none;
    display: table-cell;
    width: 1%;
    a {
      color: #858585;
      text-align: center;
      text-decoration: none;
      span {
        display: block;
        &:first-child {
          font-size: 18px;
          color: #fff;
          text-shadow: -1px 0 0 #B1B1B1, 0 -1px 0 #B1B1B1, 1px 0 0 #B1B1B1, 0 1px 0 #B1B1B1, 2px 2px 5px #666666;
        }
      }
    }
  }
}

.usp-status {
  float: left;
  border-top: 1px solid #E9E9E9;
  padding: 10px 15px;
  text-align: justify;
  color: #8A8A8A;
}

.usp-post-list {
  .tile-container {
    padding: 10px 15px !important;
    &:not(:first-child) {
      border-top: 1px solid #DDDDDD;
    }
  }
  .content-header {
    border-bottom-width: 0;
    padding-bottom: 0 !important;
  }
}

/*.field-group i {
  position: absolute;
  left: 16px;
  top: 1px;
  height: 32px;
  width: 35px;
  background-color: #C2C2C2;
  padding: 8px 10px !important;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  color: #696363;
  }*/
/*.field-group .form-control {
  width: 100%;
  padding-left: 45px;
  }*/

.logIn button {
  font-size: 14px;
  padding: 0 !important;
  width: 100px;
  height: 30px;
  margin-top: -5px;
  background-color: #DFDFDF;
  i {
    display: none;
    color: #64666B;
    font-size: 25px;
  }
}

.alert-dismissable i.close {
  top: -12px;
  right: -30px;
}

.field-group.field-error-group {
  i {
    left: 16px;
    top: 1px;
    height: 32px;
    color: #993838;
    background-color: #DFC4C4;
  }
  .form-control {
    border: 1px solid #F5A1A1;
  }
}

.set-of-field {
  width: 100%;
  float: left;
}

.thin .set-of-field {
  margin-bottom: 10px;
}

.set-of-field > label {
  float: left;
  width: 25%;
  padding-right: 10px;
  line-height: 28px;
  text-align: right;
  font-weight: normal;
  &.uperSet {
    float: none;
    margin-left: 5px;
    width: 100%;
    line-height: 28px;
    text-align: left;
    font-weight: normal;
  }
}

.mt10 {
  margin-top: 50px !important;
}

.set-of-field label[for=agree] {
  width: 100% !important;
  text-align: left !important;
}

.thin .set-of-field > div.mix, .set-of-field > div {
  float: left;
  width: 75%;
  /*margin-bottom: 15px;*/
}

.thin .set-of-field > div {
  &.mix {
    height: auto !important;
  }
  float: none;
  width: 100%;
}

.set-of-field > div > div {
  padding: 0 5px;
}

.forget-pass, .mobile-validation {
  min-height: 733px;
}

.mobile.registration .set-of-field > div {
  width: 100%;
  margin-bottom: 15px;
  &:first-child {
    margin-left: 5px;
    font-size: 16px;
  }
}

.after-seend-no, .after-test-no {
  display: none;
}

.mobile.registration .set-of-field {
  margin-bottom: 15px;
  margin-top: 15px;
}

.registration .field-error {
  /*display: none;*/
  color: #BE1C1C;
  font-size: 13px;
  padding-left: 5px;
}

.complain-for .field-error {
  line-height: 28px;
  padding-left: 30px;
}

.registration .horizontal .form-control {
  display: inline !important;
  width: 45%;
}

.date-of-birth + i {
  position: absolute;
  top: 0;
  right: 5px;
  height: 32px;
  width: 35px;
  background-color: #C2C2C2;
  padding: 8px 10px !important;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  color: #696363;
}

#recaptcha_widget {
  position: relative;
  width: 344px;
  background-color: #BDC1C2;
  padding: 5px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

#recaptcha_image {
  width: 300px;
  height: 57px;
  float: left;
  margin-bottom: 3px;
}

.recaptcha_buttons {
  width: 30px;
  height: 57px;
  margin-left: 3px;
  float: left;
}

#recaptcha_widget a {
  display: block;
  height: 27px;
  color: #ccc;
  background-color: #7B828A;
  text-decoration: none;
  padding: 3px 8px;
  &:first-child {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    margin-bottom: 3px;
  }
  &:last-child {
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
  }
}

#recaptcha_image img {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: #FFFFC4;
  border: 1px solid #A0A0A0;
}

#recaptcha_widget .form-control {
  width: 100%;
}

.registration a.dialog {
  font-family: dialogFont, serif;
  font-size: 25px;
  display: block;
  color: #898A85;
  text-decoration: none;
  margin: 70px 20px;
  &:first-child {
    margin-top: 215px;
  }
  &:last-child {
    margin-bottom: 215px;
  }
  i {
    font-size: 30px !important;
    color: white;
    padding: 15px 18px;
    margin-right: 15px;
    background-color: #C7C7C7;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid #B9B3B3;
  }
}

.FAQ {
  min-height: 350px;
}

.static-section {
  min-height: 350px;
  > ul {
    padding-left: 20px;
    padding-bottom: 50px;
  }
}

.FAQ li, .static-section li {
  list-style: none;
  color: #6F6F6F;
}

.FAQ li {
  &:nth-child(odd) {
    margin-bottom: 8px;
  }
  &:nth-child(even) {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px dashed #D1D5E0;
  }
  &:last-child {
    border-bottom: none;
  }
  span {
    position: absolute;
    display: block;
    left: 30px;
    width: 36px;
    text-align: right;
    &.qus {
      color: #4076AC;
    }
    &.ans {
      color: #2D8624;
    }
  }
}

.static-section > ul > li {
  > span {
    display: block;
    font-size: 16px;
    color: #164F83;
    margin: 10px 0;
  }
  ul {
    padding-left: 0;
  }
  li {
    font-size: 15px;
    padding-left: 35px;
    span {
      position: absolute;
      left: 35px;
      width: 25px;
      height: auto;
      text-align: right;
    }
  }
}

.static-column {
  padding-left: 0;
}

.static-menu {
  /*position: fixed;*/
  border: 1px solid #D1D1D1;
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  /*width: 15.666666666666664%;*/
  /*margin-left: 1%;*/
  background-color: #fff;
  ul {
    margin: 50px 0;
    padding: 0;
  }
  li {
    list-style: none;
    margin: 0 0 5px;
    a {
      display: block;
      font-size: 15px;
      padding: 5px 10px 5px 25px;
      text-decoration: none;
      color: #164F83;
      background-color: #DFE7E9;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
    }
    &.active a, a:hover {
      background-color: #C4D0D5;
    }
  }
}

.aft-reg {
  min-height: 658px;
  margin: 20px 0;
  h4 {
    font-size: 16px;
    color: #026BA4;
  }
  p {
    font-size: 15px;
    color: #4D4D4D;
    margin: 30px 10px;
    text-align: justify;
    &:last-child {
      text-align: right;
      font-size: 16px;
      color: #026BA4;
    }
  }
}

.searc-count {
  font-size: 13px;
  position: absolute;
  top: 7px;
  right: 15px;
}

.user-search {
  .mid-column {
    &:first-child {
      padding-right: 7px !important;
    }
    &:last-child {
      padding-left: 7px !important;
      padding-right: 0;
    }
    &.single {
      padding: 0 !important;
    }
  }
  > .tile {
    background-color: transparent !important;
    border-width: 0;
  }
  .tile-header {
    margin-bottom: 2px !important;
    border: 1px solid #D1D1D1;
  }
  .content-header {
    a.author-icon img {
      width: 60px;
      height: 60px;
    }
    .title {
      font-size: 14px !important;
      margin-bottom: 5px !important;
    }
  }
}

.content-header .title a {
  font-size: 14px !important;
  margin-bottom: 5px !important;
}

.highest-post-comments {
  ul {
    padding: 0;
  }
  li {
    list-style: none;
    &:hover {
      background-color: #F2F7FA;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #EEEEEE;
    }
    img {
      width: 14%;
    }
  }
  #highest-post li a, #highest-comments li a {
    width: 80%;
    padding: 9px 0 12px;
    display: inline-block;
    text-decoration: none;
    color: #939393;
    text-align: right;
  }
  #last-comments li {
    &:not(:first-child) {
      padding-top: 10px;
    }
    &:not(:last-child) {
      padding-bottom: 10px;
    }
    span {
      position: absolute;
      font-size: 13px;
      padding-left: 10px;
      color: #939393;
    }
    a {
      text-decoration: none;
      color: #588AB4;
    }
  }
  #highest-post li {
    img {
      float: right;
    }
    a {
      text-align: left;
    }
  }
}

#jump-menu .list-group-item.active:after {
  border-color: transparent #e1e3e6;
}

/*-------- tiny-album style -------*/

.tiny-album {
  width: 360px;
  height: 180px;
  position: relative;
  float: left;
  overflow: hidden;
  margin-right: 12px;
  margin-bottom: 20px;
  &.album-1 {
    width: 300px;
  }
}

.tiny-album-count {
  background-color: #383838;
  color: #FFF;
  font-size: 13px;
  display: none;
  z-index: 11 !important;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 3px 10px;
  opacity: 0.7;
}

.tiny-album .cover {
  background-color: #C3CDD8;
  padding: 0 1px;
  height: 180px;
  width: 300px;
  overflow: hidden;
  position: absolute;
  border-left: 1px solid #D6D6D6;
  border-right: 1px solid #D6D6D6;
  &:first-child {
    border-left-width: 0;
  }
  &:last-child {
    border-right-width: 0;
  }
  img {
    max-width: 120%;
    /*width: 180%;*/
  }
}

.album-1 .cover:not(:first-child), .album-2 .cover:nth-child(n+3), .album-3 .cover:nth-child(n+4), .album-4 .cover:nth-child(n+5) {
  display: none;
}

.album-2 .cover:nth-child(2), .album-3 .cover:nth-child(3), .album-4 .cover:nth-child(4) {
  right: 0 !important;
  z-index: 1 !important;
}

.album-2 .cover:nth-child(1), .album-3 .cover:nth-child(2), .album-4 .cover:nth-child(3) {
  right: 60px;
  z-index: 2;
}

.album-3 .cover:nth-child(1) {
  right: 120px;
  z-index: 3;
}

.album-4 .cover {
  &:nth-child(2) {
    right: 120px;
    z-index: 3;
  }
  &:nth-child(1) {
    right: 180px;
    z-index: 4;
  }
}

.album-2 .cover:hover, .album-3 .cover:hover, .album-4 .cover:hover {
  z-index: 10 !important;
}

/*--- Tiny album for left-column ( mid size ) ---*/

.left-column {
  .tiny-album {
    width: 252px;
    height: 126px;
    &.album-1 {
      width: 210px;
    }
    .cover {
      width: 210px;
      height: 100%;
      min-width: 53%;
      max-width: 210px;
    }
  }
  .album-2 .cover:nth-child(1), .album-3 .cover:nth-child(2), .album-4 .cover:nth-child(3) {
    right: 42px;
  }
  .album-3 .cover:nth-child(1) {
    right: 84px;
  }
  .album-4 .cover {
    &:nth-child(2) {
      right: 84px;
    }
    &:nth-child(1) {
      right: 126px;
    }
  }
}

.tiny-album {
  &.album-3 .cover.altZindex:nth-child(2), &.album-4 .cover.altZindex:nth-child(3) {
    z-index: 3;
  }
  &.album-3 .cover.altZindex:nth-child(1) {
    z-index: 2;
  }
  &.album-4 .cover.altZindex {
    &:nth-child(2) {
      z-index: 2;
    }
    &:nth-child(1) {
      z-index: 1;
    }
  }
}

.tile.ticket {
  border: 1px solid #AAC3A9;
  background-color: #DBEDDB;
  .tile-header {
    border-bottom: 1px solid #E7EDF1 !important;
    color: #1C3F1E !important;
    background-color: #B0DBB2 !important;
  }
}

.tic-box {
  width: 100%;
  border-bottom: 1px solid #C5D8C4;
  padding: 5px 7px 5px 15px;
  .pagination {
    margin: 0;
    > li > {
      a {
        background-color: #00f;
        &:hover {
          background-color: #f00;
        }
      }
      span:hover, a:focus, span:focus {
        background-color: #f00;
      }
    }
  }
  &:hover {
    background-color: #C2E0C2;
    cursor: pointer;
  }
}

.tic-pic {
  float: left;
  width: 50px;
  height: 50px;
}

.tic-body {
  padding-left: 10px;
  margin-right: -20px;
  overflow: hidden;
}

.ticTitle {
  color: #3172C1;
}

.ticMark {
  color: #009D4F;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h6 {
  font-weight: 200;
}

a {
  text-decoration: none;
  color: #fff;
  &.link {
    color: #fff;
    background-color: #3f4c6b;
    margin-bottom: 20px;
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid #3f4c6b;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: #fff;
      color: #3f4c6b;
    }
  }
}

/* CONTAINERS */

.container {
  max-width: 940px;
  width: 100%;
  margin: 0 auto;
}

.twelve {
  width: 100%;
}

.eleven {
  width: 91.53%;
}

.ten {
  width: 83.06%;
}

.nine {
  width: 74.6%;
}

.eight {
  width: 66.13%;
}

.seven {
  width: 57.66%;
}

.six {
  width: 49.2%;
}

.five {
  width: 40.73%;
}

.four {
  width: 32.26%;
}

.three {
  width: 23.8%;
}

.two {
  width: 15.33%;
}

.one {
  width: 6.866%;
}

/* COLUMNS */

.col {
  display: block;
  float: left;
  margin: 1% 0 1% 1.6%;
  &:first-of-type {
    margin-left: 0;
  }
}

/* CLEARFIX */

.cf {
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
  *zoom: 1;
}

/* CALENDAR */

.calendar-wrapper {
  width: 100%;
  height: 500px;
  margin: 10px 0;
  position: relative;
  overflow: hidden;
  background: #3f4c6b;
  color: #fff;
  background: -moz-linear-gradient(-45deg, #3f4c6b 0%, #3f4c6b 100%);
  background: -webkit-linear-gradient(-45deg, #3f4c6b 0%, #3f4c6b 100%);
  background: linear-gradient(135deg, #3f4c6b 0%, #3f4c6b 100%);
}

#calendar {
  width: 100%;
  height: 100%;
  text-align: center;
}

.ui-datepicker {
  margin: 0;
  padding: 0;
  border: none;
  border-spacing: 0;
  table, tr, td, th {
    margin: 0;
    padding: 0;
    border: none;
    border-spacing: 0;
  }
  display: none;
  width: 100%;
}

.ui-datepicker-header {
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #d6d6d6;
}

.ui-datepicker-calendar {
  width: 100%;
}

.ui-datepicker-title {
  text-align: center;
  font-size: 30px;
  margin: 20px 0;
}

.ui-datepicker-calendar {
  th {
    padding-top: 15px;
    padding-bottom: 10px;
    text-align: center;
  }
  td {
    padding: 0 10px;
    text-align: center;
  }
  .ui-state-default {
    display: block;
    outline: none;
    border: 1px solid transparent;
    font-size: 18px;
    line-height: 50px;
    cursor: default;
  }
}
.category-post-list{
  ul{
    padding: 0;
  }
  li.category-list {
    list-style: initial;
    display: inline-block;
  }
}

.news-feed-media {
  .newsfeed-post{
    margin-top: 0;
    .media{
      padding: 0;
    }
  }
}
.old-post{
  .user-post-interact{
    background: none !important;
    text-align: right;
  }
}

.ui-datepicker-prev {
  position: absolute;
  left: 15px;
  top: 10px;
  cursor: pointer;
}

.ui-datepicker-next {
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
}

.back-to-list {
  margin-top: 0;
  background: #e3e3e3;
  display: block;
  padding: 10px;
  margin-left: -15px;
  margin-right: -15px;
}

.highlight{
  font-weight: bold;
}

.notification-list-feed{
  li.media:hover {
    background: initial !important;
  }
}
