.main-home-layout{
  margin-top: 120px;
}
@import url("http://fonts.googleapis.com/css?family=Just+Another+Hand");
.section-wrap { width: 100%; padding: 0; margin-bottom: 15px; }

.section-header { border-bottom: 2px solid #808080; height: 32px; margin-bottom: 15px; position: relative; margin-top: 5px; }

.section-header .title { text-decoration: none; position: absolute; bottom: 0; background-color: #808080; font-size: 17px; color: #fff; padding: 6px 15px 5px 32px; }

.section-header .title:hover { text-shadow: 0 2px 3px #555; }

.section-header .custom-icon { top: 5px; left: 6px; }

.section-header.blue { border-color: #4eb3ec; }

.section-header.blue .title { background-color: #4eb3ec; }

.section-header.green { border-color: #82b440; }

.section-header.green .title { background-color: #82b440; }

.section-header.nightLife { border-color: #745B34; }

.section-header.nightLife .title { background-color: #745B34; }

.section-header.red { border-color: #f65261; }

.section-header.red .title { background-color: #f65261; }

.section-header.pink { border-color: #ff3e9f; }

.section-header.pink .title { background-color: #ff3e9f; }

.section-header.orange { border-color: #be6c1c; }

.section-header.orange .title { background-color: #be6c1c; }

.section-header.violet { border-color: #542b7a; }

.section-header.violet .title { background-color: #542b7a; }

.topics .centric { height: 140px; }
.topics .lecture h3 { margin-top: 8px; margin-bottom: 5px; }
.topics .title { font-size: 16px; line-height: 1.8em; }
.topics.list.thumb, .block-template-1 .group-of-post .topics.list, .block-template-2 .group-of-post .topics.list { margin-bottom: 12px; }
.topics.list.thumb .footage, .block-template-1 .group-of-post .topics.list .footage, .block-template-2 .group-of-post .topics.list .footage { width: 95px; height: 62px; margin-right: 10px; }
.topics.list.thumb .lecture, .block-template-1 .group-of-post .topics.list .lecture, .block-template-2 .group-of-post .topics.list .lecture { width: auto; float: none; padding: 0; }
.topics.list.thumb .title, .block-template-1 .group-of-post .topics.list .title, .block-template-2 .group-of-post .topics.list .title { font-size: 14px; }
.topics.list.small, .block-template-3 .group-of-post .topics.list, .block-template-4 .group-of-post .topics.list { margin-bottom: 12px; }
.topics.list.small .footage, .block-template-3 .group-of-post .topics.list .footage, .block-template-4 .group-of-post .topics.list .footage { width: 128px; height: 82px; margin-right: 10px; }
.topics.list.small .lecture, .block-template-3 .group-of-post .topics.list .lecture, .block-template-4 .group-of-post .topics.list .lecture { width: auto; float: none; padding: 0; }
.topics.list.small .title, .block-template-3 .group-of-post .topics.list .title, .block-template-4 .group-of-post .topics.list .title { font-size: 13px; line-height: 1.6em; }
.topics.list.smallPortrait, .block-template-5 .group-of-post .topics.list { margin-bottom: 12px; }
.topics.list.smallPortrait .footage, .block-template-5 .group-of-post .topics.list .footage { width: 88px; height: 118px; margin-right: 10px; }
.topics.list.smallPortrait .lecture, .block-template-5 .group-of-post .topics.list .lecture { width: auto; float: none; padding: 0; }
.topics.list.smallPortrait .title, .block-template-5 .group-of-post .topics.list .title { font-size: 14px; }

.block-template-2 .topics .centric, .block-template-3 .topics .centric { height: 200px; }

.block-template-5 .group-of-post .topics { box-shadow: 2px 2px 2px rgba(196, 196, 196, 0.4), 0 0 1px rgba(109, 105, 105, 0.4); -webkit-border-radius: 4px; -moz-border-radius: 4px; border-radius: 4px; position: relative; }
.block-template-5 .group-of-post .topics .footage { -webkit-border-radius: 4px 0 0 4px; -moz-border-radius: 4px 0 0 4px; border-radius: 4px 0 0 4px; }
.block-template-5 .group-of-post .topics .lecture { padding-top: 10px; }
.block-template-5 .group-of-post .topics .lecture .post-type-area { position: absolute; bottom: 10px; left: 98px; }
.block-template-5 .group-of-post .topics .lecture .post-type { padding-left: 26px; position: relative; }
.block-template-5 .group-of-post .topics .lecture .post-type i { position: absolute; left: 0; top: 0; }

.block-template-4 .topics { margin-bottom: 5px; }
.block-template-4 .topics .footage { width: 45% !important; height: 160px; margin-right: 10px; }
.block-template-4 .topics .lecture { width: auto; float: none; padding: 0; }
.block-template-4 .topics .lecture h3 { margin-top: 0; }

.topRightArrow .slick-arrow { top: -30px; background-color: #C1C1C1; height: 20px; width: 20px; -webkit-border-radius: 100%; -moz-border-radius: 100%; border-radius: 100%; padding-top: 1px; }
.topRightArrow .slick-prev { left: auto; right: 45px; }
.topRightArrow .slick-next { right: 20px; }
