
.feature-food {
  .post-body,
  .post-title,
  .post-meta,
  .post-content,
  .post-readmore,
  .post-footer {
    width: 100%;
    height: auto;
    clear: both;
  }
  .post-title .title,
  .post-title .title a {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.71rem;
    font-weight: 400;
    line-height: 2.35rem;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  .post-meta .meta-data {
    padding: 0 0 15px 0;
    margin: 0 0 15px 0;
    position: relative;
    z-index: 0;
  }
  .post-meta .meta-data:after {
    content: "";
    position: absolute;
    width: 30px;
    height: 3px;
    background: #f2e394;
    bottom: 0;
    left: 0;
    z-index: 0;
  }
  .post-meta .meta-data li {
    display: inline-block;
    margin-right: 10px;
  }
  .post-meta .meta-data li a,
  .post-meta .meta-data li i {
    font-size: 12px;
    color: #999;
  }
  .post-meta .meta-data li a:hover {
    color: #000;
    text-decoration: underline;
  }
  .post-excerpt {
  }
  .post-excerpt p {
    font-family: 'Lora', serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #888;
    margin: 0 0 10px 0;
  }
  .post-readmore {
    margin-bottom: 20px;
  }
  .post-readmore .read-more {
    font-family: 'Lora', serif;
    font-size: 15px;
    font-weight: 400;
    color: #dbc96c;
  }
  .post-readmore .read-more:hover {
    color: #000;
    text-decoration: underline;
  }
}

.row.wrap {
  background: #E2DCE9 url("../img/food-bg.png") no-repeat 0 bottom fixed;
}

.content-box {
  padding: 0;
  background-color: transparent;
}

.main-contain-area {
  padding: 15px 20px;
 // background-color: #fff;
}

.main-content {
  padding: 0 22px;
}

.site-header {
  margin-bottom: 15px;
}

.site-header,
.site-header img {
  width: 100%;
}

.no-pading {
  padding: 0 !important;
}

.block-type .showcase .branch {
  margin-bottom: 5px;
}

.block-type .grid-2 .cell {
  width: 48.27%;
  margin-right: 20px;
}

.block-type .grid-2 .cell:nth-child(2n+2) {
  margin-right: 0;
}

.block-type .release-info {
  font-size: 14px;
  padding: 5px 0;
  border-bottom: 1px dashed #B8B5B5;
  margin-bottom: 7px;
}

.thumb-type {
  padding-left: 5px;
  padding-right: 15px;
}

.thumb-type.alt {
  padding-left: 0;
  padding-right: 5px;
}

.showcase {
  margin-top: 0;
}

.thumb-type .showcase {
  background-color: transparent;
  margin-top: 0;
  margin-bottom: 30px;
}

.thumb-type .showcase .branch:hover {
  background-color: #DBE7EB;
}

.thumb-type .showcase.list figure,
.thumb-type .showcase.list .figure {
  width: 33%;
  height: 80px;
}

.thumb-type .showcase.list article,
.thumb-type .showcase.list .article {
  width: 67%;
  height: 73px;
  padding: 8px 10px 0;
  overflow: hidden;
}

.thumb-type .showcase.list .branch {
  padding: 0;
}

.thumb-type .showcase.list .branch .description {
  height: auto;
  color: #714208;
  font-size: 15px;
}

.thumb-type .showcase footer {
  border: none;
}

.list-type .showcase .branch {
  margin-bottom: 12px;
  /*border-bottom: 1px dotted #E2E5E7;*/
}

.list-type .showcase.list .branch .title {
  font-size: 16px;
  margin-top: 0;

}

.list-type .showcase.list .branch .description {
  height: 82px;
  margin-bottom: 7px;
  overflow: hidden;
}

.details-type .showcase .branch {
}

.details-type .showcase .branch img {
  max-width: 100%;
  margin-bottom: 10px;
}

.details-type .showcase .branch .title {
  font-size: 24px;
}

.social-area {
  width: 100%;
  background-color: transparent;
  padding: 0;
}

.social-area.white {
  background-color: transparent;
  margin-bottom: 15px;
  margin-top: 2px;
}

.like-area {
  padding: 0;
  min-height: 30px;
  position: relative;
  text-align: center;
}

.like-area > div:nth-child(1) {
  left: 78px;
}

.like-area > div:nth-child(2) {
  left: 285px;
}

.like-area > div:nth-child(3) {
  right: 126px;
}

.like-area > div:nth-child(4) {
  right: 22px;
}

.font-sizer {
  top: 0;
  right: 0;
}

.font-sizer span {
  height: 27px;
  width: 30px;
}

.font-sizer span:first-child {
  padding-top: 2px;
}

.font-sizer span:last-child {
  padding-top: 6px;
}

.like-area > div {
  top: 6px;
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
}

.post-item-food {
  background: #f2f2f2;
  padding: 15px;
  margin-bottom: 30px;
  position: relative;
  z-index: 0;
  .post-icon {
    position: absolute;
    width: 35px;
    height: 35px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    line-height: 33px;
    z-index: 1;
    top: 20px;
    left: 20px;
    background: #dbc96c;
    color: #fff;
  }
}
.post-header{
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    background-color: rgba(0,0,0,.35);
  }
}