.feature-travel {
  .blog-1st .content .post:first-child, .blog-standard .post {
    width: 100%;
  }
  .image-wrap {
    height: 300px;
    overflow-y: hidden;
  }
  .blog-grid .content .post {
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
    float: left;
  }
  .blog-content .post {
    margin-bottom: 30px;
  }
  .blog-grid .content .post .post-media {
    position: relative;
  }
  .blog-content .post .post-media {
    position: relative;
    overflow: hidden;
  }
  .blog-content .post .post-media img {
    width: 100%;
  }
  .blog-1st .content .post .post-media .post-format, .blog-grid .content .post .post-media .post-format {
    position: absolute;
  }
  .blog-content .post .post-media .post-format {
    position: absolute;
    display: inline-block;
    top: 5px;
    left: 5px;
    z-index: 9;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .blog-content .post .post-media .post-format a {
    display: inline-block;
  }
  .blog-content .post .post-media .post-format a .fa {
    width: 46px;
    height: 46px;
    text-align: center;
    line-height: 46px;
    color: #fff;
    font-size: 14px;
  }
  .blog-content .post .post-media .post-format a:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: #1abc9c;
    opacity: .7;
  }
  #page-wrap.layout2 .blog-content .post .post-body {
    background-color: #fff;
  }

  .blog-1st .content .post:first-child .post-body, .blog-standard .post .post-body {
    position: relative;
    border: 1px solid #ebebeb;
    border-top: 0;
    padding: 35px 46px 35px 128px;
    min-height: 240px;
    text-align: left;
  }
  .gm-style .gm-style-iw .post .post-cat, .blog-content .post .post-cat {
    margin-bottom: 5px;
  }
  .gm-style .gm-style-iw .post .post-cat ul, .blog-content .post .post-cat ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .gm-style .gm-style-iw .post .post-cat ul li, .blog-content .post .post-cat ul li {
    display: inline-block;
  }
  .gm-style .gm-style-iw .post .post-cat ul li a, .blog-content .post .post-cat ul li a {
    font-family: 'Montserrat', sans-serif;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    color: #1abc9c;
  }
  .gm-style .gm-style-iw .post .post-title, .blog-content .post .post-title {
    margin-bottom: 13px;
  }
  .blog-1st .content .post:first-child .post-title h1, .blog-1st .content .post:first-child .post-title h2, .blog-standard .post .post-title h1, .blog-standard .post .post-title h2 {
    font-size: 32px;
    text-transform: uppercase;
    margin-top: 10px;
  }
  .gm-style .gm-style-iw .post .post-title h2, .blog-content .post .post-title h2 {
    margin-top: 0;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    color: #000;
    margin-bottom: 0;
  }
  .gm-style .gm-style-iw .post .post-title h2 a, .blog-content .post .post-title h2 a {
    display: block;
    color: inherit;
  }
  .blog-1st .content .post:first-child .post-meta, .blog-standard .post .post-meta {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 20px 0;
    border-right: 1px solid #ebebeb;
  }
  .blog-1st .content .post:first-child .post-meta .post-view, .blog-1st .content .post:first-child .post-meta .post-like, .blog-1st .content .post:first-child .post-meta .post-comment, .blog-standard .post .post-meta .post-view, .blog-standard .post .post-meta .post-like, .blog-standard .post .post-meta .post-comment {
    color: #676767;
    text-align: center;
    width: 82px;
    padding: 15px 0;
    border-bottom: 1px solid #ebebeb;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover{
      color: #1ABC9C;
    }
  }
  .blog-1st .content .post:first-child .post-meta .post-view .fa, .blog-1st .content .post:first-child .post-meta .post-like .fa, .blog-1st .content .post:first-child .post-meta .post-comment .fa, .blog-standard .post .post-meta .post-view .fa, .blog-standard .post .post-meta .post-like .fa, .blog-standard .post .post-meta .post-comment .fa {
    font-size: 22px;
    display: block;
  }
  .blog-1st .content .post:first-child .post-meta .post-view .count, .blog-1st .content .post:first-child .post-meta .post-like .count, .blog-1st .content .post:first-child .post-meta .post-comment .count, .blog-standard .post .post-meta .post-view .count, .blog-standard .post .post-meta .post-like .count, .blog-standard .post .post-meta .post-comment .count {
    display: block;
    color: inherit;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    margin-top: 6px;
  }
  .blog-1st .content .post:first-child .post-meta > div:last-child, .blog-standard .post .post-meta > div:last-child {
    border: 0;
  }
  p {
    font-size: 16px;
    line-height: 26px;
  }
  div#post-7 {
    border: 1px solid #e4e4e4;
    padding: 20px;
  }
  .entry-category a {
    font-size: 18px;
    position: relative;
    top: 5px;
  }
  .post-meta-short {
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
    .post-view-short, .post-like-short,.post-comment-short{
      width:30%;
      display: inline-block;
      cursor: pointer;
      &:hover{
        color: #1ABC9C;
      }
    }
  }
}

