ul.sumarry-details li i {
  width: 20px;
}
.select-box{
  #select-box1{
    height: 34px;
  }
}
ul.about-work, ul.about-education  {
  padding: 15px;
  border: 1px solid #e3e3e3;
  margin: 5px 0;
}
.birthday-input input{
  height: 42px !important;
}
.about-myprofile{
  .editable-input select {
    height: 42px;
  }
}
.intro-account-details > ul.about-myprofile > li {
  padding-bottom: 10px;
}
.add-work-button, .add-education-button {
  width: 180px;
  margin-left: 15px;
}
.about-view {
  .panel-title>a {
    color: white;
  }
  h4{
    font-size: 15px;
    i {
      width: 20px;
    }
  }
  .study-history-title{
    padding-top: 10px;
  }
  ul.work-history {
    padding: 0 0 20px 0;
    li {
      font-size: 14px;
      line-height: 23px;
    }
  }
  ul.study-history {
    padding: 0;
    li {
      font-size: 14px;
      line-height: 23px;
    }
  }
  ul.sumarry-details{
    padding: 0;
    li {
      font-size: 14px;
      line-height: 23px;
    }
  }
  .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
    border-width: 0;
  }
  .panel-default>.panel-heading {
    color: #fafafa;
    background-color: #0B7ED4;
    border-color: #ddd;
    margin: 0;
  }
  .nav-stacked > li + li {
    margin-top: 0px;
  }
  .nav-tabs > li > a {
    padding: 15px !important;
    color: #000;
    background-color: #fff;
    border: 0px;
    border-radius: 0px;
    margin: 0;
  }
  .nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
    background-color: #0B7ED4;
    color: #FFFFFF;
    z-index: 99;
    transition: all 0.5s ease 0s;
  }
  .nav-tabs > li.active > a {
    color: #FFFFFF;
    background-color: #0B7ED4;
    box-shadow: 0 0px 5px rgba(0, 0, 0, .6);
    z-index: 100;
  }
  .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    color: #FFFFFF;
    background-color: #0B7ED4;
    box-shadow: 0 0px 5px rgba(0, 0, 0, .6);
    transition: all 0.5s ease 0s;
  }
  .left-nav-list {
    border: 1px solid #0B7ED4;
    margin-bottom: -1px;
  }
}