.tg-btn {
  z-index: 3;
  color: #fff;
  padding: 0 50px;
  background: #fff;
  overflow: hidden;
  position: relative;
  text-align: center;
  border-radius: 100px;
  border: 2px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  text-transform: capitalize;
  font: 500 16px/56px 'Montserrat', Arial, Helvetica, sans-serif;
}

.p-relative {
  position: relative;
}

.tg-btn:hover, .tg-btn.tg-active, .tg-dropdowarrow, .tg-bookby, .tg-sectiontitle h2:before, .tg-title h3:before, .tg-panel h4:hover:after, .tg-panel h4.active:after, .tg-prev:hover, .tg-next:hover, .tg-navigation ul li a:hover, .tg-navigation ul li.tg-avtive a, .tg-navigation ul li a.current, .tg-btnclosenav, .tg-bannercontent .tg-btnarea .tg-btn {
  background: #ee3324;
}

.tg-btn:hover, .tg-btn.tg-active {
  color: #fff;
}

.tg-btn {
  color: #ee3324;
}

.tg-aboutus {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  .tg-aboutusimg {
    margin: 0;
    width: 100%;
    float: left;
  }
  .tg-imgshadow {
    position: relative;
    padding: 0 30px 30px 0;
  }
  .tg-imgshadow img {
    width: 100%;
    height: auto;
    display: block;
  }
  .tg-imgshadow:after {
    right: 0;
    bottom: 0;
    width: 95%;
    content: '';
    height: 90%;
    z-index: -1;
    position: absolute;
    background: #f7f7f7;
  }
  .tg-aboutusshortcode,
  .tg-textshortcode {
    width: 100%;
    float: left;
  }
  .tg-sectionheadvtwo .tg-sectiontitle h2 {
    padding: 0;
    font-size: 35px;
    line-height: 40px;
  }
  .tg-sectionheadvtwo .tg-sectiontitle h2:before {
    display: none;
  }
  .tg-sectiontitle span {
    display: block;
    margin: 0 0 6px;
    font-size: 18px;
    text-align: left;
    line-height: 18px;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  }
  .tg-textshortcode .tg-description p {
    margin: 0;
    line-height: 28px;
  }
  .tg-firstletter {
    width: 60px;
    float: left;
    height: 60px;
    display: block;
    font-size: 45px;
    padding: 0 14px;
    line-height: 56px;
    margin: 10px 13px 0 0;
    border: 2px solid #ddd;
  }
}
.store-details-tab{
  .nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    border: 1px solid #ddd !important;
    background: transparent;
    border-bottom: 1px solid white !important;
  }
}
.book-store {
  .btn-1 {
    background: #ff851d;
    font-size: 14px;
    position: relative;
    display: inline-block;
    padding: 0 28px;
    height: 58px;
    line-height: 58px;
    border-radius: 5px;
    color: #fff;
    text-transform: uppercase;
    z-index: 11;
    text-decoration: none;
    -webkit-box-shadow: 0 1px 5px 0 rgba(50, 50, 50, 0.67);
    -moz-box-shadow: 0 1px 5px 0 rgba(50, 50, 50, 0.67);
    box-shadow: 0 1px 5px 0 rgba(50, 50, 50, 0.67);
    &:hover{
      opacity:.8;
      transition: all 4s;
    }
  }
  .btn-1.sm {
    height: 40px;
    line-height: 40px;
    padding: 0 18px;
    font-size: 13px;
  }
  .tg-sectionhead {
    width: 100%;
    float: left;
    text-align: center;
  }
  .tg-sectiontitle {
    width: 100%;
    float: left;
  }
  .tg-sectiontitle h2 {
    margin: 0;
    font-size: 36px;
    font-weight: 300;
    line-height: 30px;
    padding: 0 0 20px;
    position: relative;
  }
  .tg-sectiontitle h2:before {
    left: 50%;
    bottom: 0;
    content: '';
    height: 3px;
    width: 100px;
    position: absolute;
    margin: 0 0 0 -50px;
  }
  .tg-textalignleft .tg-sectiontitle h2 {
    text-align: left;
  }
  .tg-textalignleft .tg-sectiontitle h2:before {
    left: 0;
    margin: 0;
  }
  .tg-sectionhead .tg-description {
    padding: 33px 0 0;
  }
  .tg-sectionhead .tg-description p {
    margin: 0;
    line-height: 28px;
  }
  .add-banner {
    position: relative;
    border-radius: 5px;
    min-height: 172px;
  }
  .add-banner::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 5px;
  }
  .add-banner-1::before {
    background: rgba(153, 211, 255, 0.8);
  }
  .add-banner-2::before {
    background: rgba(197, 241, 95, 0.8);
  }
  .add-banner {
    background: url('../img/ads-bg.jpg') no-repeat;
  }
  .add-banner h3 {
    font-size: 22px;
    margin: 0 0 10px;
    font-style: italic;
    font-weight: bold;
    color: #0c085c;
  }
  .add-banner p {
    margin: 0;
    font-size: 16px;
  }
  .add-banner hr {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin: 10px 0;
  }
  .add-banner strong {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  .add-banner strong sup {
    font-size: 10px;
  }
  .add-banner .adds-book {
    position: absolute;
  }
  .add-banner:hover .adds-book {
    transform: scale(1.1);
    transition: all .4s;
  }
  .add-banner-1 .adds-book {
    top: -20px;
    left: 0;
  }
  .add-banner-2 .adds-book {
    top: -20px;
    right: 0;
  }
  .add-banner-1 {
    padding: 20px 40px 5px 186px;
  }
  .add-banner-2 {
    padding: 20px 186px 5px 40px;
  }
  .add-banner-2 h3 {
    font-size: 36px;
  }
  .add-banner-2 strong {
    font-size: 20px;
    font-style: italic;
    margin: 0 0 10px;
    display: block;
  }
  .add-banner-2 p {
    color: #343434;
    font-size: 20px;
  }
}

/* =============================================
			Other Books Style
============================================= */

.publisher {

  .tg-otherproducts,
  .tg-otherProduct {
    width: 100%;
    float: left;
  }
  .tg-otherProduct figure {
    margin: 0;
    width: 100%;
    float: left;
    background: #000;
    position: relative;
  }
  .tg-otherProduct figure img {
    width: 100%;
    height: auto;
    display: block;
  }
  .tg-otherProduct figure:hover img {
    opacity: 0.60;
  }
  .tg-othercontent {
    width: 100%;
    float: left;
    padding: 20px 0 0;
  }
  .tg-booktitle {
    width: 100%;
    float: left;
    padding: 0 0 9px;
  }
  .tg-booktitle time {
    color: #666;
    display: block;
    font-size: 14px;
    margin: 0 0 5px;
    line-height: 14px;
  }
  .tg-booktitle h3 {
    margin: 0;
    font-size: 20px;
    line-height: 20px;
  }
  .tg-booktitle h3 a {
    display: block;
  }
  .tg-othercontent .tg-description p {
    margin: 0;
    font-size: 16px;
    line-height: 28px;
  }
  .tg-prev,
  .tg-next {
    top: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    line-height: 50px;
    margin: -100px 0 0;
    text-align: center;
    position: absolute;
    background: #f7f7f7;
  }
  .tg-prev {
    left: -50px;
    border-radius: 30px 0 0 30px;
  }
  .tg-next {
    right: -50px;
    border-radius: 0 30px 30px 0;
  }
  .tg-prev span,
  .tg-next span {
    color: #666;
    font-size: 22px;
    line-height: 22px;
  }
  .tg-prev:hover,
  .tg-next:hover,
  .tg-prev:hover span,
  .tg-next:hover span {
    color: #fff;
  }
}

// Book Store

.search-bar.style-3 .sub-btn {
  background: none;
  color: #575655;
}

.best-seller .rating-stars {
  overflow: hidden;
  margin: 0 0 5px;
}

.best-seller .rating-stars li {
  margin: 0;
}

.offer-price {
  color: #999;
}
.product-sort {
  overflow: hidden;
  padding: 0 0 20px;
  border-bottom: 1px solid #eee;
  margin: 0 0 30px;
}

.product-sort .sort-dropdown label {
  float: left;
  line-height: 32px;
  margin: 0 20px 0 0;
}

.product-sort .sort-dropdown select {
  height: 32px;
  border: 1px solid #eee;
  padding: 0 10px;
  color: #999;
}

.product-grid-holder .product-box {
  margin: 0 0 50px;
}

.product-grid-holder .rating-nd-price {
  border-top: 1px solid #e7e7e7;
  padding: 20px 0 0;
  border-bottom: 0;
}

/****** Shop Detail ***********************************************************
*********************************************************** Shop Detail ******/
.add-cart-alert {
  overflow: hidden;
  border-top: 1px solid;
  padding: 30px;
  background: #f7f7f7;
  margin: 0 0 40px;
}

.add-cart-alert p {
  float: left;
  margin: 0;
  line-height: 40px;
}

.add-cart-alert p i {
  margin: 0 10px 0 0;
}

.single-product-detail {
  margin: 0 0 60px;
}

.product-thumnbnail {
  text-align: center;
}

.product-thumnbnail .bx-wrapper {
  border: 1px solid #e3e3e3;
  overflow: hidden;
  text-align: center;
}

.product-thumnbnail .bx-wrapper ul li {
  padding: 50px 0;
}

.product-thumnbnail .bx-wrapper ul li > img {
  display: inline-block;
  -webkit-box-shadow: 0 1px 5px 0 rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 0 1px 5px 0 rgba(50, 50, 50, 0.75);
  box-shadow: 0 1px 5px 0 rgba(50, 50, 50, 0.75);
}

.product-thumnbnail .bx-wrapper ul li .expand {
  height: 45px;
  width: 45px;
  text-align: center;
  line-height: 45px;
  background: #1e293d;
  position: absolute;
  left: 0;
  top: 0;
}

.product-thumnbnail #product-thumbs a {
  float: left;
  padding: 15px 29.8px;
  border-left: 1px solid #e3e3e3;
  border-top: 0;
  border-bottom: 1px solid #e3e3e3;
}

.product-thumnbnail #product-thumbs a:last-child {
  border-right: 1px solid #e3e3e3;
}

.product-thumnbnail #product-thumbs {
  display: inline-block;
}

.availability {
  text-transform: capitalize !important;
  color: #999;
  margin: 0 0 20px;
  display: block;
}

.availability strong {
  margin: 0 0 0 10px;
}

.availability strong i {
  margin: 0 0 0 10px;
}

.single-product-detail h3 {
  font-weight: bold;
  font-size: 24px;
}

.single-product-detail .rating-stars {
  margin: 0 0 20px;
  overflow: hidden;
  padding: 0;
}

.single-product-detail .rating-stars li:last-child {
  margin: 0 0 0 10px;
  font-size: 12px;
  color: #999;
  line-height: 25px;
}

.single-product-detail h4 {
  font-size: 18px;
  font-weight: bold;
}

.single-product-detail p {
  margin: 0 0 19px;
}

.quantity-box {
  overflow: hidden;
  margin: 0 0 20px;
}

.quantity-box label {
  float: left;
  margin: 0 10px 0 0;
  line-height: 32px;
}

.sp-minus {
  width: 40px;
  height: 40px;
  border: 1px solid #f2f2f2;
  float: left;
  text-align: center;
  color: #999;
  cursor: pointer;
}

.sp-input {
  width: 110px;
  height: 40px;
  border: 1px solid #f2f2f2;
  border-left: 0 solid black;
  float: left;
}

.sp-plus {
  width: 40px;
  height: 40px;
  border: 1px solid #f2f2f2;
  border-left: 0 solid #f2f2f2;
  float: left;
  text-align: center;
  color: #999;
  cursor: pointer;
}

.sp-input input {
  height: 100%;
  width: 100%;
  padding: 0;
  text-align: center;
  border: 0;
}

.sp-input input:focus {
  border: 1px solid #f2f2f2;
  border: none;
}

.sp-minus a, .sp-plus a {
  display: block;
  width: 100%;
  height: 100%;
  padding-top: 5px;
  color: #999;
}

.single-product-detail .btn-list li {
  width: auto;
  margin: 0 0 0 5px;
}

.single-product-detail .btn-list li .btn-1 i {
  padding: 0;
}

.reviews-list {
  margin: 0 0 60px;
}

.reviews-list ul li > img {
  position: absolute;
}

.reviews-list > ul > li {
  margin: 0 0 30px;
}

.reviews-list > ul > li:last-child {
  margin: 0;
}

.reviews-list ul li .comment {
  padding: 0 0 0 70px;
}

.reviews-list ul li .comment h6 {
  float: left;
  font-weight: bold;
  color: #333;
}

.reviews-list ul li .comment h6 span {
  margin: 0 10px;
  color: #999;
}

.reviews-list ul li .comment .rating-stars {
  display: inline-block;
  margin: 0 0 3px;
}

.reviews-list ul li .comment .rating-stars li {
  line-height: 0;
}

.reviews-detail {
  overflow: hidden;
}

.reviews-list ul li .comment p {
  margin: 0;
}

.add-review {
  margin: 0 0 60px;
}

.add-review h5 {
  font-size: 18px;
  font-weight: bold;
}

.add-review h6 {
  font-weight: bold;
  font-size: 14px;
}

.add-review .rating-stars {
  margin: 0 0 20px;
  overflow: hidden;
}

.add-review form {
  width: 85%;
}

.related-products h5 {
  font-weight: bold;
  font-size: 18px;
  margin: 0 0 30px;
}

.tabs-nav ul li {
  margin: 0 0 6px;
}

.tabs-nav ul li:last-child {
  margin: 0;
}

.tabs-nav ul li a {
  padding: 15px 20px;
  background: #f9f9f9;
  font-weight: bold;
}

.disc-reviews-tabs .tabs-nav ul li.ui-tabs-active a {
  color: #fff;
}

.description {
  margin: 0 0 60px;
}

.description p:last-child {
  margin: 0;
}

.description .check-list li::before {
  content: "\f046";
  margin: 0 10px 0 0;
  font-family: fontawesome, sans-serif;
}

.description .check-list li {
  margin: 0 0 10px;
}

.description .check-list li:last-child {
  margin: 0;
}

.related-products .product-box {
  margin: 0 !important;
}

.search-bar.style-2 {
  margin: 0;
  position: relative;
}

.search-bar.style-2 input {
  height: 43px;
  line-height: 43px;
  border: 1px solid #e6e6e6;
  text-transform: uppercase;
}

.search-bar.style-2 .sub-btn {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0;
  height: 43px;
  line-height: 43px;
  padding: 0 15px;
  width: auto;
}

.aside-widget {
  margin: 0 0 40px;
  overflow: hidden;
}

.aside-widget > h6 {
  padding: 0 0 15px;
  border-bottom: 5px solid #f5f5f5;
  font-weight: bold;
  margin: 0 0 25px;
}

.Category-list li a {
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
  font-size: 14px;
}

.Category-list li a:hover {
  margin: 0 0 0 20px;
  transition: all .6s
}

.Category-list li a:hover::before {
  margin: 0 10px 0 0;
}

.Category-list li a::before {
  content: "\f105";
  font-family: fontawesome, sans-serif;
  margin: 0 10px 0 0;
  color: #ccc;
}

.Category-list li:first-child a {
  padding-top: 0;
}

.Category-list li:last-child a {
  padding-bottom: 0;
  border: 0;
}

ul.Category-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.Category-list li {
  padding: 8px 0;
}

/* Best Seller */
.product-box {
  border: 1px solid #e7e7e7;
  position: relative;
  .add-to-cart-button {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.product-box::before,
.product-box::after {
  content: "";
  position: absolute;
  width: 80%;
  height: 80%;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
}

.product-box::before {
  transition: all .4s;
  border-left: 2px solid #1e293d;
  border-top: 2px solid #1e293d;
  left: 0;
  top: 0;
}

.product-box::after {
  transition: all .4s;
  border-right: 2px solid #1e293d;
  border-bottom: 2px solid #1e293d;
  right: 0;
  bottom: 0;
}

.product-box:hover::before,
.product-box:hover::after {
  visibility: visible;
  opacity: 1;
  width: 100%;
  height: 100%;
}

.product-img {
  padding: 10px 0;
  text-align: center;
  background: #f8f8f8;
  position: relative;
  z-index: 2;
  width: 95%;
  margin: 6px auto 0;
}

.product-img img {
  max-width:100%;
  -webkit-box-shadow: 0 1px 5px 0 rgba(50, 50, 50, 0.67);
  -moz-box-shadow: 0 1px 5px 0 rgba(50, 50, 50, 0.67);
  box-shadow: 0 1px 5px 0 rgba(50, 50, 50, 0.67);
}

.product-cart-option {
  left: 20px;
  opacity: 0;
  visibility: hidden;
}

.product-cart-option li {
  margin: 0 0 15px;
}

.product-cart-option li:last-child {
  margin: 0;
}

.product-cart-option li a {
  height: 34px;
  width: 34px;
  line-height: 34px;
  text-align: center;
  background: #f2f2f2;
  color: #555;
}

.product-cart-option li a:hover {
  color: #fff;
}

.product-box:hover .product-cart-option {
  opacity: 1;
  visibility: visible;
}

.product-box:hover .product-cart-option li {
  margin: 0 0 6px !important;
}

.product-img .sale-bacth {
  height: 45px;
  width: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 12px;
  border-radius: 100%;
  display: block;
  position: absolute;
  right: 10px;
  color: #fff;
  text-transform: uppercase;
}

.product-detail {
  padding: 0 20px;
  position: relative;
  z-index: 2;
  > a span{
    font-size: 13px;
    color: #8e3265;
  }
  a.writer-name{
    font-size: 12px;
    color: #8e3265;
  }
}

.product-detail > span {
  font-size: 12px;
}

.product-detail a h5{
  font-weight: bold;
  font-size: 16px;
  display: block;
}
.writer-name h5{
  margin:0;
}

.product-detail p {
  margin: 0 0 10px;
}

.rating-nd-price {
  overflow: hidden;
  border-bottom: 1px solid #e7e7e7;
  padding: 0 0 15px;
}

.rating-nd-price strong {
  float: left;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.rating-nd-price ul {
  float: right;
  margin: 3px 0 0;
  padding: 0;
}

.aurthor-detail {
  overflow: hidden;
  padding: 15px 0 0;
}

.aurthor-detail span {
  float: left;
  text-transform: capitalize;
}

.aurthor-detail span img {
  border-radius: 100%;
  overflow: hidden;
  margin: 0 10px 0 0;
}

.aurthor-detail a {
  margin: 5px 0 0;
  float: right;
  height: 24px;
  width: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 100%;
  background: #f3f3f3;
}

.product-detail::before,
.product-detail::after {
  border-bottom: 1px solid #e7e7e7;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  content: "";
  height: 8px;
  position: absolute;
}

.product-detail::before {
  bottom: -9px;
  left: 2px;
  width: 97.6%;
  z-index: 0;
}

.product-detail::after {
  bottom: -17px;
  height: 8px;
  left: 3px;
  width: 96.6%;
}

.tabs-nav-holder {
  overflow: hidden;
  position: relative;
  margin: 0 0 30px;
}

.tab-content > .tab-pane {
  height: 0;
}

.tab-content > .active {
  height: auto;
}

.tabs-nav-holder::before {
  content: "";
  position: absolute;
  bottom: 0;
  border-bottom: 1px solid #e7e7e7;
  height: 100%;
  width: 92%;
  left: 0;
  z-index: -1;
}

.best-sellers-tabs .tabs-nav {
  overflow: hidden;
  float: left;
  border-radius: 4px;
}

.best-sellers-tabs .tabs-nav li {
  float: left;
  border: 1px solid #efefef;
  border-left: 0;
}

.best-sellers-tabs .tabs-nav li:last-child {
  left: 0;
}

.best-sellers-tabs .tabs-nav li:first-child {
  border-left: 1px solid #efefef;
}

.best-sellers-tabs .tabs-nav li a {
  padding: 12px 25px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Merriweather', serif;
  min-width: 115px;
  background: #fafafa;
}

.best-sellers-tabs .tabs-nav li.ui-tabs-active a {
  color: #fff;
  border-radius: 4px;
}

.best-seller-slider .owl-stage-outer {
  padding: 20px;
  margin: -20px;
}

.best-seller-slider .owl-nav {
  position: absolute;
  top: -25px;
  right: 0;
}

.best-seller-slider .owl-nav .owl-prev,
.best-seller-slider .owl-nav .owl-next {
  height: 25px;
  width: 25px;
  position: static;
  font-size: 10px;
  float: left;
  margin: 0 0 0 10px;
  line-height: 25px;
  color: #8c8c8c;
  background: #f6f6f6;
  border: 1px solid #e7e7e7;
  box-shadow: none;
}

.best-seller-slider .owl-nav .owl-prev::before {
  content: "\e903";
}

.best-seller-slider .owl-nav .owl-next::before {
  content: "\e902";
}

.position-center-x {
  position: absolute;
  top: 50%;
}

.rating-stars li {
  float: left;
  margin: 0 0 0 4px;
}

.rating-stars li:first-child {
  margin: 0
}

.rating-stars li i {
  color: #f0bf2d;
  font-size: 14px;
}

.product-detail p {
  font-size: 13px;
}

.add-to-cart-button {
  text-align: center;
  margin-top: 20px;
}

.range-price-slider{
  .range-slider__value{
    font-size: 12px;
  }
  // Base Colors
  $shade-10: #2c3e50 !default;
  $shade-1: #d7dcdf !default;
  $shade-0: #fff !default;
  $teal: #1abc9c !default;

  .range-slider {
    margin: 20px 0 0 0%;
  }

  $range-width: 100% !default;
  $range-handle-color: $shade-10 !default;
  $range-handle-color-hover: $teal !default;
  $range-handle-size: 20px !default;
  $range-track-color: $shade-1 !default;
  $range-track-height: 10px !default;
  $range-label-color: $shade-10 !default;
  $range-label-width: 60px !default;
  .range-slider {
    width: $range-width;
  }
  .range-slider__range {
    -webkit-appearance: none;
    //width: calc(100% - (#{$range-label-width + 13px}));
    height: $range-track-height;
    border-radius: 5px;
    background: $range-track-color;
    outline: none;
    padding: 0;
    margin: 0;

    // Range Handle
    &::-webkit-slider-thumb {
      appearance: none;
      width: $range-handle-size;
      height: $range-handle-size;
      border-radius: 50%;
      background: $range-handle-color;
      cursor: pointer;
      transition: background .15s ease-in-out;

      &:hover {
        background: $range-handle-color-hover;
      }
    }

    &:active::-webkit-slider-thumb {
      background: $range-handle-color-hover;
    }

    &::-moz-range-thumb {
      width: $range-handle-size;
      height: $range-handle-size;
      border: 0;
      border-radius: 50%;
      background: $range-handle-color;
      cursor: pointer;
      transition: background .15s ease-in-out;

      &:hover {
        background: $range-handle-color-hover;
      }
    }

    &:active::-moz-range-thumb {
      background: $range-handle-color-hover;
    }
  }

  .range-slider__value {
    display: inline-block;
    position: relative;
    width: $range-label-width;
    color: $shade-0;
    line-height: 20px;
    text-align: center;
    border-radius: 3px;
    background: $range-label-color;
    padding: 5px 10px;
    margin-left: 8px;
    margin-top: 15px;
    &:after {
      position: absolute;
      top: 8px;
      left: -7px;
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-right: 7px solid $range-label-color;
      border-bottom: 7px solid transparent;
      content: '';
    }
  }
  // Firefox Overrides
  ::-moz-range-track {
    background: $range-track-color;
    border: 0;
  }
  input::-moz-focus-inner,
  input::-moz-focus-outer {
    border: 0;
  }
}

button.btn-default.sub-btn.fa.fa-search {
  background: none;
  border: none;
}

.book-pdp{
  .single-product-detail {
    margin: 0 0 60px;
    h3 {
      font-weight: bold;
      font-size: 24px;
      margin:0;
    }
    h4 {
      font-size: 18px;
      font-weight: bold;
    }
    p {
      margin: 0 0 19px;
    }
    .rating-stars li:last-child {
      margin: 0 0 0 10px;
      font-size: 12px;
      color: #999;
      line-height: 25px;
    }
    .availability {
      text-transform: capitalize!important;
      color: #999;
      margin: 0 0 20px;
      display: block;
      strong{
        color: #ff851d;
      }
      strong i {
        margin: 0 0 0 10px;
      }
    }
    .quantity-box {
      overflow: hidden;
      margin: 0 0 20px;
      label {
        float: left;
        margin: 0 10px 0 0;
        line-height: 32px;
      }
      .sp-minus {
        width: 40px;
        height: 40px;
        border: 1px solid #f2f2f2;
        float: left;
        text-align: center;
        color: #999;
        cursor: pointer;
      }
      .sp-minus a, .sp-plus a {
        display: block;
        width: 100%;
        height: 100%;
        padding-top: 5px;
        color: #999;
      }
      .sp-input {
        width: 110px;
        height: 40px;
        border: 1px solid #f2f2f2;
        border-left: 0 solid black;
        float: left;
      }
      .sp-plus {
        width: 40px;
        height: 40px;
        border: 1px solid #f2f2f2;
        border-left: 0 solid #f2f2f2;
        float: left;
        text-align: center;
        color: #999;
        cursor: pointer;
      }
    }
  }
  .btn-list {
    margin: 0;
    padding: 0;
    list-style: none;
    .btn-1.blank {
      background: #f2f2f2;
      color: #999;
    }
    .btn-1.sm {
      height: 40px;
      line-height: 40px;
      padding: 0 18px;
      font-size: 13px;
    }
    li {
      width: auto;
      margin: 0 0 0 5px;
      float: left;
    }
  }
}

.shadow-0 {
  box-shadow: none !important;
}

.product-thumnbnail {
  text-align: center;
  .product-slider {
    border: 1px solid #e3e3e3;
    overflow: hidden;
    text-align: center;
    margin-bottom: 0;
    li {
      padding: 50px 0;
    }
  }
  #product-thumbs {
    display: inline-block;
    a {
      padding: 15px 18.7px;
      img{
        width: 70px;
        height: 100px;
        object-fit: cover;
      }
    }
  }
}

#tg-otherproducts{
  .owl-carousel .owl-item img {
    max-width: 100%;
    margin: 0 auto;
    width: initial;
  }
}


.book-info-list{
  margin-top: 20px;
  ul{
    padding-left: 0;
    margin-left: 0;
  }
  li {
    margin: 0 0 10px;
    span {
      font-weight: bold;
      margin: 0 5px 0 0;
    }
  }
}