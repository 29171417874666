@media (min-width: 767px) {
  .p-res-0{
    padding: 0!important;
  }
}
@media (min-width: 1100px) {
  ul.nav.navbar-nav.megamenu > li {
    margin-left: 5px;
    &:first-child {
      margin-left: 0;
    }
  }
  .search-form-nav {
    margin-top: 3px;
  }
  .sectionHeader {
    width: initial;
  }
  .customized-search {
    input.sb {
      width: 95%;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .section-menu.sidebar-left.sidebar {
    width: 55px;
    top: 60px;
    position: fixed;
    &:hover {
      width: 140px;
    }
  }
}

@media (min-width: 991px) {
  .gossip-active .breadcrumb-nav {
    width: calc(100% - 230px);
  }
  #cart-request .shopping-cart .shopping-cart-items {
    overflow: auto;
    height: 140px;
  }
  li.dropdown:hover > .dropdown-menu {
    display: block;
  }
  img.banner-pic.banner-pic-blank {
    height: 250px !important;
  }
  nav.subNav .megamenu-fw > a {
    font-weight: bold;
  }
}

@media (max-width: 1100px) {
  .navbar-header {
    .navbar-brand {
      img {
        width: 50px;
      }
    }
  }
}

@media (max-width: 991px) {
  .notification-list {
    .media-body {
      text-align: left;
    }
    .media-right {
      position: relative;
      .btn-group {
        width: 80px;
      }
    }
  }

  .title-notification {
    padding: 15px;
    background: #e3e3e3;
    font-weight: bold;
    font-size: 18px;
    position: relative;
    top: 4px;
  }
  .bottom-stiky-bar ul.nav li.media {
    background: rgba(51, 135, 189, 0.02);
  }
  .bottom-stiky-bar ul.nav li.media.unread {
    background: rgba(51, 135, 189, 0.22);
  }
  .faq-category-row {
    label {
      width: 33%;
    }
  }
  .icon-set-desc {
    margin-top: 25px;
  }
  ul.nav.navbar-nav.navbar-right.top-nav-user {
    margin-right: -15px;
  }
  a.sidebar-left-opener {
    left: 10px;
  }
  li.dropdown-profile-image {
    width: 46px;
  }
  .blog-post-container:nth-child(2) {
    padding-left: 0;
  }
  .customized-search input.sb {
    font-size: 16px;
  }
  .customized-search .sbtn {
    font-size: 20px;
  }
  .main-header .navbar-brand {
    top: 0px;
    left: 60px;
    position: relative;
  }
  a.loged-user {
    .visible-big-inline {
      display: none;
    }
  }
  .account-setting-view, .about-view {
    ul.nav-stacked {
      display: flex;
      overflow-y: auto;
      li.left-nav-list {
        border: none;
        > a {
          height: 100%;
          border: 1px solid #0081d5;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .category-nav {
    li.item {
      display: block;
      margin: 0 auto 5px;
    }
  }
  .search-box {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 20px;
    float: left !important;
  }
  .birthday-celebration {
    position: absolute;
    top: 0;
    left: 20px;
    transform: initial;
    color: #fff;
    font-size: 5em;
    font-weight: 900;
    -webkit-user-select: none;
    user-select: none;
    width: 450px;
  }
}

@media screen and (max-width: 767px) {
  .social-nav {
    padding: 9px 5px;
  }
  .notification-list li.media {
    padding: 10px;
  }
  button.hamburger.is-open {
    position: relative;
    top: -25px;
  }

  .holy-wrapper .hamburger {
    width: 50px;
    height: 50px;
  }
  .category-group{
    columns: 20px 4;
    padding: 0;
    li.sub-category-item:before {
      content: "";
    }
  }
  li.main-category-item:first-child {
    border-bottom: 1px solid #e3e3e3;
    margin-bottom: 5px;
}
  .category-heading {
    margin-bottom: 20px;
  }
  .feature-food.profile-blog.container {
    padding: 0;
  }
  .social-nav ul li a, .sub-navi ul li a {
    font-size: 12px;
  }
  .reply-list {
    margin-right: -5px;
  }
  .wait-thirty-second {
    position: initial;
    display: block;
    float: left;
  }
  .banner .user-intro .title {
    margin: 1px auto;
    display: inline-block;
  }
  .banner .friends-req-area>* {
    float: right;
    margin-left: 0;
  }
  .comments-container {
    margin: 0 -15px;
  }
  .post-tabs .nav > li > a {
    padding: 8px 2px;
  }
  .quick-metas>ul>li>a {
    margin: 0 10px 0 0;
    > i {
      margin-right: 1px;
    }
  }
  .friend-list-page {
    ul.media-list{
      margin-left: 15px;
      margin-right: 15px;
    }
    li.media {
      width: 100%;
      overflow: initial;
      float: none;
      margin-bottom: 10px;
      &:last-child,&:nth-last-child(2){
        margin-bottom: 20px;
      }
    }
    .navbar {
      margin-left: 15px;
      margin-right: 15px;
    }
    .navbar-nav {
      margin: 7.5px 0;
    }
  }
  .forMyPostPage .left-column {
    margin-left: 0;
    margin-right: 0;
  }
  .post-tabs {
    float: none;
    width: 100%;
  }
  .newsfeed-post {
    margin-top: 20px;
    padding: 10px;
  }
  .blog-post-start-title {
    border-bottom: 1px solid #808080;
    padding-bottom: 10px;
    text-align: center;
    background: #e3e0de;
    padding-top: 10px;
    color: black;
    margin: 0;
    margin-left: -15px;
    margin-right: -15px;
    font-size: 25px;
    margin-bottom: 20px;
    border-top: 1px solid #808080;
  }
  .newsfeed-post .media {
    padding: 0;
  }
  .rating-nd-price ul.rating-stars {
    float: none;
    margin: 0 auto;
    padding: 0;
    display: inline-block;
  }
  .rating-nd-price strong {
    float: none;
    text-align: center;
  }
  .rating-nd-price {
    text-align: center;
  }
  .product-img img {
    width: 100px !important;
    text-align: center;
    display: inline-block !important;
  }
  .product-box {
    text-align: center;
  }
  .tg-aboutus {
    display: block;
    clear: both;
    .tg-sectionheadvtwo .tg-sectiontitle h2 {
      padding: 0;
      font-size: 20px;
      line-height: 20px;
      font-weight: bold;
    }
  }
  .book-store .tg-sectiontitle h2 {
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;
    padding: 0 0 10px;
  }
  .book-store .add-banner {
    max-width: 400px;
    margin: 0 auto 30px;
    p {
      font-size: 12px;
    }
    h3 {
      font-size: 16px;
    }
    strong {
      font-size: 18px;
      font-weight: bold;
      color: #333;
    }
  }
  .checkout-page-sticky {
    .tab-payment .paymentWrap {
      padding: 0;
    }
    .box {
      .nav-justified > li {
        float: left;
        a {
          position: relative;
          display: block;
          padding: 10px;
        }
      }
    }
  }
  .table-responsive > .table > tbody > tr > td, .table-responsive > .table > tbody > tr > th, .table-responsive > .table > tfoot > tr > td, .table-responsive > .table > tfoot > tr > th, .table-responsive > .table > thead > tr > td, .table-responsive > .table > thead > tr > th {
    white-space: initial;
  }
  .table-responsive {
    border: 0;
  }
  .icon-set-desc-img img {
    padding: 0;
    margin-top: -50px;
  }

  .navbar-nav .open .dropdown-menu {
    position: absolute;
    float: initial;
    width: initial;
    margin-top: initial;
    background-color: white;
    border: initial;
    -webkit-box-shadow: initial;
    box-shadow: initial;
  }
  .mtopneg60 {
    margin-top: -60px;
  }
  .show-search {
    height: 35px;
    margin-top: 3px !important;
  }
  .navbar-header {
    padding-left: 0;
  }
  .search-form-nav {
    margin-top: 0;
  }
  .top-nav-user .dropdown-menu {
    left: -130px;
  }
  .full-blue {
    padding-left: 0;
  }
  .megamenu {
    text-align: center;
    height: 49px;
    > li {
      float: initial;
      margin: 0 auto;
      text-align: left;
      display: inline-block;
    }
  }
  .main-header {
    position: static;
  }
  .chat-menu.col-xs-12 {
    text-align: center;
    button {
      margin-right: 10px;
      margin-bottom: 10px;
      text-align: center;
      display: inline-block;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .social-nav {
    text-align: center;
  }
  .social-nav ul li, .sub-navi ul li {
    display: inline-block;
    padding: 0 10px;
  }
  .chat-box-wraper .chat-list-area {
    max-width: 106px;
  }
  .chat-list ul li .chat-short-info {
    display: none;
  }
  .chat-list ul li .user-img img {
    border-radius: 50%;
  }
  .chat-box-wraper .chat-header > .current-chat-type {
    display: none;
  }
  .sectionHeader {
    margin-top: 0;
    height: 41px;
    display: flex;
    justify-content: center;
  }
  body {
    overflow-x: hidden;
  }
  li.search-form-nav {
    position: absolute;
    top: 37px;
    left: 0;
    width: 100%;
    padding: 5px 15px;
    form {
      border: 0 !important;
    }
  }
  .bds3 {
    border-color: transparent;
  }
  .comments-list .comment-box {
    width: calc(95% - 50px);
  }
  .banner {
    height: 220px;
    overflow: hidden;
    .user-intro {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      text-align: center;
    }
    .banner-pic, .profile-pic {
      height: 100%;
    }
    .friends-req-area {
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
      right: initial;
      button {
        margin-bottom: 3px;
      }
    }
  }
  .search-form-nav {
    display: none;
  }
}

@media screen and (max-width: 479px) {
  .book-store {
    .add-banner {
      min-height: initial;
    }
    .add-banner > img {
      display: none !important;
    }
    .add-banner {
      padding: 30px !important;
      margin: 0 0 30px;
      strong {
        font-size: 18px;
        font-weight: bold;
        color: #333;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .sectionHeader li + li {
    margin-right: 20px;
  }
  li.show-search {
    height: 25px;
  }
  .sectionHeader {
    padding-top: 5px;
    line-height: 25px;
    padding-left: 17px;
    li {
      font-size: 9px;
    }
  }
  .lang-btn-grp {
    left: 110px;
  }
  .navbar-default .navbar-brand {
    img {
      width: 50px;
    }
  }

  a.loged-user {
    display: none;
  }
  .navbar-brand {
    padding: 15px 5px;
  }

}

@media screen and (max-width: 300px) {
  .bottom-stiky-bar ul.nav {
    display: block;
    li {
      width: 19%;
      float: left;
    }
  }
  footer.nb-footer .about .social-media ul li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 15px;
  }
  .bottom-stiky-bar .placard .lft > div:last-child {
    width: 65%;
  }
  .placard > .row > div {
    padding: 10px 20px 10px 0;
  }
  .bottom-stiky-bar ul.nav li.media {
    width: initial;
    height: 65px;
    overflow-y: hidden;
    .media-right {
      vertical-align: initial;
      position: relative;
      top: 17px;
    }
  }
  .bottom-stiky-bar .notify {
    left: 25px !important;
  }
  .social-nav ul li, .sub-navi ul li {
    display: inline-block;
    padding: 0 6px;
  }
  .social-nav {
    height: 40px;
    padding: 9px 0;
    background: #0B7ED4;
  }
  .social-nav ul li a, .sub-navi ul li a {
    font-size: 14px;
  }
}
