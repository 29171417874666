.account-setting-view {
  .panel-title > a{
    color: #fafafa;
    &:hover{
      color: #fafafa;
    }
  }
  .radio-style{
    .panel-heading {
      background-color: white;
      color: #000;
      .panel-title {
        font-weight: bold;
      }
    }
    .form-group{
      margin-bottom: 30px;
      padding: 6px 0;

    }
  }
  h4.panel-heading {
    margin-top: 0;
    margin-bottom: 0;
  }
  .panel-group .panel-heading {
    border: 1px solid #2962ff;
  }
  .panel-heading {
    background-color: #2962ff;
    color: white;
  }
  .panel-title > a {
    display: block;
    padding: 8px 10px;
    color: #ffffff !important;
  }
  .panel-body-input {
    margin-bottom: 10px;
  }
  .panel-group {
    margin-top: 0;
  }
  div.card {
    h1 {
      margin: 0 0 20px 0;
      font-weight: normal;
      color: #03a9f4;
      font-size: 30px;
    }
    .phone-number-input{
      display: inline-block;
      padding-left: 5px;
    }
    select#select-box1 {
      padding: 5px;
      border: 1px solid silver;
    }
    form > input {
      border: 2px solid silver;
      padding: 0;
      border-width: 0 0 2px 0;
      margin-bottom: 15px;
      transition: border-color .3s;
      &:focus, &:hover {
        border-color: #03a9f4;
        outline: 0;
      }
      &.warning {
        border-color: #ff9800;
      }

      &.error {
        border-color: #f44336;
      }

      &.valid {
        border-color: #4caf50;
      }

      &[type=submit] {
        border: 0;
        background-color: white;
        color: #03a9f4;
        text-transform: uppercase;
        width: auto;
        cursor: pointer;
      }
    }
  }
  #radioBtn .notActive{
    color: #3276b1;
    background-color: #fff;
  }
}
#collapsePrivacy{
    .panel-title {
      padding: 5px;
      text-align: center;
  }
}

.about-view  #collapsePlace{
  .panel-default>.panel-heading {
    background-color: #0B7ED4;
    opacity: .7;
  }
}
