
* {
  font-family: 'Noto Sans', sans-serif;
}

html {
  height: 100%;
  box-sizing: border-box;
  font-family: 'Noto Sans', sans-serif;
}

body {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 6rem;
}

html {
  position: relative;
  min-height: 100%;
}
.dropdown-menu>li>a:hover, .dropdown-menu>li>a:focus {
  width: 100%;
  background-color: #f5f5f5 !important;
}
footer {
  position: relative;
  left: 0;
  bottom: 0;
  height: auto;
  width: 100%;
}
.content {
  flex: 1 0 auto;
}
.cage.imgJar img {
  object-fit: cover;
  width: 100px;
  height: 100px;
}
a, p, span, h1, h2, h3, h4, h5, h6 {
  font-family: 'Noto Sans', sans-serif;
}

a {
  color: #23527c;
}
.m-right-10{
  margin-right: 10px;
}
.m-top-5 {
  margin-top: 5px;
}
.m-top-0 {
  margin-top: 0;
}

.m-left-15 {
  margin-left: 15px;
}

.m-top-10 {
  margin-top: 10px !important;
}

.m-top-15 {
  margin-top: 15px;
}

.m-top-20 {
  margin-top: 20px;
}
.m-top-30 {
  margin-top: 30px !important;
}

.m-bot-20 {
  margin-bottom: 20px;
}

.m-bot-10 {
  margin-bottom: 20px;
}

.m-top-50 {
  margin-top: 50px;
}
.m-top-100 {
  margin-top: 100px;
}
.m-left-20{
  margin-left: 20px;
}
.m-top-60 {
  margin-top: 60px;
}
.m-top-80 {
  margin-top: 80px;
}

.pl-0 {
  padding-left: 0;
}

.m-top-8 {
  margin-top: 8px !important;
}

body {
  position: relative !important;
  margin: 0;
  min-height: 100% !important;
  padding-bottom: 50px !important;
  background: rgba(246, 246, 246, 0.49);
}
.intro-account{
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
    height: 42px;
  }
}

li.divider {
  border-top: 1px solid #e4e4e4;
}

.newsfeed-post {
  margin-top: 20px;
}
.header {
  background-color: #FFF;
  border-bottom: 1px solid #D7D7D7;
  height: 59px;
}

.m-top-60 {
  margin-top: 60px;
}

.content-box {
  background-color: #FFF;
  border: none;
  margin: 0 auto 5px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.left-sidebar-info .panel{
    margin-right: 5px;
    &.ifry.fRequest {
    padding: 0;
  }
}



.blogging {
  .content-box {
    margin: 0 auto 5px;
  }
}

.container-fluid {
  padding: 0 15px;
}
span.posted-time, p.posted-time{
  font-size: 14px;
}
/*~~~~~~~~ fit Image into Frame ~~~~~~~*/

.picFrame {
  overflow: hidden;
  position: relative;
}

.newsfeed-post{
  .single-page{
    .post-header{
      img{
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
    }
  }
}
/*~~~~~~~~ /fit Image into Frame ~~~~~~~~~~*/

.picFrame img {
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  left: 50%;
  /* max-width: 150%; */
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

/*--------- GRID --------------------------------------------------------*/

[class*=grid-] {
  position: relative;
  width: 100%;
  font-size: 0;
  margin: 0;
  padding: 0;
}

[class*=grid-] .cell {
  position: relative;
  display: inline-block !important;
  min-height: 10px;
  font-size: 14px;
  margin-right: -4px;
  /* safari */
  -webkit-transition: all .4s ease;
  -moz-transition: all .4s ease;
  -o-transition: all .4s ease;
  transition: all .4s ease;
}

.grid-1 .cell {
  width: 100%;
}

.grid-2 .cell {
  width: 49.25%;
  margin-right: 1.5%;
  width: 48.8% \9
;
  /* IE8-8 */
  margin: 0 0.6% \9
;
  /* IE8-8 */
  margin-right: 1.4%;
  /* safari */
}

.grid-3 .cell {
  width: 32.33333333333333%;
  margin-right: 1.5%;
  width: 32% \9
;
  /* IE8-8 */
  margin: 0 0.65% \9
;
  /* IE8-8 */
  margin-right: 1.3%;
  /* safari */
}

.grid-4 .cell {
  width: 24.25%;
  margin-right: 1%;
  width: 24% \9
;
  /* IE8-8 */
  margin: 0 0.5% \9
;
  /* IE8-8 */
  margin-right: 0.8%;
  /* safari */
}

.grid-5 .cell {
  width: 19.2%;
  margin-right: 1%;
  width: 19% \9
;
  /* IE8-8 */
  margin: 0 0.5% \9
;
  /* IE8-8 */
  margin-right: 0.9%;
  /* safari */
}

.grid-6 .cell {
  width: 16%;
  margin-right: 0.8%;
  width: 16% \9
;
  /* IE8-8 */
  margin: 0 0.3% \9
;
  /* IE8-8 */
  margin-right: 0.6%;
  /* safari */
}

.grid-2 .cell:nth-child(2n+2),
.grid-3 .cell:nth-child(3n+3),
.grid-4 .cell:nth-child(4n+4),
.grid-5 .cell:nth-child(5n+5),
.grid-6 .cell:nth-child(6n+6) {
  margin-right: 0;
}

/*safari Selector Hacks*/

_::-moz-svg-foreign-content,
:root .grid-2 .cell:nth-child(2n+2),
_::-moz-svg-foreign-content,
:root .grid-3 .cell:nth-child(3n+3),
_::-moz-svg-foreign-content,
:root .grid-4 .cell:nth-child(4n+4),
_::-moz-svg-foreign-content,
:root .grid-5 .cell:nth-child(5n+5),
_::-moz-svg-foreign-content,
:root .grid-6 .cell:nth-child(6n+6) {
  margin-right: -4px;
}

/*~~~~ add .bind or .bind-all for remove margin from .cell ~~~*/

.grid-2[class*="bind"] .cell {
  width: 50%;
}

.grid-3[class*="bind"] .cell {
  width: 33.33333333333333%;
}

.grid-4[class*="bind"] .cell {
  width: 25%;
}

.grid-5[class*="bind"] .cell {
  width: 20%;
}

.grid-6[class*="bind"] .cell {
  width: 16.666666666666664%;
}

[class*="grid-"].bind .cell {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

[class*="grid-"].bind-all .cell {
  margin: 0 !important;
}

@media (max-width: 767px) {
  [class*=grid-] .cell,
  [class*=grid-].bind .cell {
    width: 100%;
    margin-right: 0 !important;
  }
  [class*=grid-].bind-all .cell {
    width: 100%;
    margin: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .grid-4 .cell,
  .grid-5 .cell,
  .grid-6 .cell {
    width: 32.33333333333333%;
    margin-right: 1.5% !important;
    width: 32% \9
  ;
    /* IE8-8 */
    margin: 0 0.65% \9
  ;
    /* IE8-8 */
    margin-right: 1.3%;
    /* safari */
  }
  .grid-4[class*="bind"] .cell,
  .grid-5[class*="bind"] .cell,
  .grid-6[class*="bind"] .cell {
    width: 33.33333333333333%;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .grid-4 .cell:nth-child(3n+3),
  .grid-5 .cell:nth-child(3n+3),
  .grid-6 .cell:nth-child(3n+3) {
    margin-right: 0 !important;
  }
  /*safari Selector Hacks*/
  _::-moz-svg-foreign-content,
  :root .grid-4 .cell:nth-child(3n+3),
  _::-moz-svg-foreign-content,
  :root .grid-5 .cell:nth-child(3n+3),
  _::-moz-svg-foreign-content,
  :root .grid-6 .cell:nth-child(3n+3) {
    margin-right: -4px !important;
  }
}

/*........ temp style ........*/

[class*=grid-].sample .cell {
  /*min-height: 100px;*/
  min-width: 100px;
  margin-bottom: 12px !important;
  vertical-align: bottom;
  background: #FEFEFE;
  border: 1px solid #ccc \9
;
  /* IE8-8 */
  box-shadow: 0 1px 2px rgba(34, 25, 25, 0.4);
}

/*........ /temp style ........*/

/*--------- Showcase ---------*/

.showcase {
  margin: 10px 0;
  padding: 0;
}

.showcase header,
.showcase footer {
  min-height: 20px;
  padding: 5px 15px;
  background-color: #FFFFFF;
  border: 1px solid #ccc;
}

.showcase,
.showcase header,
.showcase footer {
  position: relative;
  width: 100%;
}

.showcase header {
  margin-bottom: 12px;
}

.showcase header > h1 {
  display: inline-block;
  font-size: 18px;
  margin: 7px 0;
}

.showcase article,
.showcase .article {
  width: 100%;
  padding: 0;
  font-size: 14px;
}

.showcase.list article,
.showcase.list .article {
  width: 60%;
  height: 136px;
  padding: 0 0 0 10px;
}

.showcase .branch {
  background: #FFF;
  /*font-size: 14px;*/
  margin-bottom: 15px;
  padding-bottom: 15px;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.showcase .branch .title {
  display: block;
  width: 100%;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 10px;
}

.showcase .branch .title .posted-by {
  display: inline-block;
  font-size: 60%;
  padding-left: 10px;
  color: #8C8C8C;
}

.showcase .branch .title a.posted-by:hover {
  color: #0080C0;
}

.showcase .branch .action .btn {
  font-family: 'Playball', cursive;
  border-radius: 0;
  font-size: 13px;
  border-color: transparent;
  color: #fff;
  padding: 2px 20px;
  -webkit-transition: all .4s ease;
  -moz-transition: all .4s ease;
  -o-transition: all .4s ease;
  transition: all .4s ease;
}

.thumb-type .showcase .branch {
  text-decoration: none;
  color: #7A7A7A;
  cursor: pointer;
  border: none;
  margin-bottom: 1px !important;
  border-bottom: 1px solid #D5D3D3;
}

/* Social media design */

.like-area > div {
  position: absolute;
  top: 18px;
  -webkit-transform: scale(4);
  -ms-transform: scale(4);
  -o-transform: scale(4);
  transform: scale(1.5);
}

/* Font Sizer Plugin CSS Style */

.font-sizer {
  border-bottom: 1px solid #d8caca;
  border-left: 1px solid #d8caca;
  border-radius: 0 0 0 5px;
  position: absolute;
  right: -1px;
  top: 0;
}

.font-sizer span {
  background-color: #e8ebee;
  color: #757474;
  cursor: pointer;
  display: block;
  float: left;
  height: 25px;
  text-align: center;
  width: 25px;
}

.font-sizer span:first-child {
  border-radius: 0 0 0 5px;
  border-right: 1px solid #d8caca;
  font-size: 18px;
}

.font-sizer span:last-child {
  font-size: 12px;
  padding-top: 4px;
}

/*==========================================*/

.sectionHeader,
.sectionHeader > span,
.sectionHeader > span a {
  font-family: 'Noto Sans', sans-serif !important;
}

.sectionHeader {
  line-height: 40px;
}

.sectionHeader > span {
  position: relative;
  top: 1px;
  height: 31px;
}

.sectionHeader > span a {
  position: relative;
  text-decoration: none;
}

.sectionHeader > span:not(:first-child) {
  margin-left: 10px;
}

.sectionHeader > span:not(:first-child) a {
  top: -1px;
  color: #756E6A;
  font-size: 16px;
  padding-left: 10px;
  text-decoration: none;
  line-height: 43px;
  margin-top: -20px;
}

.sectionHeader > span a:hover {
  color: #9B4316;
}

.sectionHeader span:first-child a {
  color: #805D4C;
  font-size: 24px;
  top: -1px;
  padding-left: 0;
}

.sectionHeader > span:not(:first-child):before,
.sectionHeader > span:not(:first-child):after {
  position: absolute;
  content: '';
  left: 0;
  height: 9px;
  width: 1px;
  background-color: #805D4C;
}

.sectionHeader > span:not(:first-child):before {
  top: 6px;
  -ms-transform: rotate(-35deg);
  /* IE 9 */
  -webkit-transform: rotate(-35deg);
  /* Chrome, Safari, Opera */
  transform: rotate(-35deg);
}

.sectionHeader > span:not(:first-child):after {
  bottom: 12px;
  -ms-transform: rotate(35deg);
  /* IE 9 */
  -webkit-transform: rotate(35deg);
  /* Chrome, Safari, Opera */
  transform: rotate(35deg);
}

* {
  font-family: 'Noto Sans', sans-serif;
}

.row.wrap {
  background-color: #F6F6F6;
}

.content-box {
  padding-bottom: 30px;
  background-color: transparent;
}

.sectionHeader {
  margin-top: 0;
  display: block;
  li + li {
    float: left;
    list-style: none;
    position: relative;
    margin-right: 30px;
    &:before {
      content: "\f105";
      font-family: FontAwesome, serif;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      color: #fff;
      font-size: 18px;
      padding-right: 0.5em;
      position: absolute;
      top: 0;
      right: -25px;
    }
  }
  li {
    float: left;
    list-style: none;
    position: relative;
    margin-right: 30px;
    margin-top: 0;
    &:not(:last-child):after {
      content: "\f105";
      font-family: FontAwesome, serif;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      color: #fff;
      font-size: 18px;
      padding-right: 0.5em;
      position: absolute;
      top: 0;
      right: -25px;
    }
  }
  a {
    color: white;
    position: relative;
  }
}

.feature-category a {
  padding: 5px 7px;
  color: #363636;
  font-size: 14px;
  font-weight: bold;
  margin-right: 2px;
  line-height: 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 3px;
  text-decoration: none;
  text-transform: uppercase;
}

.feature-category.topic a {
  background-color: #fff;
}

.feature-category a:hover,
.feature-category a.active {
  background-color: #b44e1a;
  color: white;
}

/*........................................*/

.thumb-type .showcase .branch {
  border: 1px solid #D5D3D3;
  border-bottom-width: 0;
  margin-bottom: 0 !important;
}

.thumb-type .showcase .branch:last-child {
  border-bottom-width: 1px;
}

.showcase header {
  background-color: #DEDEDE;
  border-color: #DEDEDE;
  color: #494949;
  font-size: 16px;
  padding: 5px 10px;
  margin-bottom: 10px;
}

.showcase header.food {
  background-color: #9B4316;
  border-color: #9B4316;
  color: white;
}

.showcase header.health {
  background-color: #34B772;
  border-color: #34B772;
  color: white;
}

.showcase header.lifestyle {
  background-color: #353535;
  border-color: #353535;
  color: white;
}

.showcase header.beauty {
  background-color: #dd5a7b;
  border-color: #dd5a7b;
  color: white;
}

.showcase header.travel {
  background-color: #A58B35;
  border-color: #A58B35;
  color: white;
}

.showcase header.technology {
  background-color: #2a839d;
  border-color: #2a839d;
  color: white;
}

.showcase header.sports {
  background-color: #428022;
  border-color: #428022;
  color: white;
}

.showcase header.entertainment {
  background-color: #1e213c;
  border-color: #1e213c;
  color: white;
}

.showcase.uMayLike header {
  margin-top: 10px;
}

.showcase.uMayLike [class*=grid-] {
  margin-bottom: 12px;
}

.showcase.uMayLike figure,
.showcase.uMayLike .figure {
  height: 150px;
}

.details-type .showcase.uMayLike .branch .title {
  font-size: 16px;
}

.post-status {
  margin-top: 50px;
  font-size: 16px;
}

.post-status > div {
  margin-bottom: 15px;
}

.post-status > div a {
  padding: 4px 5px;
  background-color: #D2D6DE;
  color: #545353;
  font-size: 14px;
  margin-right: 3px;
  line-height: 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.post-status > div a:hover {
  background-color: #9B4316;
  color: white;
}

.shop-bost-1 .product-category {
  position: absolute;
  bottom: 25px;
  width: 100%;
}

.shop-bost-1 .product-category a {
  width: 28%;
  overflow: hidden;
  float: left;
  margin-left: 3.2%;
  border: 2px solid #fff;
  -webkit-box-shadow: 2px 2px 9px #000;
  -moz-box-shadow: 2px 2px 9px #000;
  box-shadow: 2px 2px 9px #000;
}

.shop-bost-1 .product-category a:hover {
  -webkit-box-shadow: 3px 3px 14px #000;
  -moz-box-shadow: 3px 3px 14px #000;
  box-shadow: 3px 3px 14px #000;
}

.shop-bost-1 .product-category a img {
  /*width: 100%;*/
}

.shop-bost-2 {
  width: 100%;
  overflow: hidden;
}

.shop-bost-2 .panel-heading {
  position: relative;
}

.shop-bost-1 .inner-header .logo,
.shop-bost-2 .panel-heading .logo {
  width: 50px;
}

.shop-bost-1 .inner-header .subname,
.shop-bost-2 .panel-heading .subname {
  position: absolute;
  left: 67px;
  top: 12px;
  color: black;
  font-size: 17px;
  -moz-text-shadow: 2px 2px 9px #000;
  -webkit-text-shadow: 2px 2px 9px #000;
  /* text-shadow: 2px 2px 9px #000; */
}

.shop-bost-2 .product-category {
  position: relative;
}

.shop-bost-2 .product-category a {
  position: relative;
  width: 48%;
  display: inline-block;
  float: left;
  border: 2px solid #fff;
  -webkit-box-shadow: 2px 2px 9px #000;
  -moz-box-shadow: 2px 2px 9px #000;
  box-shadow: 2px 2px 9px #000;
}

.shop-bost-2 .product-category a:not(:first-child) {
  margin-left: 3%;
}

.for-pagi {
  text-align: center;
}

.pagination {
  margin: 0;
}

.pagination > li > a,
.pagination > li > span {
  font-size: 15px;
  padding: 7px 14px;
  margin-left: 3px;
  background-color: #fff;
  color: #0081d5;
  border: 1px solid #0081d5;
}

h4.title {
  color: #333333;
  font-size: 16px;
  text-transform: uppercase;
}

.single-page {
  p {
    font-size: 16px !important;
    line-height: 16*1.6px !important;
  }
}

.sub-nav-responsive {
  background: white;
}

p.blog-post {
  font-family: 'Lora', serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #888;
  margin: 0 0 10px 0;
}

// Comments
.comments-area {
  margin-bottom: 50px;
  margin-top: 80px;
  padding: 50px;
}

.comments-box {
  .comments-area {
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
  }
}

.comment-list,
.comment-list .comment > ol.children {
  list-style: none;
  margin: 0;
  padding: 0;
}

.comment-reply-title,
.comments-title {
  margin-bottom: 50px;
}

.divider {
  display: block;
  overflow: hidden;
  text-align: center;
  .divider-content {
    position: relative;
    display: inline-block;
  }
}

.divider-content:before {
  left: 100%;
  margin-left: 20px;
}

.divider-content:after,
.divider-content:before {
  content: "";
  height: 1px;
  width: 9999%;
  border-bottom: 1px solid #dddddd;
  position: absolute;
  top: 50%;
  display: block;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.divider-content:after {
  right: 100%;
  margin-right: 20px;
}

.divider h2,
.divider h3 {
  margin-bottom: 0;
  margin-top: 0;
}

.comment-list,
.comment-list .comment > ol.children {
  list-style: none;
}

.comment-list > .comment:first-child {
  border-top: none;
}

.comment-list .comment-body {
  padding: 20px 0;
}

.comment-body .comment-author {
  float: left;
  width: 70px;
  height: 70px;
  overflow: hidden;
  img {
    max-width: 100%;
    height: auto;
  }
}

.comment-body .comment-content {
  display: block;
  padding-left: 90px;
}

.comment-body .author-name {
  font-weight: normal;
  margin-bottom: 0;
}

.comment-body .author-name a {
  color: inherit;
}

.comment-body .author-name a:hover {
  color: #f8b5c9;
}

.comment-body .reply {
  float: right;
}

.comment-body .comment-text {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
}

.comment-body .comment-text p:last-child {
  margin-bottom: 0;
}

.date-comment {
  font-size: 12px;
  color: #999999;
  display: inline-block;
}

.date-comment a {
  color: inherit;
}

.date-comment a:hover {
  color: #f8b5c9;
}

.comment-awaiting-moderation {
  display: block;
}

.comment-form textarea,
.comment-form .input-form {
  margin-bottom: 30px;
  z-index: auto;
  position: relative;
}

input[type="text"],
input[type="password"],
input[type="email"],
textarea {
  border: 1px solid #dddddd;
  width: 100%;
  color: #999999;
  background: #ffffff;
  line-height: 24px;
  padding: 10px 15px;
}

p.form-submit {
  margin-left: 15px;
  input {
    background: #f8b5c9;
    font-size: 10px;
    border: 1px solid #f8b5c9;
    color: #ffffff;
    text-transform: uppercase;
    display: inline-block;
    letter-spacing: 2px;
    line-height: 24px;
    min-width: inherit;
    padding: 10px 20px;
    text-align: center;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    font-weight: bold
  }
}

.home-nav li {
  display: inline-block !important;
}

.slide-banner {
  position: relative;
  text-align: center;
  .slide-banner-image {
    width: 100%;
    max-height: 250px;
    overflow-y: hidden;
    img{
      width: 100%;
      object-fit: cover;
    }
  }
  .caption {
    position: absolute;
    top: 50%;
    width: 400px;
    background: rgba(255, 255, 255, 0.85);
    padding: 40px;
    left: 50%;
    transform: translate(-50%, -50%);
    h2 {
      font-size: 20px;
    }
    p {
      text-align: center;
    }
  }
}

.sectionHeader {
  position: relative;
  background-color: transparent;
  color: #437277;
  font-size: 20px;
  padding: 0;
  margin-bottom: 0;
  height: auto;
}

.search-form-nav {
  margin-top: 3px;
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.sectionHeader,
.sectionHeader > span,
.sectionHeader > span a,
.sectionHeader span:first-child a,
.sectionHeader > span:not(:first-child) a:hover {
  color: #437277;
  font-size: 14px !important;
  height: auto;
}

.full-blue {
  padding-left: 30px;
}

/* MEGAMENU STYLE
-------------------------------------------------- */

.megamenu .nav,
.megamenu .collapse,
.megamenu .dropup,
.megamenu .dropdown {
  position: static;
}

.megamenu .container {
  position: relative;
}

.megamenu .dropdown-menu {
  left: auto;
}

.megamenu .megamenu-content {
  padding: 15px;
}

.megamenu .megamenu-content h3 {
  margin-top: 0;
  color: #333333;
  font-size: 18px;
  font-weight: bold;
}

.megamenu .dropdown.megamenu-fw .dropdown-menu {
  left: 0;
  right: 0;
}

.header-logobar1 {
  background: no-repeat left top;
  background-size: contain;
  float: left;
  position: relative;
  width: 100%;
}

.header-logobar1 .logo {
  margin: 65px 0 5px;
  width: 230px;
  text-align: left;
  padding-left: 30px;
}

.logo > a {
  width: 100%;
  display: block;
}

.logo > a > strong {
  color: #252525;
  font-size: 26px;
  font-weight: normal;
  letter-spacing: -2px;
  line-height: 30px;
}

.logo > a > span {
  color: #898989;
  display: inherit;
  font-size: 13px;
}

.megamenu-content ul.media-list.row li {
  margin-bottom: 20px;
  text-align: left;
  i {
    left: -20px;
    position: relative;
    top: 5px;
    font-size: 30px;
    color: #7f8c8d;
  }
  p {
    margin: 0 0 0 30px;
    width: calc(100% - 30px);
  }
  span {
    font-weight: bold;
    font-size: 15px;
    display: inline-block;
    margin-bottom: 10px;
  }
  a {
    color: #333333;
  }
}

header.main-header {
  height: 52px;
}

.main-header {
  top: 0;
}

.header-logobar1 {
  background: white;
}

.full-blue {
  background: rgb(0, 129, 213);
}

.navbar-default .navbar-nav > li.dropdown:hover > a,
.navbar-default .navbar-nav > li.dropdown:hover > a:hover,
.navbar-default .navbar-nav > li.dropdown:hover > a:focus {
  background-color: initial !important;
  color: rgb(85, 85, 85);
}

.carousel {
  .item {
    height: 230px;
    overflow-y: hidden;
  }
}

.search-section {
  background-color: #fff;
  margin-bottom: 40px;
}

.slide-banner {
  margin-bottom: initial;
}

.search-section .tb {
  padding: 50px 0;
  max-width: 1000px;
  margin: auto;
}

.tb {
  display: table;
  width: 100%;
}

.search-section .tb .tb-cell {
  width: 50%;
}

.tb-cell {
  display: table-cell;
  vertical-align: middle;
}

.search-section .tb .tb-cell .text-content {
  padding: 0 15px;
}

.search-section .tb .tb-cell h2 {
  font-size: 20px;
  margin-top: 0;
  text-transform: uppercase;
}

.search-section .tb .tb-cell p {
  margin-bottom: 0;
}

.search-section .tb .tb-cell .search-box {
  padding: 0 15px;
}

.search-section .tb .tb-cell .search-box form {
  position: relative;
}

.search-section .tb .tb-cell .search-box .form-item input {
  width: 100%;
  background-color: #fff;
  padding-right: 110px;
}

input[type="search"],
input[type="text"],
input[type="url"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="file"] {
  background: #fff none;
  border: 1px solid #e7e7e7;
  height: 34px;
  padding: 0 15px;
  color: #666666;
  font-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.search-section .tb .tb-cell .search-box .form-actions {
  position: absolute;
  top: 0;
  right: 0;
}

.search-box .form-actions input,
.pi-btn {
  display: inline-block;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  font-weight: 700;
  color: #fff;
  border: 0;
  background: #c0392b none;
  text-align: center;
  text-transform: uppercase;
  padding: 0 22px;
  line-height: 42px;
  height: 42px;
  letter-spacing: 0.1em;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.form-item {
  display: inline-block;
}

.form-actions {
  display: inline-block;
  margin-left: -4px;
}

.search-box {
  margin-top: 63px;
  margin-right: 20px;
}

.shape-shifter {
  margin-top: 0 !important;
}

.gossip-activity .gossip-search {
  input {
    position: absolute !important;
    top: -5px !important;
    padding: 0 !important;
    background: #DFDFDF !important;
  }
}

.bottom-stiky-bar i {
  font-size: 18px;
}

.bottom-stiky-bar .notify {
  top: -2px !important;
  left: 35px !important;
  position: absolute;
}

.category-nav {
  padding-top: 10px;
  margin-bottom: 10px;
  background: #b0dbb2;
  padding-bottom: 10px;
  li.item {
    list-style: none;
    margin-right: 15px;
    display: inline-block;
    a {
      font-weight: bold;
      color: #000;
    }
  }
}

ul.dropdown-menu.megamenu-content a {
  text-decoration: none;
  &:hover {
    opacity: .8;
  }
}

li {
  list-style: none;
}

.fixed {
  position: fixed;
  bottom: 00px;
  width: 400px;
}

.navbar-header {
  display: inline-block;
  float: left;
}

ul.nav.navbar-nav.navbar-right.top-nav-user {
  float: right;
  li {
    float: left;
  }
}

li.search-form-nav {
  outline: 0;
  width: 300px;
  margin-top: 13px;
  input {
    height: 34px;
  }
}

.chat-history .writing-area {
  width: 100%
}

.shape-shifter {
  min-height: initial;
}

.subNav {
  float: left;
}

ul.megamenu > li {
  height: 49px;
}

.subNav .navbar-nav li > a {
  padding: 19px 0;
}

.subNav .navbar-nav li a:hover {
  background-color: initial;
}

.sectionHeader li + li:before {
  content: "";
}

.global-gossip {
  z-index: 99;
  top: 58px;
  ul.gossip-body-users {
    height: 90vh;
    overflow-y: auto;
  }
}

.main-header {
  z-index: 999;
}

.user-tips-wrap {
  z-index: 9999999;
}

.chat-list-area.col-xs-3.col-md-4 {
  padding: 0;
}

ul.nav.navbar-nav.megamenu {
  margin-top: -1px;
}

.gosip-hide {
  position: absolute;
  top: 5px;
  right: 5px;
  button {
    color: white;
  }
}

.chat-header-text {
  font-size: 16px;
  font-weight: bold;
}

.chat-body-header {
  padding: 5px;
  background: #0081d5;
  color: white;
  cursor: pointer;
}

.breadcrumb-nav {
  z-index: 999;
  position: absolute;
  width: 100%;
}

li.dropdown.megamenu-fw > .custom-icon {
  margin-top: 18px;
  margin-left: 10px;
}

li.dropdown.megamenu-fw > a {
  padding-left: 35px;
}

.lang-btn-grp {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  button.btn-lang {
    background: transparent;
    border: 1px solid #0081d5;
    padding: 4px;
    margin-right: -5px;
    margin-top: 10px;
    &.active {
      background: #0081d5;
      color: #fff;
    }
  }
}

.megamenu-content {
  &:before {
    display: none !important;
  }
}

.section-menu.sidebar-left.sidebar.opener {
  width: 55px;
  &:hover {
    width: 200px;
  }
}

.section-menu.sidebar-left.sidebar {
  width: 0;
}

a.sidebar-left-opener {
  position: absolute;
  top: 4px;
  left: 94px;
  font-size: 30px;
  color: #9e4239;
}

.section-menu.sidebar-left.sidebar.opener {
  margin-top: 59px;
}

.personal-tips-bot {
  padding: 5px;
}

.feature-home {
  .arrow-style {
    width: 40px;
    height: 30px;
    &::before {
      left: -46px;
      border-width: 0 0 30px 46px;
    }
    button {
      i {
        padding: 10px 2px;
      }
    }
  }
}

.thumb-type .showcase.list figure,
.thumb-type .showcase.list .figure {
  float: left;
}

.cog-setting-menu {
  li {
    width: 100%;
  }
}

.bottom-stiky-bar ul.nav li.left-arrow-gotomenu {
  width: 50px !important;
  a {
    color: white;
    background: #2e76a5;
    &:hover {
      opacity: .7;
    }
  }
}

.b-item-c-list .b-item {
  margin-bottom: 30px;
}

.b-item-c {
  position: relative;
}

.b-item-c .b-item-details .b-item-description, .b-item-c .b-item-details .b-meta, .b-item-c .b-item-img {
  margin-bottom: 0;
}

.b-item .b-item-img {
  margin-bottom: 5px;
  position: relative;
}

.b-item-c-list .b-item .b-item-img:before {
  padding-top: 58.5%;
}

.b-item .b-item-img:before {
  display: block;
  content: '';
  background: #e1e0e0;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
}

.b-item-c .b-item-img a:before {
  z-index: 9;
  content: " ";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent 50%, rgba(0, 0, 0, .7) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#b3000000', GradientType=0);
}

.b-item .b-item-img img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.b-item-c .b-term {
  margin-bottom: 15px;
}

.b-term-badge {
  border: 1px solid #e74c3c;
  position: absolute;
  background: #e74c3c;
  padding: 10px;
  z-index: 90;
}

.b-item-c:after, .b-term-badge {
  top: 0;
  transition: all .5s ease;
  left: 0;
}

.b-term {
  font-size: 1.4rem;
  font-weight: 700;
}

.b-term-badge a {
  color: #fff;
}

.b-item-c .b-item-details {
  z-index: 12;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
}

.b-item .b-item-title {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.3;
  max-height: 55px;
  overflow: hidden;
}

.b-item-c .b-item-details .b-item-title a {
  font-weight: 400;
  color: #fff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, .65);
}

.b-item .b-item-title a {
  font-family: Oswald, sans-serif;
  color: #2c3e50;
}

.b-item-c .b-item-details .b-item-description, .b-item-c .b-item-details .b-meta, .b-item-c .b-item-details .b-meta-author, .b-item-c .b-item-details .b-meta-date, .b-item-c .b-item-details .b-meta-separator {
  color: #fff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, .65);
}

.b-meta .b-meta-separator {
  font-size: 6px;
  margin: 0 10px;
  vertical-align: middle;
  color: #9e9e9e;
}

//Life STyle

.b-block {
  position: relative;
  margin-bottom: 50px;
}

.b-title {
  font-size: 2.6rem;
  text-transform: uppercase;
  font-weight: 700;
  font-family: Oswald, sans-serif;
  color: #3498db;
  overflow: hidden;
  border-bottom: 1px solid rgba(52, 152, 219, .31);
}

.b-sidebar, .b-title {
  margin-bottom: 20px;
}

.m-top-50 {
  margin-top: 50px;
}

.b-btn-block {
  margin-top: 20px;
  margin-bottom: 30px;
  transition: all .4s ease;
}

.b-btn-block .b-btn {
  font-size: 1.4rem;
  padding: 5px 10px;
  border: 1px solid #3498db;
}

.b-btn:hover {
  border: 1px solid #3498db;
  color: #FFF;
}

.b-btn:hover, .pagination .active a {
  background: #3498db;
}

//Blog

.b-blog-1 .b-item, .b-blog-2 .b-item, .b-blog-4 .b-item {
  margin-bottom: 20px;
}

.feature-home-blog {
  .b-item .b-item-img {
    height: 200px;
  }
  .b-term a {
    color: #f54101;
  }
}

.b-item .b-item-img {
  margin-bottom: 5px;
  position: relative;
}

.b-blog-1 .b-item-img:before, .b-blog-2 .b-item-img:before, .b-blog-3 .b-item-img:before, .b-blog-4 .b-item-img:before, .b-blog-5 .b-item-img:before {
  padding-top: 52.3%;
}

.b-item .b-item-img:before {
  display: block;
  content: '';
  background: #e1e0e0;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
}

.b-item .b-item-img img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.b-error .b-link a, .b-tabs .tab li a, .b-term-badge a:hover, a:focus, a:hover, a:visited {
  text-decoration: none;
}

.b-term {
  font-size: 1.4rem;
  font-weight: 700;
}

.b-table-item, .b-term {
  text-transform: uppercase;
}

.b-term a {
  color: #f5f0f0;
}

.b-blog-1 .b-item .b-item-title, .b-blog-2 .b-item .b-item-title, .b-blog-4 .b-item .b-item-title {
  font-size: 28rem;
  max-height: 30px;
}

.b-item .b-item-title {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.3;
  max-height: 55px;
  overflow: hidden;
}

.b-item .b-item-title a {
  font-family: Oswald, sans-serif;
  color: #2c3e50;
}

.b-meta {
  font-size: 1.2rem;
  margin-bottom: 5px;
  text-transform: none;
}

.b-meta, .b-meta .b-meta-author, .b-meta .b-meta-date, .b-meta .b-meta-share, .b-meta .b-meta-social {
  color: #9e9e9e;
}

.b-item .b-item-description, .b-meta, .b-term a {
  font-family: "Noto Sans", serif;
}

.b-meta, .b-meta .b-meta-author, .b-meta .b-meta-date, .b-meta .b-meta-share, .b-meta .b-meta-social {
  color: #9e9e9e;
}

.b-meta .b-meta-separator {
  font-size: 6px;
  margin: 0 10px;
  vertical-align: middle;
  color: #9e9e9e;
}

.b-blog-1 .b-item .b-item-description, .b-blog-2 .b-item .b-item-description, .b-blog-4 .b-item .b-item-description {
  overflow: hidden;
  max-height: 48px;
}

.b-item .b-item-description {
  color: #2c2c2c;
  margin-bottom: 10px;
}

.b-item-b {
  margin-bottom: 15px;
}

.search-form-nav .input-group {
  width: 100%;
}

//Footer

footer.nb-footer {
  background: #222;
  border-top: 4px solid #0081d5;
  z-index: 9;
}

footer.nb-footer .about {
  margin: 0 auto;
  margin-top: 40px;
  max-width: 1170px;
  text-align: center;
}

footer.nb-footer .about p {
  font-size: 13px;
  color: #999;
  margin-top: 30px;
}

footer.nb-footer .about .social-media {
  margin-top: 15px;
}

footer.nb-footer .about .social-media ul li a {
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 50%;
  font-size: 16px;
  color: #0081d5;
  border: 2px solid rgba(255, 255, 255, 255);
}

footer.nb-footer .about .social-media ul li a:hover {
  background: #0081d5;
  color: #fff;
  border-color: #0081d5;
}

footer.nb-footer .footer-info-single {
  margin-top: 30px;
}

footer.nb-footer .footer-info-single .title {
  color: #aaa;
  text-transform: uppercase;
  font-size: 16px;
  border-left: 4px solid #0081d5;
  padding-left: 5px;
}

footer.nb-footer .footer-info-single ul li a {
  display: block;
  color: #aaa;
  padding: 2px 0;
}

footer.nb-footer .footer-info-single ul li a:hover {
  color: #b78c33;
}

footer.nb-footer .footer-info-single p {
  font-size: 13px;
  line-height: 20px;
  color: #aaa;
}

footer.nb-footer .copyright {
  margin-top: 15px;
  background: #111;
  padding: 7px 0;
  color: #999;
}

footer.nb-footer .copyright p {
  margin: 0;
  padding: 0;
}

.icon-set-desc-img img {
  background: #e3eceb;
  padding: 20px;
  border-radius: 11px;
  max-width: 200px;
}

/*------------------------------------------------------------------
5. Begin Features
------------------------------------------------------------------*/
.features {
  background: #f3fbee;
  padding-bottom: 70px;
}

.features-main {
  margin-top: 20px;
}

.features-item {
  margin-top: 40px;
}

.features-item .feature-book-title {
  margin-top: 0;
  font-size: 25px;
  color: cadetblue;
}

.features-item-icon {
  width: 50px;
  height: 50px;
}

.features-item-icon img, .features-item-icon svg {
  max-width: 50px;
  max-height: 50px;
}

.features-item-left {
  padding-right: 35px;
  text-align: right;
}

.features-item-left .features-item-icon {
  float: right;
}

.features-item-left .features-item-text {
  padding-right: 70px;
}

.features-item-right {
  padding-left: 30px;
  text-align: left;
}

.features-item-right .features-item-icon {
  float: left;
}

.features-item-right .features-item-text {
  padding-left: 70px;
}

.features-image {
  text-align: center;
  margin-top: 30px;
}

.features-image img {
  margin: 0 auto;
}

ul.nav.navbar-nav.navbar-right.top-nav-user {
  margin-right: 0;
}

.search-section {
  margin-top: 20px;
  margin-bottom: 20px;
}

.b-item-images {
  @import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300);

  body {
    font-size: 16px;
  }

  * {
    transition: all 0.5s;
  }

  h1 {
    font-family: 'Noto Sans Condensed', sans-serif;
    text-align: center;
    font-size: 2.5em;
    font-weight: 400;
  }

  .row {
    -moz-column-count: 2;
    -moz-column-gap: 5px;
    -webkit-column-count: 2;
    -webkit-column-gap: 5px;
    column-count: 2;
    column-gap: 5px;
  }

  .row div {
    display: inline-block;
    width: 100%;
  }

  /* Custom, iPhone Retina */
  @media only screen and (min-width: 320px) {
    h1 {
      font-size: 2em;
    }
  }

  /* Extra Small Devices, Phones */
  @media only screen and (min-width: 768px) {
    .row {
      -moz-column-count: 3;
      -moz-column-gap: 5px;
      -webkit-column-count: 3;
      -webkit-column-gap: 5px;
      column-count: 3;
      column-gap: 5px;
    }

    h1 {
      font-size: 2.3em;
    }
  }

  @media only screen and (min-width: 992px) {
    .row {
      -moz-column-count: 4;
      -moz-column-gap: 5px;
      -webkit-column-count: 4;
      -webkit-column-gap: 5px;
      column-count: 4;
      column-gap: 5px;
    }
    h1 {
      font-size: 2.5em;
    }
  }
}

.title-section-main {
  margin-bottom: 10px !important;
}

// Notifications

.notification-header {
  .notification-text {
    font-size: 20px;
  }
  .notification-link {
    color: blue;
    font-size: 12px;
    &:hover {
      opacity: .8;
    }
  }
}

.about-myprofile {
  .edit-icon {
    font-size: 22px;
    cursor: pointer;
  }
}

// friend-list-page

.friend-list-page {
  .navbar{
    margin-bottom: 10px;
  }
  a {
    font-size: 17px;
    font-weight: bold;
  }
  .media:first-child {
    margin-top: 15px;
  }
  .media:last-child {
    margin-bottom: 20px;
  }
  li.media {
    width: calc( 50% - 10px);
    overflow: initial;
    padding: 15px;
    border: 1px solid rgba(0,129,213,0.19);
    float: left;
    margin-right: 10px;
    margin-bottom: 0px;
  }
}

// Profile Blog

/* Post Tabs */
.post-tabs {
  float: left;
  width: 100%;
}

.post-tabs ul.nav {
  border: medium none;
  float: left;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.post-tabs ul.nav > li {
  border: medium none;
  float: left;
  margin: 0;
  padding: 0;
}

.post-tabs .nav > li > a {
  background: #dcdcdc none repeat scroll 0 0;
  border: medium none !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  color: #fff;
  float: left;
  padding: 8px 5px;
  &.active {
    background-color: #ff2929;
  }
}

.post-tabs .nav > li:hover > a, .post-tabs .nav > li.active > a {
  color: #ffffff;
}

.mini-post-thumb, .mini-post {
  img {
    width: 35px;
    height: 35px;
  }
}

.mini-post-info > h3 {
  color: #1d1d1d;
  float: left;
  font-family: Roboto;
  font-size: 17px;
  font-weight: normal;
  letter-spacing: 0.3px;
  line-height: 22px;
  margin: 0;
  width: 100%;
}

.mini-post {
  display: table;
  float: left;
  margin-top: 8px;
  width: 100%;
  border-bottom: 1px solid #e3e3e3;
}
.my-post-list-view .pagination {
  margin-left: 20px;
  margin-bottom: 15px;
}
.mini-post-thumb {
  display: table-cell;
  padding-right: 10px;
}

.mini-post-thumb > a {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  float: left;
  overflow: hidden;
}

.mini-post-info {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  font-size: 13px;
}

.mini-post-info > h3 a {
  color: #1d1d1d;
}

.quick-metas {
  float: left;
  margin-top: 5px;
  width: 100%;
}

.quick-metas > ul {
  float: left;
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.quick-metas > ul > li {
  float: left;
  font-size: 12px;
  padding-right: 0;
  position: relative;
}

.quick-metas > ul > li > a {
  color: #818181;
  display: inline-block;
  font-size: 12px;
  margin: 0 2px;
  letter-spacing: 0.2px;
}

.quick-metas > ul > li > a > i {
  margin-right: 8px;
}

.post-tabs {
  float: left;
  width: 100%;
}

.post-tabs ul.nav {
  border: medium none;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.post-tabs ul.nav > li {
  border: medium none;
  margin: 0;
  padding: 0;
  a {
    background: none !important;
    color: #293840 !important;
    border: 1px solid !important;
    border-radius: 5px;
    &:hover, &:focus {
      background: #1c6955 !important;
      opacity: .8;
      color: white !important;
      transition: all .3s;
    }
  }
}

.forMyPostPage .left-column {
  margin-bottom: 15px;
  margin-left: -15px;
  margin-right: -15px;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
  border: none;
  border-bottom-color: initial;
}

.user-post-interact {
  padding: 15px;
  margin-left: -10px;
  margin-right: -10px;
  background: whitesmoke;
  a {
    margin-right: 10px;
  }
}

.continue-reading {
  color: #66a6ff;
}

.faq-category-row {
  display: inline-block;
  width: 100%;
  position: relative;
  > i {
    position: absolute;
    right: 10px;
    top: 5px;
    color: black;
    font-size: 20px;
  }
  .faq {
    width: 100%;
    cursor: pointer;
    margin-bottom: 10px;
  }
  .question {
    color: black;
    line-height: 30px;
    background: #fff;
    padding: 0 0 0 20px;
    background-size: 100%;
    border-radius: 1em;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  .question:after {
    content: "";
    display: table;
    clear: both;
  }
  .answer {
    margin: 0 auto;
    background: white;
    background-image: -moz-linear-gradient(#ffffff, #f4f8ff);
    label input[type="checkbox"] ~ i.fa.fa-square-o {
      color: #c8c8c8;
      display: inline;
    }
    label input[type="checkbox"] ~ i.fa.fa-check-square-o {
      display: none;
    }
    label input[type="checkbox"]:checked ~ i.fa.fa-square-o {
      display: none;
    }
    label input[type="checkbox"]:checked ~ i.fa.fa-check-square-o {
      color: #333333;
      display: inline;
    }
    label:hover input[type="checkbox"] ~ i.fa {
      color: #333333;
    }

    div[data-toggle="buttons"] label.active {
      color: #333333;
    }

    div[data-toggle="buttons"] label {
      display: inline-block;
      padding: 5px 12px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: normal;
      line-height: 2em;
      text-align: left;
      white-space: nowrap;
      vertical-align: top;
      cursor: pointer;
      background-color: none;
      border: 0px solid #c8c8c8;
      border-radius: 3px;
      color: #333333;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
    }

    div[data-toggle="buttons"] label:hover {
      color: #333333;
    }

    div[data-toggle="buttons"] label:active, div[data-toggle="buttons"] label.active {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    h4.cat-title {
      margin: 0;
      font-weight: bold;
    }
    hr {
      margin: 0;
    }
  }
}

.header .navbar-nav .loged-user .imgPot {
  border-radius: 50%;
}

.cog-sett-dd {
  border: 1px solid #0081d5;
  border-bottom: 0;
  padding: 0;
  border-top-width: 1px !important;
  li a {
    width: 100%;
    border-bottom: 1px solid #0081d5;
    padding: 10px;
    &:hover {
      color: white;
      background: #0081d5;
      transition: all .3s;
    }
  }
}

.dropdown-menu.cog-sett-dd:before {
  border-color: #0081d5 transparent;
}

#editor-container {
  height: 100px;
}

#editor-container-blog {
  height: 250px;
  margin-bottom: 20px;
}

.header .navbar-nav > li > a {
  padding: 16px 5px;
}

.customized-search-icons a {
  padding: 13px 5px !important;
}

.main-home-layout {
  margin-top: 70px;
}

.top {
  background-color: #123;
  position: relative;
  display: block;
  width: 100%;
  height: 30px;
}

i {
  font-size: 25px;
}

.customized-search {
  form {
    display: none;
    background: #2980b9;
    padding: 10px 10px 0;
    text-align: center;
    position: relative;
    transition: background-color 500ms linear;
    top: 52px;
  }
  input.sb {
    border: none;
    padding: 10px 0;
    background: none;
    color: white;
    outline: none;
    border-bottom: 1px solid white;
    width: 80%;
    font-size: 18px;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 300;
  }
  button {
    background: none;
    border: none;
  }
  input.sb::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 300;
  }
  .sbtn {
    font-size: 24px;
    color: white;
  }
}

.customized-search-icons {
  .btn-search-icon {
    color: white;
    i {
      color: #777;
      font-size: 22px;
    }
  }
}

.customizable-check {
  .checkbox, .radio {
    display: inline-block;
  }
  label {
    color: white;
    font-size: 16px;
  }
}

.shortcut-links {
  padding: 0;
  ul {
    padding: 0;
    li {
      display: block;
      margin-bottom: 10px;
      a {
        font-size: 14px;
      }
    }
  }
  i {
    font-size: 22px;
    margin-right: 20px;
  }
}

.fRequest {
  padding-bottom: 30px;
}

.friend-suggestion {
  border: 1px solid #eee9fd;
  padding: 10px;
  &:not(:last-child){
    margin-bottom: -16px;
  }
  .friend-suggestion-profile img {
    width: 45px;
    border-radius: 50%;
    height: 45px;
  }
  .add-request {
    font-size: 11px;
    background: #0081d5;
    color: white;
  }
  .Education {
    margin-bottom: 6px;
  }
}

.birthday-post-wall {
  .birthday-post-wall-input {
    padding: 15px;
    margin: 20px 0 10px;
  }
}

.navbar-brand {
  padding: 11px 15px;
}

.friends-req-area {
  .btn {
    color: #fff;
    background-color: #0B7ED4;
    border-color: #0B7ED4;
  }
}

.dropdown-profile {
  .dropdown-menu {
    li {
      width: 100%;
    }
  }
}

.customized-search {
  margin-left: -15px;
  margin-right: -15px;
}

.header .navbar-nav > li > a {
  margin-left: 5px;
}

span.notification {
  color: #0b7ed4;
}

.social-nav ul li a:hover {
  color: rgba(255, 255, 255, 0.87);
}

li.dropdown.friends-note.open .title {
  font-size: 17px;
  text-align: center;
}

a.dropdown-toggle.notify-on {
  &:focus {
    background: #2e76a5 !important;
  }
}

.message-note {
  span.name, span.message-number {
    display: inline-block !important;
  }
}

.message-body-full-css {
  padding: 0 !important;
  position: absolute;
  z-index: 9999;
  background: white !important;
  height: 100vh;
  margin-right: -15px;
  .collapse-message {
    position: fixed;
    top: 52px;
    right: 0;
    left: 0;
    z-index: 999;
  }
  .lft {
    width: calc(100% - 60px);
    background: rgba(51, 135, 189, 0.92);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  }
  .rgt #dropdown {
    border-left: 4px solid #739cda;
    -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
     -webkit-transition: all 0.15s linear;
    -moz-transition: all 0.15s linear;
    -ms-transition: all 0.15s linear;
    -o-transition: all 0.15s linear;
    transition: all 0.15s linear;
    line-height: initial !important;
    background: white;
  }
  .rgt #dropdown {
    padding: 7px 16px;
    background: rgba(51, 135, 189, 0.92);
    i{
      font-size: 24px !important;
      line-height: 22px;
      margin-left: 10px;
    }
  }
  span.name {
    font-size: 22px !important;
    margin-top: 8px !important;
    text-align: center !important;
    color: white !important;
  }
  span.mutual, .suggested, span.message-number, .profile-image-chat-user {
    display: none !important;
  }
  .cancel-chat-header {
    display: block !important;
    i {
      font-size: 30px;
      line-height: 19px;
      color: white;
      margin-left: 15px;
    }
  }
  .title-notification{
    display: none;
  }
}


#cart-request {
  $main-color: #6394F8;
  $light-text: #ABB0BE;
  *, *:before, *:after {
    box-sizing: border-box;
  }
  .lighter-text {
    color: #ABB0BE;
  }
  .main-color-text {
    color: $main-color;
  }
  nav {
    padding: 20px 0 40px 0;
    background: #F8F8F8;
    font-size: 16px;

    .navbar-left {
      float: left;
    }

    .navbar-right {
      float: right;
    }
    .shopping-cart-items li {
      background: white;
    }
    ul {
      li {
        display: inline;
        padding-left: 20px;
        background: white;
        float: initial;
        a {
          color: #777777;
          text-decoration: none;

          &:hover {
            color: black;
          }
        }
      }
    }
  }

  .container {
    margin: auto;
  }

  .badge {
    background-color: #6394F8;
    border-radius: 10px;
    color: white;
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    padding: 3px 7px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
  }

  .shopping-cart {
    float: right;
    background: white;
    width: 100%;
    position: relative;
    border-radius: 3px;
    .shopping-cart-header {
      border-bottom: 1px solid #E8E8E8;
      padding-bottom: 15px;
      .shopping-cart-total {
        float: right;
      }
    }

    .shopping-cart-items {
      padding-top: 20px;
      margin-left: 0;
      padding-left: 0;
      height: 230px;
      overflow: auto;
      margin-bottom: 40px;
      li {
        position: relative;
        margin-bottom: 18px;
        background: white;
        text-align: left;
        float: initial;
        img{
          position: absolute;
          top:50%;
          transform: translateY(-50%);
        }
        i.fa-times{
          right: 15px;
          top:50%;
          transform: translateY(-50%);
          position: absolute;
          display: none;
        }
        &:hover i.fa-times{
          display: block;
        }
        .item-name ,.item-price{
          margin-left: 50px;
        }
        p.desc {
          font-size: 14px !important;
          margin-left: 50px;
          width: 70%;
          margin-bottom: 0;
        }
      }
      img {
        float: left;
        margin-right: 12px;
      }
      .item-name {
        display: block;
        padding-top: 10px;
        font-size: 16px;
        width: 70%;
      }

      .item-price {
        color: $main-color;
        margin-right: 8px;
      }

      .item-quantity {
        color: $light-text;
      }
    }
  }

  .cart-icon {
    color: #515783;
    font-size: 24px;
    margin-right: 7px;
    float: left;
  }

  .button {
    background: $main-color;
    color: white;
    text-align: center;
    padding: 12px;
    text-decoration: none;
    border-radius: 3px;
    font-size: 16px;
    margin: 25px 0 15px 0;

    &:hover {
      background: lighten($main-color, 3%);
    }
  }
  .clearfix:after {
    content: "";
    display: table;
    clear: both;
  }
}

.sms-note-message {
  .header {
    height: initial;
    border: none;
  }
  .message-note .rgt i {
    font-size: 21px !important;
    margin-right: 5px;
  }
  .chat {
    list-style: none;
    margin: 0;
    padding: 0;
    .text-muted {
      margin-left: 20px;
    }
  }
  .chat li {
    margin-bottom: 10px;
    padding-bottom: 5px;
    background: none !important;
    text-align: left !important;
    &.right{
      .ddmenu>ul{
        left:0 !important;
      }
    }
    &.left{
      .ddmenu>ul{
        left:-134px !important;
      }
    }
  }
  .chat li.left .chat-body {
    margin-left: 60px;
  }
  .chat li.right .chat-body {
    margin-right: 60px;
  }
  .chat li .chat-body p {
    margin: 0;
    color: #777777;
  }
  .panel-footer {
    position: fixed;
    bottom: 49px;
    left: 0;
  }
  .panel-body {
    margin-top: 25px;
  }
  .nav > li > a:focus, .nav > li > a:hover {
    background-color: initial !important;
  }
  .nav .open > a, .nav .open > a:focus, .nav .open > a:hover {
    background-color: initial !important;
  }
  .rgt,.message-option {
    #dropdown {
      text-align: center;
      display: block;
      padding: 13px 16px;
      height: 50px;
      margin: 0 auto;
      position: relative;
      cursor: pointer;
      background: initial;
      font-size: 1.55em;
      color: #656565;
      font-weight: normal;
      -moz-transition: all 0.15s linear;
      -ms-transition: all 0.15s linear;
      -o-transition: all 0.15s linear;
      transition: all 0.15s linear;
      line-height: 25px;
      background: white;
      i{
        font-size:25px !important;
      }
    }
    #dropdown:hover {
      color: #898989;
    }

    #dropdown.open {
      background: #5a90e0;
      color: #fff;
      border-left-color: #6c6d70;
    }

    #dropdown ul {
      padding-left: 0;
      position: absolute;
      top: 100%;
      left: -110px;
      width: 170px;
      display: none;
      border-left: 4px solid #8e9196;
      background: #fff;
      -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
      z-index: 99;
    }
    #dropdown ul li {
      font-size: 0.9em;
    }
    #dropdown ul li a {
      text-decoration: none;
      display: block;
      color: white;
      font-size: 13px;
      padding: 7px 15px;
      z-index: 999;
      i {
        font-size: 25px !important;
      }
    }
    #dropdown ul li a:hover {
      color: #6fa0e9;
      background: #e7f0f7;
    }
  }
}

.message-option {
  #dropdown{
    cursor:pointer;
  }
  #dropdown ul{
    background: #4390c2 !important;
    li{
      margin-bottom: 0;
    }
    li:not(:last-child){
      border-bottom: 1px solid white;
    }
  }
}

//.title-notification {
//  position: fixed;
//  top: 50px;
//  left: 0;
//  right: 0;
//  height: 30px;
//  background: #d9e5ee;
//  z-index: 99999;
//}
//
//.collapse-message {
//  height: 50px;
//  position: fixed;
//  top: 50px;
//  left: 0;
//  right: 0;
//  bottom: 0;
//}


// Checkout Page

.checkout-page-sticky{
  .box {
    margin: 10px 0 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-left: none;
    border-right: none;
    .box-footer {
      margin: 30px -20px -20px;
      padding: 20px;
      border-top: solid 1px #eee;
    }
    .box-header {
      margin: 0;
      padding: 1px 10px 0;
      border: solid 1px #c8c8c873;
      background: #ece8e84d;
      margin-bottom: -20px;
    }
    .text-muted {
      color: #777;
    }
    .table-responsive {
      min-height: .01%;
      overflow-x: auto;
    }
    table {
      border: 1px solid #ddd;
      margin-top: 20px;
      td {
        color: #999;
      }
      input{
        width: 50px;
      }
      tr.total td, #order-summary table tr.total th {
        font-size: 18px;
        color: #555;
        font-weight: 700;
      }
    }

  }
  .btn {
    font-weight: normal;
    font-family: "Montserrat",Helvetica,Arial,sans-serif;
    text-transform: uppercase;
    letter-spacing: .08em;
    padding: 6px 12px;
    font-size: 13px;
    line-height: 1.42857143;
    border-radius: 3px;
  }
  .form-group {
    margin-bottom: 20px;
  }
  .nav {
    margin-bottom: 20px;
    border-bottom: solid 1px #c27baa;
    li {
      height: 100%;
      a {
        display: block;
        height: 100%;
      }
    }

  }
}
.newsfeed-post .media{
  padding: 0 15px;
}

.friend-list-page {
  .dropdown-menu{
    left: -88px;
  }
}

// content-user-images


.content-user-images{
  .thumbnail {margin-bottom:6px;}
  .carousel-control.left,.carousel-control.right{
    background-image:none;
    margin-top:10%;
    width:5%;
  }
}

.category-group{
  columns: 40px 7;
  padding: 20px;
  background: white;
  margin-bottom: 20px;
}
.category-heading{
  background: white;
  padding: 10px;
  margin-top: 0;
  margin-bottom: 0;
  border-bottom: 1px solid #e3e3e3;
  text-align: center;
}

.category-group{
  li.sub-category-item:before {
    content: "|";
    position: relative;
    left: -3px;
    font-weight: bold;
    color: #e3e3e3;
  }
}

li.main-category-item:not(:first-child) {
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 5px;
  margin-top: 5px;
}

//Date seperator

.date-seperator {
  position: relative;
  width: 300px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin: 0 auto;
}

.date-seperator::before,
.date-seperator::after {
  position: absolute;
  width: 110px;
  height: 1px;
  top: 24px;
  background-color: #aaa;
  content: '';
}
.date-seperator::before {
  left: 0;
}

.date-seperator::after {
  right: 0;
}
.load-more-btn{
  display: block;
  margin: 0 auto;
  width: 100px;
}

.cog-sett-dd li a:hover {
  color: black;
}
.full-width{
  width: 100% !important;
}
.account-setting-wait{
  .wait-thirty-second-new, .wait-thirty-second{
    position: static;
  }
}

button.hamburger.is-closed {
  position: fixed;
  left: 0;
  top: 3px;
}

button.hamburger.is-open i {
  font-size: 25px;
  color: white;
  width: 30px;
  height: 30px;
}

.file-footer-buttons{
  .kv-file-upload, .kv-file-zoom{
    display: none;
  }
}

.file-footer-buttons{
  .kv-file-remove i{
    font-size: 12px;
  }
}
.krajee-default .file-thumbnail-footer {
  padding: 0;
}
.btn-kv {
  height: 21px;
  line-height: 30px;
  padding: 0px 2px 2px 2px;
  &:hover{
    background: transparent;
  }
}
.krajee-default.file-preview-frame .kv-file-content img{
  height: 100% !important;
  object-fit: cover;
  width: 100% !important;
}

.file-input div.btn.btn-primary.text-right.btn-file > i.glyphicon-plus{
  font-size: 15px;
}

.btn-file{
  margin-left: 20px;
}