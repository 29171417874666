/* comment ------*/

.comment-main-level {
  margin-bottom: 10px;
}
.comments-container {
  padding: 15px;
  margin: 0 0 10px;
  background-color: #fff;
}

.comments-container h3,
.comment-form h3 {
  margin: 0 0 30px;
  color: #000;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
}

.comments-list {
  padding: 0;
  position: relative;
}

.reply-list:before,
.reply-list:after {
  display: none;
}

.dropdown.report-dropdown {
  display: inline-block;
  float: right;
  .dropdown-menu {
    left: -115px;
  }
}

.comments-list > li {
  margin-bottom: 30px;
  display: block;
  position: relative;
}

li.emoji-tab {
  margin-bottom: 0 !important;
  display: inline-block !important;
}

.reply-list .reply-comment-block {
  display: none;
}

.comments-list li:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
  width: 0;
}

.reply-list {
  padding-left: 50px;
  clear: both;
  margin-top: 30px;
}

.comments-list .comment-avatar {
  width: 45px;
  height: 45px;
  position: relative;
  float: left;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 50%;
}

.comments-list .comment-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;

}

.reply-list .comment-avatar {
  width: 35px;
  height: 35px;
}

.comment-main-level:after {
  content: '';
  width: 0;
  height: 0;
  display: block;
  clear: both;
}

.comments-list .comment-box {
  width: 80%;
  float: left;
  position: relative;
  margin-left: 15px;
}

.comments-list .comment-box::before {
  border-width: 10px 13px 11px 0;
  border-color: transparent rgba(0, 0, 0, 0.08);
  left: -12px;
}

.reply-list .comment-box {
  width: 80%;
}

@media(min-width: 768px) and (max-width: 1199px) {
  .comments-list .comment-box {
    width: 80%;
  }
  .reply-list {
    margin-top: 30px;
  }
  .reply-list .comment-box {
    width: 82%;
  }
}

/*.comment-box .comment-content {background: #FFF;}*/

.comment-box .comment-content h5 {
  line-height: 25px;
  color: rgb(34, 34, 34);
  text-transform: uppercase;
  margin-top: 0;
}

.comment-header {
  margin-bottom: 15px;
  letter-spacing: 0.1em;
}

.comment-author {
  color: rgb(34, 34, 34);
  text-transform: uppercase;
  margin-top: 0;
  font-style: normal;
  letter-spacing: 0;
}

.comment-datetime {
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #ababab;
  display: block;
}

.report-dropdown{
  > button{
    border: none;
    padding: 0;
    &:hover, &:focus, &:visited {
      background: none !important;
    }
  }
}
.login-to-comment{
  position: relative;
  left: 15px;
  top: 10px;
}
.comment-content p {
  margin: 0 0 18px
}

.comment-content .btn-news {
  padding: 2px 15px;
  text-transform: capitalize;
}

.article {
  position: relative;
  padding-left: 36px;
  padding-right: 50px;
  padding-top: 0px;
  margin: 50px 0;
}

.article::before {
  content: "\f10e";
  position: absolute;
  top: 2px;
  left: 0px;
  color: rgb(34, 34, 34);
  font-family: FontAwesome;
  font-size: 25px;
}

.article p {
  font-size: 15px;
  line-height: 27px;
}

.customers {
  font-size: 14px;
  line-height: 25px;
  color: rgb(34, 34, 34);
  text-transform: uppercase;
}

.customers span {
  font-size: 13px;
  line-height: 25px;
  text-transform: none;
  font-weight: 400;
}

/*.separator{height: 4px;width: 100%;margin: 50px auto;background: #fec107;}*/

/*comment form*/

.comment-form {
  background-color: #fff;
  padding: 15px;
  margin: 0 0 10px;
}

.comment-form label {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
  color: #eb0254;
}

.comment-form .form-group {
  margin-bottom: 20px
}

.comment-form .form-control {
  box-shadow: none;
  border-radius: 0;
  height: 50px;
  font-size: 13px;
  border-width: 0 0 1px 0;
  padding: 0;
  border-color: #ddd;
  border-style: dotted;
}

.comment-form .form-control:focus {
  border-color: #eb0254;
}

.comment-form textarea.form-control {
  height: auto;
}
