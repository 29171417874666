.feature-lifestyle {
  #post-7 {
    margin-bottom: 50px;
  }
  .entry-thumb {
    margin-bottom: 25px;
    position: relative;
    overflow: hidden;
  }
  a {
    color: #dc6e9f;
  }
  .entry-thumb img, .entry-thumb a:before, .entry-thumb a:after {
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -ms-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
  .entry-thumb img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  .entry-category {
    margin: 0 0 5px;
    font-size: 12px;
  }

  .entry-category {
    color: #dc6e9f;
  }
  .entry-category a {
    color: inherit;
  }
  h3.entry-title {
    margin: 0 0 20px;
    font-size: 18px;
    color: #616161;
  }
  .entry-summary {
    font-size: 13px;
    line-height: 22px;
    color: #545454;
    margin: 0;
  }
  a:focus, a:active, a:hover {
    color: #dc6e9f;
  }
  .entry:hover .entry-thumb img {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  .entry-thumb img, .entry-thumb a:before, .entry-thumb a:after {
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -ms-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }
  .entry-thumb img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  .category-box.category-box-large {
    height: 600px;
  }
  .category-box {
    margin-bottom: 20px;
    padding: 40px 30px;
    position: relative;
    background: no-repeat center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .category-box:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: -moz-linear-gradient(top, #f6f6f6 0%, transparent 80%);
    background-image: -webkit-gradient(left top, left bottom, color-stop(0%, #f6f6f6), color-stop(80%, transparent));
    background-image: -webkit-linear-gradient(top, #f6f6f6 0%, transparent 80%);
    background-image: -o-linear-gradient(top, #f6f6f6 0%, transparent 80%);
    background-image: -ms-linear-gradient(top, #f6f6f6 0%, transparent 80%);
    background-image: linear-gradient(to bottom, #f6f6f6 0%, transparent 80%);
  }
  .category-box h3 {
    margin: 0 0 10px;
    color: #2b2b2b;
  }
  .category-box * {
    position: relative;
    z-index: 2;
  }
  .category-box h3 a {
    color: inherit;
  }
  .category-box .description {
    font-size: 13px;
    margin: 0 0 35px;
    line-height: 20px;
  }
  .category-box .read-more a {
    font-size: 11px;
    color: #fff;
    background-color: #2d2d2d;
    line-height: 18px;
    padding: 10px 24px;
    text-transform: uppercase;
    display: inline-block;
  }
  p.description {
    text-align: left;
    font-size: 16px !important;
    line-height: 16*1.6px !important;
  }
  .category-box.category-box-small {
    height: 290px;
  }
  .gutter-less > div {
    padding-left: 10px;
    padding-right: 10px;
  }
}