.feature-beauty {
  .breadcrumbs {
    padding: 0 0 200px;
    position: relative;
    > h2 {
      color: #535353;
      font-size: 30px;
      line-height: 22px;
      margin-bottom: 0;
    }
    .breadcrumbs-title {
      position: relative;
      h2 {
        position: absolute;
        text-transform: uppercase;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.breadcrumbs-menu {
  background: rgba(255, 255, 255, 0.7) none repeat scroll 0 0;
  bottom: 3px;
  height: 100px;
  line-height: 100px;
  position: absolute;
  width: 100%;
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    color: #6bc513;
    display: inline-block;
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    margin-left: 10px;
    text-transform: uppercase;
    &:first-child {
      margin-left: 0;

    }
  }
}

.blog-pages .single-blog {
  margin-bottom: 30px;
}

.s-article-gallery:before, .blog-img > a:before {
  background: #6bc513 none repeat scroll 0 0;
  content: "";
  height: 100%;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  position: absolute;
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  width: 100%;
}

.blog-desc {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  -o-border-image: none;
  border-image: none;
  border: 1px solid #ddd;
  border-left-color: transparent;
  float: left;
  margin-top: 2px;
  padding: 36px 0 36px 30px;
  width: 64%;
}

.blog-bottom-action {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding: 16px 30px;
  margin-bottom: 50px;
}

.blog-publish, .blog-action-box, .blog-action-box li {
  display: inline-block;
}

.blog-publish {
  float: left;
  > p {
    color: #999999;
    line-height: 14px;
    margin-bottom: 0;
  }
}

.blog-action-box {
  float: right;
  li {
    margin-right: 20px;
  }
}

.blog-img {
  float: left;
  width: 36%;
  position: relative;
  img {
    width: 100%;
    height: 206px;
  }
  a {
    display: block;
  }
}

.breadcrumbs-title h2 {
  text-transform: uppercase;
}

.blog-desc h4 a {
  color: #4f4f4f;
}